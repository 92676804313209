import { Dispatch, SetStateAction } from 'react';
import { UseFormReset } from 'react-hook-form';
import { AddressRecipientUpdateForm } from '../../global-types/addresses';

export enum RecipientAddressesVariant {
  Address = 'address',
  Preview = 'preview',
}

export type RecipientAddressesProps = {
  isOpen: boolean;
  setRecipientAddressesDialogOpen: (isOpen: boolean) => void;
  setOpenEditDialog: Dispatch<SetStateAction<boolean>>;

  variant: RecipientAddressesVariant;

  // Functions to handle the actions buttons
  onActionButtonClick: () => void;
  onSecondaryActionButtonClick: () => void;
  resetRecipientAddressForm: UseFormReset<AddressRecipientUpdateForm>;
};
