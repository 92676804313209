import { AddressRecipientUpdate, AddressRecipientUpdateForm } from '../../../global-types/addresses';

export const parseUpdateAddressForm = (address: AddressRecipientUpdateForm): AddressRecipientUpdate => {
  return {
    address_line_1: address.address_line_1,
    address_line_2: address.address_line_2,
    city: address.city,
    state_code: address.state_code,
    zip: address.zip,
    country_code: address.country_code,
    company_name: address.company_name,
    first_name: address.first_name,
    last_name: address.last_name,
    skip_usps_validation: address.skip_usps_validation,
    skip_ebdw_validation: address.skip_ebdw_validation,
  };
};
