import { Dispatch, SetStateAction } from 'react';
import { AddressForm } from '../global-types';
import { SubmittedAddresses } from '../views/address/address-types';
import { mapAddressesByType } from './address/map-address-by-type';
import { trimObjectStrings } from './trim-object-strings';

// Builds the submitted addresses object for one-to-one addresses, this can be used for both sender and recipient addresses
// reusable function to be used in the address form and address book
export const buildOneToOneAddresses = (
  address: AddressForm,
  setSubmittedAddresses: Dispatch<SetStateAction<SubmittedAddresses>>,
) => {
  if (address === null) {
    return;
  }

  // remove unused keys from address object
  delete address?.send_to;
  delete address?.envelope;

  // trim all the strings in the address object
  const trimmedAddress = trimObjectStrings(address) as AddressForm;

  // map the trimmed address object to the correct address type
  const submittedAddress = mapAddressesByType([{ ...trimmedAddress }]);

  // update the submitted addresses state
  setSubmittedAddresses((addresses) => ({ ...addresses, ...submittedAddress }));
};
