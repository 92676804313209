/**
 * gets a clipPath if present on current canvas so that images and text can be added within
 * @param currentFace current cardface from cardFacesList, not the cardState.activeCanvas
 * @param areaIndex index of the activeArea in the array of editableAreas
 *
 * @example
 *
 *  getCardFaceClipPath(cardState.cardFacesList[cardState.activeCardIndex], 0);
 */
export const getCardFaceClipPath = (currentFace: any, areaIndex: number) => {
  if (!currentFace.clipPaths) return;
  return currentFace.clipPaths[`${areaIndex}`];
};
