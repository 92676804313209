import React from 'react';
import { Button, ButtonModes } from '@hallmark/web.core.buttons.button';
import { ActionButtonProps } from '../quantity-change-dialog-types';

export const ActionButton = ({ click, label }: ActionButtonProps): React.ReactElement => {
  return (
    <Button click={click} mode={ButtonModes.Primary} testId="action-button">
      {label}
    </Button>
  );
};
