import React from 'react';
import classNames from 'classnames';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { SummaryEndProps } from '../summary-drawer-types';
import styles from '../summary-drawer.module.scss';

export const SummaryFooter = ({ data }: SummaryEndProps): React.ReactElement => {
  const classes = classNames(styles['summary-row'], styles['summary-footer']);

  return (
    <div className={classes} data-testid="summary-grid-end">
      <div className={styles.detail}>
        <div className={styles['title-container']}>
          <Typography variant={TypographyVariants.Body}>{data.title}</Typography>
        </div>
        <div className={styles['subtitle-container']}>
          <Typography variant={TypographyVariants.BodyBold}>{data.detail}</Typography>
        </div>
      </div>
    </div>
  );
};
