import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

/**
 * Custom hooks to clean up the search url when returning from account url.
 * After logging in, if the redirect URL contains search parameters, we get '&amp;amp;' instead of just '&'.
 * We need to sanitize the url in order to access those search parameters correctly
 * @returns isSearchUrlSanitized to prevent any request to be done before sanitizing the url
 */
export const useSanitizeSearchUrl = () => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const [isSanitized, setIsSanitized] = useState(!search.includes('&amp;amp;'));

  useEffect(() => {
    // There's a bug on the back-end in which instead of returning '&' after redirect they return '&amp;amp;
    // Check https://hallmark.atlassian.net/browse/ECOM-7166 for more details.
    if (search.includes('&amp;amp;')) {
      navigate({ pathname, search: search.replace(/&amp;amp;/g, '&') }, { replace: true });
      setIsSanitized(true);
    }
  }, [search, pathname, window.history]);

  return { isSearchUrlSanitized: isSanitized };
};
