import React, { useEffect } from 'react';
import { fabric } from 'fabric';
import DeleteIcon from '../../assets/Delete.svg';
import RotateIcon from '../../assets/Rotate.svg';
import ScaleIcon from '../../assets/Scale.svg';
import CircleIcon from '../../assets/circle-icon.svg';
import RectangleIcon from '../../assets/rectangle-icon.svg';
import {
  useAppContext,
  setIsDeleteConfirmationDialogOpen,
  setIsSizeDrawerOpen,
  setIsRotationDrawerOpen,
} from '../../context/app-context';
import { useInitializationDataContext } from '../../context/data-context';
import { FabricObject, FabricTextBox } from '../../global-types';
import { CardType } from '../../global-types/card';
import { CanvasDataTypes, helperSettingsConfig, isImage, isTextbox, renderSizeTextIcon } from '../../utils';
import { CanvasCustomControlsProps, CustomControlsIcons, CustomControlOptions } from './canvas-custom-config-types';
import { renderCustomCroppingControl } from './utils/render-custom-cropping-control';

export const CanvasCustomConfig = ({ children }: CanvasCustomControlsProps) => {
  const { appDispatch } = useAppContext();
  const { scalingFactor } = helperSettingsConfig;
  const {
    initializedDataState: { data: initializedData, isUK },
  } = useInitializationDataContext();

  const windowFabric = window.fabric;

  const renderIcon = (icon: HTMLImageElement) => {
    return function renderIcon(
      ctx: CanvasRenderingContext2D,
      left: number,
      top: number,
      styleOverride: unknown,
      fabricObject: fabric.Object,
    ): void {
      const size = 32;
      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle ?? 0));
      ctx.drawImage(icon, -size / scalingFactor, -size / scalingFactor, size, size);
      ctx.restore();
    };
  };

  const renderCustomControls = (
    icons: Record<string, HTMLImageElement>,
    controlName: string,
    ctx: CanvasRenderingContext2D,
    left: number,
    top: number,
    fabricObject: FabricObject,
    control: fabric.Control,
  ) => {
    const initialSize = 20;
    const sizeMultiplier = 1.8;
    const { angle = 0 } = fabricObject;

    const scaledSize = Math.min(initialSize, 10);

    const options = {
      left,
      top,
      size: scaledSize,
      iconSize: initialSize * sizeMultiplier,
      angle,
    };

    ctx.restore();
    if (isTextbox(fabricObject)) {
      renderTextControl(icons, controlName, ctx, options, control, fabricObject);
    }
    if (isImage(fabricObject)) {
      renderImageControls(icons, controlName, ctx, options, control);
    }
    if (fabricObject.name === 'cropping-rect') {
      renderCustomCroppingControl(controlName, ctx, left, top, fabricObject);
    }
  };

  const renderImageControls = (
    icons: Record<string, HTMLImageElement>,
    controlName: string,
    ctx: CanvasRenderingContext2D,
    options: CustomControlOptions,
    control: fabric.Control,
  ) => {
    const { left, top, iconSize, size } = options;
    ctx.save();
    ctx.translate(left, top);

    const icon = icons[`${controlName}`];

    if (!icon) {
      return;
    }

    const actualSizeRotateIcon = iconSize ?? 0;
    switch (controlName) {
      case 'tr':
      case 'bl':
      case 'tl':
      case 'br':
        ctx.drawImage(icon, -size / scalingFactor, -size / scalingFactor, size, size);
        break;
      case 'mtr':
        renderSizeTextIcon(actualSizeRotateIcon, control, ctx, icon, scalingFactor);
        break;
    }
    ctx.restore();
  };

  const renderTextControl = (
    icons: Record<string, HTMLImageElement>,
    controlName: string,
    ctx: CanvasRenderingContext2D,
    options: CustomControlOptions,
    control: fabric.Control,
    textBox: FabricTextBox,
  ) => {
    const { left, top, size, iconSize } = options;
    const actualSizeTrash = iconSize ?? 0;

    const icon = icons[`${controlName}`];

    if (!icon) {
      return;
    }
    const shouldShowResizeControls =
      textBox.CanResizeTextArea ||
      textBox.data.type === CanvasDataTypes.UserText ||
      textBox.data.type === CanvasDataTypes.Placeholder;
    const shouldShowDeleteControls =
      (textBox.CanDeleteTextArea && textBox.data.type === CanvasDataTypes.EditableText) ||
      textBox.data.type === CanvasDataTypes.Placeholder;

    ctx.save();
    ctx.translate(left, top);

    switch (controlName) {
      case 'tr':
      case 'bl':
      case 'tl':
      case 'br':
        if (shouldShowResizeControls) {
          ctx.drawImage(icon, -size / 2, -size / 2, size, size);
        }
        break;
      case 'ml':
        if (shouldShowResizeControls) {
          ctx.drawImage(icon, -12 / scalingFactor, -size, size, size + 12);
        }
        break;
      case 'mr':
        if (shouldShowResizeControls) {
          ctx.drawImage(icon, -8 / scalingFactor, -size, size, size + 12);
        }
        break;
      case 'deleteControl':
        if (shouldShowDeleteControls || shouldShowResizeControls) {
          renderSizeTextIcon(actualSizeTrash, control, ctx, icon, scalingFactor);
        }
        break;
    }
    ctx.restore();
  };

  const onDeleteObject = (): boolean => {
    setIsDeleteConfirmationDialogOpen(appDispatch);
    return true;
  };
  const onRotateObject = (): boolean => {
    // @todo ensure implementation
    setIsRotationDrawerOpen(appDispatch);
    return true;
  };
  const onResizeObject = (): boolean => {
    // @todo ensure implementation
    setIsSizeDrawerOpen(appDispatch);
    return true;
  };

  const setCustomControls = (
    { _fabric }: any,
    { deleteIcon, scaleIcon, circleIcon, rectangleIcon, rotateIcon }: CustomControlsIcons,
  ) => {
    _fabric.Object.prototype.drawControls = function (ctx) {
      let coords;
      this.setCoords();
      this.forEachControl(function (control, key, fabricObject) {
        coords = fabricObject.oCoords[`${key}`];
        const icons = {
          mr: rectangleIcon,
          ml: rectangleIcon,
          tr: circleIcon,
          bl: circleIcon,
          mtr: rotateIcon,
          deleteControl: deleteIcon,
          tl: circleIcon,
          br: circleIcon,
        };
        renderCustomControls(icons, key, ctx, coords.x, coords.y, fabricObject, control);
      });
      ctx.restore();
    };

    _fabric.Object.prototype.set({
      borderColor: isUK ? '#333333' : '#604099',
      borderScaleFactor: 3,
      borderDashArray: [3, 3],
    });

    _fabric.Textbox.prototype.controls.deleteControl = new fabric.Control({
      x: 0,
      y: -0.5,
      offsetY: -40,
      withConnection: true,
      cursorStyle: 'pointer',
      mouseUpHandler: onDeleteObject,
      render: renderIcon(deleteIcon),
      actionName: 'delete',
    });

    _fabric.Textbox.prototype.controls.rotateControl = new fabric.Control({
      x: 0,
      y: -0.5,
      offsetY: -40,
      withConnection: true,
      cursorStyle: 'pointer',
      mouseUpHandler: onRotateObject,
      actionHandler: (eventData, transformData: fabric.Transform, x, y) => {
        (transformData.originX as 'left' | 'right' | 'center') = 'center';
        (transformData.originY as 'left' | 'right' | 'center') = 'center';
        return fabric.Object.prototype.controls.mtr.actionHandler(eventData, transformData, x, y);
      },
      render: renderIcon(rotateIcon),
    });

    _fabric.Textbox.prototype.controls.resizeControl = new fabric.Control({
      x: 0.5,
      y: 0.5,
      cursorStyle: 'pointer',
      mouseUpHandler: onResizeObject,
      actionHandler: fabric.Object.prototype.controls.br.actionHandler,
      render: renderIcon(scaleIcon),
    });

    // Hide default controls
    _fabric.Textbox.prototype.setControlsVisibility({
      mr: true,
      mt: false,
      ml: true,
      bl: true,
      mtr: true,
      mb: false,
      tr: true,
      tl: true,
      rotateControl: false,
    });
  };

  const setCustomCroppingControls = ({ _fabric }: any) => {
    // Override drawControls function to draw custom controls for cropping rect
    _fabric.Object.prototype.drawControls = function (ctx, styleOverride) {
      styleOverride = styleOverride || {};
      ctx.save();
      const retinaScaling = this.canvas.getRetinaScaling();
      let matrix, p;
      ctx.setTransform(retinaScaling, 0, 0, retinaScaling, 0, 0);
      ctx.strokeStyle = ctx.fillStyle = styleOverride.cornerColor || this.cornerColor;
      if (!this.transparentCorners) {
        ctx.strokeStyle = styleOverride.cornerStrokeColor || this.cornerStrokeColor;
      }
      this._setLineDash(ctx, styleOverride.cornerDashArray || this.cornerDashArray);
      this.setCoords();
      if (this.group) {
        matrix = this.group.calcTransformMatrix();
      }

      this.forEachControl(function (control, key, fabricObject) {
        p = fabricObject.oCoords[`${key}`];
        if (control.getVisibility(fabricObject, key)) {
          if (matrix) {
            p = fabric.util.transformPoint(p, matrix);
          }
          // render custom controls for cropping-rect in WAM, otherwise call fabricjs' original render function
          if (fabricObject.name === 'cropping-rect') {
            renderCustomCroppingControl(key, ctx, p.x, p.y, fabricObject);
          } else {
            control.render(ctx, p.x, p.y, styleOverride, fabricObject);
          }
        }
      });
      ctx.restore();
      return this;
    };
  };

  /**
   * Sets specific properties of fabric instance overriding its defaults
   *
   * @param {Object} _fabric fabric instance
   */
  const setFabricConfiguration = ({ _fabric }: any) => {
    _fabric.disableStyleCopyPaste = true;
  };

  useEffect(() => {
    // Set up icon images
    const deleteIcon = new Image();
    const rotateIcon = new Image();
    const scaleIcon = new Image();
    const circleIcon = new Image();
    const rectangleIcon = new Image();

    deleteIcon.src = DeleteIcon;
    rotateIcon.src = RotateIcon;
    scaleIcon.src = ScaleIcon;
    circleIcon.src = CircleIcon;
    rectangleIcon.src = RectangleIcon;

    const fabricObj = {
      _fabric: windowFabric,
    };
    setCustomControls(fabricObj, { deleteIcon, rotateIcon, scaleIcon, circleIcon, rectangleIcon });
    setFabricConfiguration(fabricObj);
  }, [windowFabric]);

  useEffect(() => {
    if (windowFabric && initializedData?.project_type_code === CardType.SAS) {
      const fabricObj = {
        _fabric: windowFabric,
      };

      setCustomCroppingControls(fabricObj);
    }
  }, [initializedData?.project_type_code, windowFabric]);

  return <>{children}</>;
};
