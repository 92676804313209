import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Dialog, DialogContentType, DialogFooterType } from '@hallmark/web.core.feedback.dialog';
import { FormFieldAddress } from '../../global-types/addresses';
import { useFormFieldGenerator } from '../../hooks/use-form-field-generator';
import { getCurrentRegion } from '../../regional-config/get-regional-config';
import { FormFieldsGenerator } from '../form-fields-generator/form-fields-generator';
import styles from './edit-address-dialog.module.scss';
import { EditAddressDialogProps } from './edit-address-types';
import { editAddressFormFieldList } from './utils/edit-address-form-field-list';

export const EditAddressDialog = ({
  title,
  accentIcon,
  formHandlers,
  isEditAddressVisible,
  onCloseEditAddress,
  actionButton,
}: EditAddressDialogProps): React.ReactElement => {
  const region = getCurrentRegion();

  const { generatedFormFields } = useFormFieldGenerator(
    formHandlers as unknown as UseFormReturn<FormFieldAddress>,
    'edit-address',
    editAddressFormFieldList,
  );

  return (
    <Dialog
      id={'domid-edit-address-dialog'}
      title={title}
      locale={region}
      accentIcon={accentIcon}
      hasGrayBackground={true}
      type={DialogContentType.FeatureModal}
      footerType={DialogFooterType.WithShadow}
      isOpen={isEditAddressVisible}
      onClose={onCloseEditAddress}
      closeButtonId={`edit-address-dialog-close-button`}
      addDialogClass={styles['edit-address-dialog']}
      addDialogWrapperClass={styles['edit-address-dialog-wrapper']}
      actionButton={actionButton}
      testId={'edit-address-dialog'}
    >
      <FormFieldsGenerator selectedFormFields={generatedFormFields} register={formHandlers.register} />
    </Dialog>
  );
};
