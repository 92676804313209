import API from '@dotcom/api-middleware';
import {
  getAddressesResponse,
  quickAddressesResponse,
  quickAddressResponse,
  savedAddressResponse,
} from '../data/address-response';
import {
  Address,
  AddressRecipientUpdate,
  AddressUpdate,
  BaseAddress,
  GetAddresses,
  QuickAddressesResponse,
  QuickAddressResponse,
  QuickAddressSubmitData,
  SaveAddressesResponse,
  SaveAddressResponse,
  UpdateAddress,
} from '../global-types/addresses';
import type { ApiResponse } from '../global-types/services';
import { getAccountIdFromCookie } from '../utils/utility/get-account-id-from-cookie';
import { processResponse } from './middleware';

const PODService = new API();
const accountId = getAccountIdFromCookie();
const mockResponse = process.env.REACT_APP_MOCK_INITIALIZATION_SERVICE === 'true';

export const getAddresses = (projectId: string): Promise<GetAddresses> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      resolve(getAddressesResponse);
    }
    const response = PODService.get('/customization', `/${accountId}/${projectId}/addresses`);
    processResponse(response, resolve, reject);
  });
};

export const saveAddress = (projectId: string, address: Address): Promise<SaveAddressResponse> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      resolve(savedAddressResponse);
    }
    const response = PODService.post('/customization', `/${accountId}/${projectId}/addresses`, address);
    processResponse(response, resolve, reject);
  });
};

export const saveAddresses = (
  projectId: string,
  body: {
    addresses: {
      address: Omit<BaseAddress, 'address_type_code'>;
      is_verified: boolean;
    }[];
    skip_ebdw_validation: boolean;
  },
): Promise<SaveAddressesResponse> => {
  return new Promise((resolve, reject) => {
    const response = PODService.post('/customization', `/${accountId}/${projectId}/addresses/list`, body);
    processResponse(response, resolve, reject);
  });
};

export const updateAddress = (
  projectId: string,
  addressId: string,
  address: AddressRecipientUpdate,
): Promise<UpdateAddress> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      resolve(savedAddressResponse);
    }
    const response = PODService.put('/customization', `/${accountId}/${projectId}/addresses/${addressId}`, address);
    processResponse(response, resolve, reject);
  });
};

/**
 * Disassociates an address from a project.  For instance, when a user swaps from wanting
 * a return address envelope to a blank envelope.
 *
 * @param projectId - ProjectID associated with the card
 * @param addressId - AddressID associated with the address
 * @returns void
 */
export const deleteAddress = (projectId: string, addressId: string): Promise<ApiResponse<null> | null> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      resolve(null);
    }
    const response = PODService.delete('/customization', `/${accountId}/${projectId}/addresses/${addressId}`);
    processResponse(response, resolve, reject);
  });
};

export const saveQuickAddress = (address: QuickAddressSubmitData): Promise<QuickAddressResponse> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      resolve(quickAddressResponse);
    }
    const response = PODService.post('/address-book/', accountId, address);
    processResponse(response, resolve, reject);
  });
};

export const deleteQuickAddress = (contactId: string): Promise<ApiResponse<null> | null> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      resolve(null);
    }
    const response = PODService.delete('/address-book', `/${accountId}/${contactId}`);
    processResponse(response, resolve, reject);
  });
};

export const getQuickAddresses = (params?: { page?: string; limit?: string }): Promise<QuickAddressesResponse> => {
  return new Promise((resolve, reject) => {
    const { page = 1, limit = 10 } = params || {};
    if (mockResponse) {
      resolve(quickAddressesResponse);
    }
    const path = `${accountId}?p[page]=[{"eq": "${page}"}]&p[limit]=[{"eq": "${limit}"}]&q[sort_by]=[{"field":"created_at","order":"asc"}]`;
    const response = PODService.get('/address-book/', `${path}`);
    processResponse(response, resolve, reject);
  });
};

export const fetchUsStates = async () => {
  const usStatesUrl = process.env.REACT_APP_US_STATES_S3_URL;
  if (usStatesUrl) {
    const response = await fetch(usStatesUrl);
    const usStatesData = await response.json();
    return usStatesData;
  }
};

export const updateQuickAddress = (
  contactId: string,
  addressData: AddressUpdate,
): Promise<ApiResponse<null> | null> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      resolve(null);
    }
    const response = PODService.put('/address-book', `/${accountId}/${contactId}`, addressData);
    processResponse(response, resolve, reject);
  });
};

export const bulkUploadAddresses = (file: File): Promise<ApiResponse<null> | null> => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', file);
    const response = PODService.post('/address-book', `/${accountId}/bulk`, formData);
    processResponse(response, resolve, reject);
  });
};
