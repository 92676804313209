import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonModes } from '@hallmark/web.core.buttons.button';
import { Dialog, DialogContentType, DialogFooterType } from '@hallmark/web.core.feedback.dialog';
import { DialogBrandColors } from '@hallmark/web.core.feedback.dialog/dist/dialog-types';
import { IconNames } from '@hallmark/web.styles.fonts.icomoon';
import useAddressSelection from '../../hooks/use-address-selection';
import { getCurrentRegion } from '../../regional-config/get-regional-config';
import { Contact } from '../address-book';
import contactStyles from '../contact-card/contact-card.module.scss';
import { Contact as ContactCard } from '../contact/contact';
import { DetailRecipients } from './fragments/detail-recipients';
import { OrderQuantity } from './fragments/order-quantity';
import { useDeleteAddress } from './hooks/use-delete-address';
import { useOrderQuantitySummary } from './hooks/use-order-quantity-summary';
import { RecipientAddressesProps, RecipientAddressesVariant } from './recipient-addresses-dialog-types';
import styles from './recipient-addresses-dialog.module.scss';

export const RecipientAddressesDialog = ({
  isOpen,
  setRecipientAddressesDialogOpen,
  variant,
  onActionButtonClick,
  onSecondaryActionButtonClick,
  setOpenEditDialog,
  resetRecipientAddressForm,
}: RecipientAddressesProps) => {
  const { contacts } = useAddressSelection();
  const { t } = useTranslation();
  const region = useMemo(() => getCurrentRegion(), []);
  const printedEnvelopes = contacts.length;
  const { quantity, blankEnvelopes } = useOrderQuantitySummary({ printedEnvelopes });
  const { deleteAddress } = useDeleteAddress();

  const onEditContact = (contact: Contact) => {
    // set the values of the contact to the form
    resetRecipientAddressForm({
      address_id: contact.contact_id,
      first_name: contact.first_name,
      last_name: contact.last_name,
      address_line_1: contact.address_line_1,
      address_line_2: contact.address_line_2,
      city: contact.city,
      state_code: contact.state_code,
      zip: contact.zip,
      country_code: contact.country_code,
    });

    setOpenEditDialog(true);
    setRecipientAddressesDialogOpen(false);
  };

  const onDeleteContact = (contactId: string) => {
    deleteAddress(contactId);
  };

  return isOpen ? (
    <Dialog
      testId={'recipient-addresses-dialog'}
      isOpen={isOpen}
      onClose={() => setRecipientAddressesDialogOpen(false)}
      accentIcon={IconNames.AddressBookBold}
      accentIconColor={DialogBrandColors.White}
      title={String(t('recipientAddresses.title'))}
      type={DialogContentType.FeatureModal}
      footerType={DialogFooterType.WithShadow}
      locale={region}
      addDialogWrapperClass={styles.recipientAddressesWrapper}
      secondaryActionButton={
        <Button testId="secondary-action-button" click={onSecondaryActionButtonClick} mode={ButtonModes.Secondary}>
          {variant === RecipientAddressesVariant.Address
            ? t('recipientAddresses.address.secondaryActionButton')
            : t('recipientAddresses.preview.secondaryActionButton')}
        </Button>
      }
      actionButton={
        <Button testId="action-button" click={onActionButtonClick} mode={ButtonModes.Primary}>
          {variant === RecipientAddressesVariant.Address
            ? t('recipientAddresses.address.actionButton')
            : t('recipientAddresses.preview.actionButton')}
        </Button>
      }
    >
      <div className={styles.recipientAddresses}>
        <OrderQuantity quantity={quantity} />
        <DetailRecipients
          shouldEdit
          iconName={IconNames.Mail}
          description={t('recipientAddresses.detailRecipients.blankEnvelopes')}
          amount={blankEnvelopes}
        />
        <DetailRecipients
          iconName={IconNames.AddressBookBold}
          description={t('recipientAddresses.detailRecipients.printedEnvelopes')}
          amount={printedEnvelopes}
        />
        {contacts && contacts.length > 0 ? (
          contacts.map((contact, index) => (
            <div className={contactStyles['contact-card'] + ' ' + styles.contact} key={index}>
              <ContactCard contact={contact} onDelete={onDeleteContact} onEdit={onEditContact} />
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
    </Dialog>
  ) : (
    <></>
  );
};
