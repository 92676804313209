import { useTranslation } from 'react-i18next';
import { setInitializationData, updateAddresses, useInitializationDataContext } from '../../../context/data-context';
import { AddressResponseData, CardFace, InitializationData } from '../../../global-types';
import { useSaveProject, useSystemErrorHandling } from '../../../hooks';
import { config } from '../../../regional-config';
import { loadProject } from '../../../services';
import { formatResponseData, validateImages } from '../../../utils';
import { mapAddressesByType } from '../../../utils/address/map-address-by-type';
import { useLoadFontFaces } from './useLoadFontFaces';

export const useLoadExistingProject = () => {
  const { onSystemError } = useSystemErrorHandling();
  const loadFontFaces = useLoadFontFaces();
  const { saveDraftName } = useSaveProject();
  const {
    saveProjects: { isSavedProjectsEnabled },
  } = config;
  const { t } = useTranslation();
  const { initializationDataDispatch } = useInitializationDataContext();

  const loadExistingProject = async (projectId: string, isDataLoaded: React.MutableRefObject<boolean>) => {
    const response = await loadProject(projectId);
    if (!response.data) return;
    // corrects the wrong information we've sent the backend on digital greetings cards below
    const formatedSkuData = formatResponseData(response.data);

    const faces = formatedSkuData?.variables?.template_data?.Faces as CardFace[];
    // confirm all card faces have valid background images
    const missingBackgroundImageError = t('editorView.missingBackgroundImageError');
    validateImages(faces, onSystemError, missingBackgroundImageError);

    await loadFontFaces(formatedSkuData);

    if (isSavedProjectsEnabled) {
      await saveDraftName(formatedSkuData.name, true);
      formatedSkuData.is_draft = true;
    }

    setInitializationData(initializationDataDispatch, formatedSkuData as InitializationData);
    isDataLoaded.current = true;

    if (formatedSkuData?.addresses) {
      const addresses = mapAddressesByType((formatedSkuData as InitializationData).addresses as AddressResponseData[]);
      updateAddresses(initializationDataDispatch, addresses);
    }
  };

  return loadExistingProject;
};
