import { hideLoadingScreen, showLoadingScreen } from '../../../context/app-context';
import { Dispatch } from '../../../context/app-context/app-context-types';
import { updateAddresses } from '../../../context/data-context';
import { Dispatch as DispatchData } from '../../../context/data-context/data-context-types';
import {
  Address,
  AddressResponseData,
  DigitalAddress,
  ErrorResponse,
  ErrorType,
  SaveAddressResponse,
} from '../../../global-types';
import { AddressRecipientUpdate, AddressTypeCode } from '../../../global-types/addresses';
import { OnSystemError } from '../../../hooks/useSystemErrorHandling';
import { saveAddress, updateAddress } from '../../../services/address';
import { getAddressError } from '../../../utils/address/get-address-error';
import { mapAddressesByType } from '../../../utils/address/map-address-by-type';

export interface addressData {
  sender: AddressResponseData | Address | null;
  recipient: AddressResponseData | Address | null;
  digital: DigitalAddress | Address | null;
}

interface handleSaveAddressProps {
  projectId: string;
  data: Address;
  step: number;
  appDispatch: Dispatch;
  initializationDataDispatch: DispatchData;
  setIsAddressLoading: React.Dispatch<React.SetStateAction<boolean>>;
  addressData: addressData;
  addAddressToValidate: (address: Address, errorType: ErrorType, step: number, contactId: any) => void;
  openAddressConfirmationDialog: () => void;
  onSystemError: OnSystemError;
}

export const handleSaveAddress = async ({
  projectId,
  data,
  step,
  appDispatch,
  initializationDataDispatch,
  setIsAddressLoading,
  addressData,
  addAddressToValidate,
  openAddressConfirmationDialog,
  onSystemError,
}: handleSaveAddressProps) => {
  const {
    address_type_code,
    first_name,
    last_name,
    address_line_1,
    address_line_2,
    company_name,
    city,
    state_code,
    zip,
    country_code,
    skip_usps_validation,
  } = data;
  const address: AddressRecipientUpdate = {
    first_name,
    last_name,
    address_line_1,
    address_line_2,
    company_name,
    city,
    state_code: state_code || '',
    zip,
    country_code,
    skip_usps_validation,
  };
  showLoadingScreen(appDispatch, 'Generating preview');
  setIsAddressLoading(true);
  const addressId =
    address_type_code === AddressTypeCode.RECIPIENT
      ? (addressData.recipient as AddressResponseData)?.address_id
      : (addressData.sender as AddressResponseData)?.address_id;

  try {
    const response: SaveAddressResponse = addressId
      ? await updateAddress(projectId, addressId, address)
      : await saveAddress(projectId, data);
    if (response.data) {
      const mappedAddress = mapAddressesByType([response.data]);
      updateAddresses(initializationDataDispatch, mappedAddress);
    }
  } catch (error) {
    if ((error as ErrorResponse)?.status === 422) {
      const addressError = getAddressError((error as ErrorResponse).data.errors);
      addAddressToValidate(data, addressError?.description as ErrorType, step, null);
      hideLoadingScreen(appDispatch);
      openAddressConfirmationDialog();
      throw error;
    } else {
      setIsAddressLoading(false);
    }
    hideLoadingScreen(appDispatch);
    onSystemError(error as ErrorResponse);
  }
};
