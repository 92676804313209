import { useCallback, useState, useRef, useLayoutEffect, MutableRefObject } from 'react';

interface Size {
  width: number;
  height: number;
}

export function useElementSize<T extends HTMLElement = HTMLDivElement>(): [MutableRefObject<T | null>, Size] {
  const ref = useRef<T | null>(null);
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  });

  // Prevent too many rendering using useCallback
  const handleSize = useCallback(() => {
    setSize({
      width: ref.current?.offsetWidth ?? 0,
      height: ref.current?.offsetHeight ?? 0,
    });
  }, [ref.current?.offsetWidth, ref.current?.offsetHeight]);

  useLayoutEffect(() => {
    window.addEventListener('resize', handleSize);
    return () => window.removeEventListener('resize', handleSize);
  }, []);

  useLayoutEffect(() => {
    handleSize();
  }, [ref.current?.offsetWidth, ref.current?.offsetHeight]);

  return [ref, size];
}
