import React, { createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { fabric } from 'fabric';
import { Pill } from '@hallmark/web.core.buttons.pill';
import { FabricCanvas, useFabric } from '@hallmark/web.core.display.fabric-canvas';
import { ToastVariants } from '@hallmark/web.core.feedback.toast';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { BrandColors } from '@hallmark/web.styles.colors';
import { useAnalyticsContext } from '../../context/analytics-context';
import {
  setAllDrawersClosed,
  setIsImageEditDrawerOpen,
  setIsImageUploadDrawerOpen,
  setIsRotationDrawerOpen,
  setIsSystemErrorOpen,
  setIsTextDrawerOpen,
  setIsToasterOpen,
  setSkipSpellcheckValidation,
  useAppContext,
} from '../../context/app-context';
import { useCardContext } from '../../context/card-context';
import { useInitializationDataContext } from '../../context/data-context';
import {
  CustomFabricImage,
  CustomFabricObject,
  FabricObject,
  FabricTextBox,
  ObjectJson,
} from '../../global-types/canvas';
import { CardType } from '../../global-types/card';
import { EditableTextSettings } from '../../global-types/card-face';
import { useActiveCanvas, useDimensionsWithBleed, useElementSize, useFeatureFlags } from '../../hooks';
import { useIsOneToMany } from '../../hooks/use-is-one-to-many';
import { ActionType, TextAction, useDatadog } from '../../hooks/useDatadog';
import colors from '../../styles/util.scss';
import {
  addUserTextHandlers,
  CanvasDataTypes,
  clipObject,
  expandText,
  getGroupedItemByName,
  getMaxWidthLine,
  getObjectByName,
  getPhotoTextZoneDescription,
  getSizeAfterScaling,
  getZoneLabelOffset,
  hideMiddleControls,
  isEditableText,
  lockEditableSize,
  mergeLines,
  MIN_FONT_SIZE,
  onPhotoTrayImageDrop,
  setEditableTextHandlers,
  shrinkText,
  spaceContentEvenly,
  toggleTamWarning,
} from '../../utils';
import { parseTextAction } from '../../utils/parse-text-action';
import { handleSelectionSpellcheck, handleSpellcheckSuggestions } from '../../utils/spellcheck';
import { findTextById } from '../../utils/utility/find-text-by-id';
import { addEditableAreas, addIconsToLowResImages, getEditableTextButton } from '../card-editor/utils';
import { ResetTextConfirmationDialog } from '../confirmation-dialog/confirmation-dialog';
import { CardFaceProps } from './card-face-types';
import styles from './card-face.module.scss';
import { useCanvasMouseUp } from './hooks/use-canvas-mouse-up';
import { useCanvasScalingFactor } from './hooks/use-canvas-scaling-factor';
import { useCanvasAccessibility } from './hooks/useCanvasAccessibility';
import { calculateAdjustedScale, closeResetTextModal, confirmResetText, setCroppedSrc } from './utils';
import { registerZoomEvent, resetZoom } from './utils/zoom-mobile-text';

export const CardFace = ({
  cardFace,
  parentRef,
  useOverrideRef,
  addClass,
  cardIndex,
}: CardFaceProps): React.ReactElement => {
  const classes = classNames(styles['card-face'], addClass);
  const { cardState } = useCardContext();
  const currentCardFace = cardState.cardFacesList[`${cardState.activeCardIndex}`];
  const isInsideRight = currentCardFace?.type === 'inside-right';
  const { appState, appDispatch } = useAppContext();
  const { firstElementSelected, updateEditFormats, trackWamTamReset } = useAnalyticsContext();
  const activeCanvas = useActiveCanvas();
  const { initializedDataState } = useInitializationDataContext();
  const isOneToMany = useIsOneToMany();
  const projectTypeCode = initializedDataState.data?.project_type_code;
  const [hasEditingStarted, setHasEditingStarted] = useState(false);
  const [isResetTextModalOpen, setIsResetTextModalOpen] = useState(false);
  const showInstructions = projectTypeCode === CardType.SAS && !hasEditingStarted;
  const { isUK, data } = initializedDataState;
  const [containerRef, { width: containerWidth, height: containerHeight }] = useElementSize();
  const instructionsRef = useRef<HTMLDivElement | null>(null);
  const { canvasWrapperRef, activeLabel } = useCanvasAccessibility();
  const canvasAriaLabel = useMemo(
    () => activeLabel || `${cardFace.type.replace('-', ' ')} card face`,
    [activeLabel, cardFace],
  );
  const { addAction, textAction } = useDatadog();
  const { getCanvasDimensionsWithBleed, getImageDimensionsWithBleed } = useDimensionsWithBleed();
  const { t } = useTranslation();
  const { DISPLAY_ZOOM_DEV, SAS_DYNAMIC_BUTTONS, IS_SPELLCHECK_ENABLED } = useFeatureFlags();
  const canvasDimensionsWithBleed = getCanvasDimensionsWithBleed(cardFace);
  const imageDimensionsWithBleed = getImageDimensionsWithBleed(cardFace);
  const isMobile = appState.isMobileApp;
  const editingHybridBox = useRef<boolean>(false);
  const checkSpellingRef = useRef<boolean>(false);
  const { resetTextData, handleMouseUp } = useCanvasMouseUp({
    cardIndex,
    editingHybridBox,
    setIsResetTextModalOpen,
    fabric,
  });

  useEffect(() => {
    checkSpellingRef.current = appState.checkSpelling;
  }, [appState.checkSpelling]);

  const onEditableTextChange = (textbox: fabric.Textbox) => {
    const { fixedWidth, maxFontSize, maxLines } = textbox.data;
    if (textbox.textLines.length <= maxLines && getMaxWidthLine(textbox) < fixedWidth) {
      while (
        textbox.textLines.length <= maxLines &&
        textbox.fontSize &&
        textbox.fontSize < maxFontSize &&
        getMaxWidthLine(textbox) < fixedWidth
      ) {
        expandText(textbox, maxFontSize);
      }
    }
    if (textbox.textLines.length > maxLines || getMaxWidthLine(textbox) > fixedWidth) {
      const hasEndingNewLine = /\n$/.test(textbox.text || '');
      if (hasEndingNewLine) {
        mergeLines(textbox);
      }
      while (textbox.textLines.length > maxLines || getMaxWidthLine(textbox) > fixedWidth) {
        shrinkText(textbox);
        if (
          textbox.fontSize === MIN_FONT_SIZE &&
          (textbox.textLines.length > maxLines || getMaxWidthLine(textbox) > fixedWidth)
        ) {
          const textLength = textbox.text?.length || 0;
          const offset = textbox.selectionEnd || textLength;
          textbox.removeChars(offset - 1, offset);
          setIsToasterOpen(appDispatch, {
            variant: ToastVariants.Warning,
            children: t('editableText.toastWarningDescription'),
            title: t('editableText.toastWarningTitle'),
          });
          if (textbox.hiddenTextarea) {
            textbox.hiddenTextarea.value = textbox.text as string;
          }
          textbox.setSelectionStart(offset - 1);
          textbox.setSelectionEnd(offset - 1);
          textbox.width = fixedWidth;
        }
      }
    }
    textbox.width = fixedWidth;
  };

  const onObjectSelection = useCallback(
    (selected: fabric.Object) => {
      // Hide resize controls when canResizeTextArea flag is set to false.
      if (selected.type === 'textbox') {
        textAction(ActionType.SELECT_TEXT, parseTextAction(selected as FabricTextBox));
        const textZone = selected as FabricTextBox;
        if (textZone.CanResizeTextArea === false) {
          textZone.setControlVisible('resizeControl', false);
          textZone.setControlsVisibility({
            mr: false,
            ml: false,
            bl: false,
            mtr: false,
            tr: false,
            tl: false,
            br: false,
          });
        } else {
          textZone.setControlVisible('resizeControl', true);
          textZone.setControlsVisibility({
            mr: true,
            ml: true,
            bl: true,
            mtr: true,
            tr: true,
            tl: true,
            br: true,
          });
        }

        if (textZone.CanDeleteTextArea === false) {
          textZone.setControlVisible('deleteControl', false);
          textZone.setControlsVisibility({
            mtr: false,
          });
        } else {
          textZone.setControlVisible('deleteControl', true);
          textZone.setControlsVisibility({
            mtr: true,
          });
        }
      }
      // Drawer should not be open when the user clicks on a text zone.
      if (selected?.data?.type === CanvasDataTypes.EditableText) {
        return setAllDrawersClosed(appDispatch);
      }
      if (selected.type === 'textbox') {
        setAllDrawersClosed(appDispatch);
        setIsTextDrawerOpen(appDispatch);
      }

      if (selected.name?.startsWith(CanvasDataTypes.PhotoTextZone)) {
        const photoTextZone = selected as fabric.Group;
        const originalTextbox = getGroupedItemByName(CanvasDataTypes.PhotoZoneTextbox, photoTextZone) as fabric.Text;
        if (originalTextbox && originalTextbox.text && originalTextbox.text.length > 0) {
          setIsTextDrawerOpen(appDispatch);
        } else {
          setAllDrawersClosed(appDispatch);
        }
      }

      if (selected.type === 'image') {
        setIsImageEditDrawerOpen(appDispatch);
      }
    },
    [appDispatch],
  );

  const canvasRef = useOverrideRef ? createRef<fabric.Canvas>() : cardFace.canvas;

  const handleResetTextOnClose = () => {
    closeResetTextModal(setIsResetTextModalOpen);
  };

  const handleResetTextConfirmation = () => {
    confirmResetText(resetTextData, SAS_DYNAMIC_BUTTONS, setIsResetTextModalOpen);
  };

  const handleOnSpellcheckTrigger = useCallback(
    (options: fabric.IEvent<MouseEvent>) => {
      if (IS_SPELLCHECK_ENABLED) {
        // the handleSelectionSpellcheck function validates the object type of the deselected/updated object
        handleSelectionSpellcheck({
          options,
          projectTypeCode,
          shouldHighlightErrors: checkSpellingRef.current,
          appDispatch,
          handleSpellcheckCallback: handleSpellcheckSuggestions,
          setSkipSpellcheckValidation,
        });
      }
    },
    [checkSpellingRef],
  );

  const canvas = useFabric({
    canvasRef,
    canvasParentRef: parentRef,
    canvasSettings: {
      preserveObjectStacking: true,
      enableRetinaScaling: true,
      selection: false,
    },
    onReady: (canvas) => {
      /** Event handler for zooming on mobile devices */
      if (isMobile) {
        registerZoomEvent(canvas, isMobile, DISPLAY_ZOOM_DEV);
      }

      canvas.on('mouse:up', (options) => handleMouseUp({ options, canvasRef, canvas }));

      // Type any was added here because we were blocked by build errors when publishing.  We need
      // to go back and figure out the correct type, and why our linting isn't catching the error
      // when removing this type casting.
      canvas.on('selection:updated', (options: any) => {
        // Handle spell checking for cases when object selection is updated instead of a textbox being deselected
        handleOnSpellcheckTrigger(options);

        const selectedObj = options.selected && options.selected[0];
        if (selectedObj) {
          onObjectSelection(selectedObj);
        }
      });

      canvas.on('text:changed', (event) => {
        const textbox = event.target;
        if (!textbox || !isEditableText(textbox)) {
          return;
        }
        textAction(ActionType.EDIT_TEXT, {} as TextAction);
        onEditableTextChange(textbox);
      });

      // Same as the above comment about type any
      canvas.on('object:modified', (options) => {
        if (options?.action === 'rotate') {
          setIsRotationDrawerOpen(appDispatch);
        }
      });

      canvas.on('selection:cleared', (options) => {
        const deselectedElement = options.deselected?.[0];

        if (
          (deselectedElement && deselectedElement.data?.type === CanvasDataTypes.PhotoZoneImage) ||
          (deselectedElement && deselectedElement.data?.type === CanvasDataTypes.WamImage) ||
          (deselectedElement && deselectedElement.data?.type === CanvasDataTypes.UserImage)
        ) {
          addAction('deselect-image', {
            image: deselectedElement.data,
          });
        } else if (
          (deselectedElement && deselectedElement.data?.type === CanvasDataTypes.PhotoTextZone) ||
          (deselectedElement && deselectedElement.data?.type === CanvasDataTypes.EditableText)
        ) {
          textAction(ActionType.DESELECT_TEXT, parseTextAction(deselectedElement as FabricTextBox));
        }

        /* There doesn't appear to be a way to directly detect click events on the reset button object
         * using a boolean that activates when entering a hybrid TAM/WAM box
         */
        if (editingHybridBox.current) {
          trackWamTamReset(true);
          editingHybridBox.current = false;
        }
        setAllDrawersClosed(appDispatch);
        resetZoom(canvas);
        handleOnSpellcheckTrigger(options);
      });

      canvas.on('drop', (event) => {
        onPhotoTrayImageDrop({
          event,
          data,
          isUK,
          setIsImageUploadDrawerOpen,
          hideMiddleControls,
          canvas,
          appDispatch,
        });
      });

      canvas.setDimensions(canvasDimensionsWithBleed);
      if (cardFace.originalCanvasJson) {
        const scaledCanvasJson = useCanvasScalingFactor(
          cardFace.originalCanvasJson,
          cardFace.type,
          cardState,
          projectTypeCode as string,
        );

        scaledCanvasJson.objects.forEach((obj: FabricObject) => {
          if (obj && obj.type === 'image') {
            (obj as fabric.Image).crossOrigin = 'anonymous';
            // If the image has been cropped, replace the src with the s3 cropped url.
            setCroppedSrc(obj as CustomFabricImage);
          }
          // Get dynamic_texboxes values from the template for photoTextZones
          if (obj && obj.name?.startsWith(CanvasDataTypes.PhotoTextZone)) {
            const textbox = obj as FabricTextBox;
            const templateTextField = findTextById(cardState.cardFacesList, textbox.data.ID);
            textbox.CanResizeTextArea = templateTextField?.CanResizeTextArea;
            textbox.CanEditFontSize = templateTextField?.CanEditFontSize;
          }
        });
        // the photozone is created first, then the img is attached
        const myPhotozone = {} as fabric.Group;

        canvas.loadFromJSON(
          scaledCanvasJson,
          () => {
            canvas.renderAll.bind(canvas);
            canvas
              .getObjects('textbox')
              .filter((obj) => obj.data?.type === CanvasDataTypes.EditableText)
              .forEach((fabricItem) => {
                const editableText = fabricItem as fabric.Textbox;
                const editIconScale = getSizeAfterScaling(40, canvas);
                const maxLines = editableText.data?.maxLines;
                const maxFontSize = editableText.data?.maxFontSize;
                // using the fabric options as the settings since this was already populated with the settings in the first card load.
                const settings = fabricItem as unknown as EditableTextSettings;
                getEditableTextButton(settings, editIconScale, isUK).then((icon) => {
                  setEditableTextHandlers(icon, editableText, maxFontSize, maxLines, cardState);
                  canvas.add(icon);
                  canvas.bringToFront(icon);
                  canvas.requestRenderAll();
                });
              });
            cardState.cardFacesList.map((face) => addEditableAreas(face, cardState, false, isOneToMany));

            const onIconAdded = (icon: fabric.Image) => {
              icon.on('mousedown', () => {
                setIsToasterOpen(appDispatch, {
                  title: t('lowResolutionImage.title'),
                  children: t('lowResolutionImage.message'),
                  variant: ToastVariants.Warning,
                });
              });
            };

            addIconsToLowResImages(canvas, onIconAdded);
          },
          (jsonObject: ObjectJson, fabricItem: FabricObject) => {
            if (fabricItem) {
              if (fabricItem.type === 'image') {
                hideMiddleControls(fabricItem);
              }

              if (fabricItem.data?.type === 'userUploadedImage') {
                clipObject(myPhotozone[(fabricItem as CustomFabricObject).imgToZoneId], fabricItem);
              }

              if (fabricItem.name?.startsWith('photozone')) {
                myPhotozone[(fabricItem as CustomFabricObject).zoneId] = fabricItem;
              }

              if (fabricItem.name?.startsWith(CanvasDataTypes.PhotoTextZone) && fabricItem.data?.hasContent) {
                const textbox = (fabricItem as fabric.Group)._objects[0];
                textbox?.on('changed', () => {
                  toggleTamWarning(textbox as fabric.Textbox, fabricItem as fabric.Group, appDispatch, t);
                });
              }

              if (fabricItem.isType('textbox')) {
                (fabricItem as fabric.Textbox).editable = appState.hasMouse;
                fabricItem.selectable = true;

                if (fabricItem.name?.startsWith('userTextbox')) {
                  lockEditableSize(fabricItem as fabric.Textbox, cardState, activeCanvas, appDispatch, t);
                }

                if (fabricItem.data?.type === CanvasDataTypes.UserText) {
                  const defaultTextMsg = `${t('cardEditor.userTextDefaultMessage')}`;
                  const defaultColor = isUK ? '#333333' : initializedDataState.defaultColor || BrandColors.Black;
                  addUserTextHandlers(fabricItem as fabric.Textbox, defaultTextMsg, defaultColor, canvas);
                }
              }

              if (fabricItem.isType('group')) {
                fabricItem.setControlsVisibility({
                  mt: false,
                  mb: false,
                  ml: false,
                  mr: false,
                  bl: false,
                  br: false,
                  tl: false,
                  tr: false,
                  mtr: false,
                });
              }
            }
          },
        );
      } else {
        const backgroundSrc = cardFace.backgroundImage.replaceAll('\\', '/');
        if (!backgroundSrc || backgroundSrc === '' || backgroundSrc.includes('?w=0')) {
          setIsSystemErrorOpen(appDispatch, true);
          return;
        }
        fabric.Image.fromURL(
          `${backgroundSrc}?w=${imageDimensionsWithBleed.width}`,
          (img) => {
            // by setting the options from the imageDimensionsWithBleed, the height and width are also set
            // and this causes the image to be cropped since fabric 2.0.0
            // we use the scaling method to obtain the desired result
            // the rest of the options are set below, in the last param of fabric.Image.fromURL
            img.scaleToWidth(imageDimensionsWithBleed.width);
            canvas.setBackgroundImage(img, () => {
              // as of fabric 2 it is recommended to use requestRenderALl instead of renderAll
              canvas.requestRenderAll();
              //cardStore.changesSinceLastSave = 0;
            });
          },
          {
            left: imageDimensionsWithBleed.left,
            top: imageDimensionsWithBleed.top,
            fill: 'transparent',
            originX: 'left',
            originY: 'top',
            crossOrigin: 'anonymous',
          },
        );
      }

      if (cardState.cardFormat === 'landscape' && cardFace.type === 'inside') {
        const horizontalLine = new fabric.Rect({
          top: canvasDimensionsWithBleed.height / 2,
          width: cardFace.dimensions.width,
          height: 2,
          fill: 'gray',
          hasControls: false,
          evented: false,
          selectable: false,
          data: { type: CanvasDataTypes.FoldLine },
        });
        canvas.add(horizontalLine);
        canvas.renderAll();
      }

      if (cardState.cardFormat === 'portrait' && cardFace.type === 'inside') {
        const verticalLine = new fabric.Rect({
          left: canvasDimensionsWithBleed.width / 2,
          height: cardFace.dimensions.height,
          width: 2,
          fill: 'gray',
          hasControls: false,
          evented: false,
          selectable: false,
          data: { type: CanvasDataTypes.FoldLine },
        });
        canvas.add(verticalLine);
        canvas.renderAll();
      }
    },
  });

  const updateSize = useCallback(() => {
    const { width: canvasWidth, height: canvasHeight } = canvasRef.current || {};
    const areCanvasDimensionsSet =
      containerHeight && containerWidth && canvasHeight && canvasWidth && canvasRef.current;
    if (!areCanvasDimensionsSet) {
      return;
    }

    const scale = calculateAdjustedScale({
      containerWidth,
      canvasWidth,
      containerHeight,
      canvasHeight,
      isMobile,
      isOneToMany,
      projectTypeCode,
      isInsideRight,
      instructionsRef,
    });

    const zoom = canvasRef.current.getZoom() * scale;
    canvasRef.current.setDimensions({
      width: canvasWidth * scale,
      height: canvasHeight * scale,
    });
    canvasRef.current.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);

    const groups = canvasRef.current?.getObjects('group') as fabric.Group[];
    if (groups && groups.length > 0) {
      groups.forEach((group) => {
        const zoneDescription = getPhotoTextZoneDescription(group);
        if (zoneDescription) {
          const zoneWidth = zoneDescription.getScaledWidth();
          if (!isMobile) {
            // scale label and edit icon
            zoneDescription.getObjects().forEach((obj) => obj.scale(1 / zoom));
          }
          const zoomFactor = isMobile ? 1 - zoom / 2 : 1 / zoom;
          // get the offset for zone's label, necessary when a browser's (like FF) rendering results in larger char width
          const gap = 12 + (!isMobile ? getZoneLabelOffset(zoneDescription) : 0);
          // space content evenly after resize
          spaceContentEvenly(zoneDescription, gap * zoomFactor, -zoneWidth * zoomFactor);
        }
      });
    }
    canvasRef.current.renderAll();
  }, [activeCanvas, canvasRef.current, containerRef.current, containerHeight, containerWidth]);

  const onEditingStarted = useCallback(() => setHasEditingStarted(true), []);

  useEffect(() => {
    if (showInstructions) {
      activeCanvas?.current?.on('mouse:down', onEditingStarted);
    }

    return () => {
      activeCanvas?.current?.off('mouse:down', onEditingStarted);
    };
  }, [activeCanvas, showInstructions]);

  useEffect(() => {
    if (activeCanvas?.current) {
      const photoTextZones = activeCanvas.current.getObjects().filter((obj) => obj.name?.startsWith('photoTextZone'));
      photoTextZones.forEach((zone) => {
        const warningIcon = getObjectByName(`${zone.name}-warning-icon`, activeCanvas.current as fabric.Canvas);
        if (warningIcon?.visible) {
          zone.set('borderColor', colors.error);
        }
      });
    }
  }, [activeCanvas]);

  useEffect(() => {
    updateSize();
  }, [activeCanvas, containerHeight, containerWidth]);

  const handleObjectSelection = useCallback(
    (options) => {
      setHasEditingStarted(true);
      const selectedObj = options.selected && options.selected[0];

      if (selectedObj && selectedObj.data) {
        const isEditableTextObject =
          !selectedObj.data.isFixed ||
          selectedObj.CanResizeTextArea ||
          selectedObj.CanDeleteTextArea ||
          selectedObj.data.displayFontToolbox;

        if (selectedObj.data.type === CanvasDataTypes.EditableText && activeCanvas && !isEditableTextObject) {
          selectedObj.enterEditing();
          selectedObj.selectAll();
          activeCanvas.current?.renderAll();
          selectedObj.hiddenTextarea.focus(); // focus on the hidden textarea
        }
        onObjectSelection(selectedObj);
        // if this is the first element selected, add this 'start' eventAction instead of 'format', then set the flag so we don't do this again
        if (!firstElementSelected.current) {
          updateEditFormats({ eventAction: 'start' });
          // if it's a S&S photoTextZone, we are setting the start/add key elsewhere
          if (selectedObj.name?.indexOf(CanvasDataTypes.PhotoTextZone) === -1) {
            firstElementSelected.current = true;
          }
        }
      }
    },
    [firstElementSelected.current, onObjectSelection, updateEditFormats],
  );
  useEffect(() => {
    activeCanvas?.current?.on('selection:created', handleObjectSelection);
    return () => {
      activeCanvas?.current?.off('selection:created', handleObjectSelection);
    };
  }, [activeCanvas, handleObjectSelection]);

  const noInstructionsClassName = !showInstructions ? styles['card-face__no-instructions'] : '';

  return (
    <div className={`${classes} ${noInstructionsClassName}`}>
      <div className={styles['card-container']} ref={containerRef}>
        {isOneToMany && (
          <div className={styles['beta-label-container']}>
            <Pill title="Beta" addClass={styles['beta-label']} />
          </div>
        )}
        <div
          aria-label={canvasAriaLabel}
          aria-live="polite"
          className={styles['canvas-wrapper']}
          ref={canvasWrapperRef}
          tabIndex={0}
          id={`${cardFace.faceNumber}_TABPANEL`}
          role="tabpanel"
          aria-labelledby={`${cardFace.faceNumber}_TAB`}
          data-testid={`canvas-wrapper-${cardFace.faceNumber}`}
        >
          <FabricCanvas canvasRef={canvas} />
          {projectTypeCode === CardType.SAS && cardState?.activeCardIndex > 0 && (
            <div
              className={`${
                cardState.cardFormat === 'landscape' ? styles['landscape-instructions'] : styles['instructions']
              } ${hasEditingStarted ? styles['instructions__hidden'] : ''}`}
            >
              <Typography
                variant={TypographyVariants.Headline6}
                color={BrandColors.Black}
                addClass={styles['instructions-text']}
              >
                {t('cardFace.personalMessageInside')}
              </Typography>
            </div>
          )}
          {projectTypeCode === CardType.SAS && cardState?.activeCardIndex === 0 && (
            <div
              className={`${
                cardState.cardFormat === 'landscape' ? styles['landscape-instructions'] : styles['instructions']
              } ${hasEditingStarted ? styles['instructions__hidden'] : ''}`}
              ref={instructionsRef}
            >
              <Typography
                variant={TypographyVariants.Headline6}
                color={BrandColors.Black}
                addClass={styles['instructions-text']}
              >
                {t('cardFace.personalMessageOutside')}
              </Typography>
            </div>
          )}
        </div>
        <ResetTextConfirmationDialog
          isOpen={isResetTextModalOpen}
          onClose={handleResetTextOnClose}
          onConfirm={handleResetTextConfirmation}
        />
      </div>
    </div>
  );
};
