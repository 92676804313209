import API from '@dotcom/api-middleware';
import { digitalAddresGetResponse, saveDigitalAddressResponse } from '../data/address-response';
import {
  EmailAddressForm,
  SaveAssetResponse,
  AssetFormData,
  GetDigitalAddress,
  SaveDigitalAddress,
} from '../global-types/addresses';
import { ApiResponse } from '../global-types/services';
import { getAccountIdFromCookie } from '../utils/utility/get-account-id-from-cookie';
import { processResponse } from './middleware';

const PODService = new API();
const accountId = getAccountIdFromCookie();
const mockResponse = process.env.REACT_APP_MOCK_INITIALIZATION_SERVICE === 'true';

/**
 * Get all recipients associated with a project id and account id
 *
 * @param projectId - ProjectID associated with the card
 * @returns void
 */
export const getRecipients = (projectId: string): Promise<GetDigitalAddress> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      resolve(digitalAddresGetResponse);
    }
    const response = PODService.get('/customization', `/${accountId}/${projectId}/recipients`);
    processResponse(response, resolve, reject);
  });
};

/**
 * Create and associate a recipient to a project id and account id
 *
 * @param projectId - ProjectID associated with the card
 * @param address - Address data
 * @returns void
 */
export const saveRecipient = (projectId: string, address: EmailAddressForm): Promise<SaveDigitalAddress> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      resolve(saveDigitalAddressResponse);
    }
    const response = PODService.post('/customization', `/${accountId}/${projectId}/recipients`, address);
    processResponse(response, resolve, reject);
  });
};

/**
 * Updates an existing recipient
 *
 * @param projectId - ProjectID associated with the card
 * @param addressId - AddressID associated with the address
 * @param address - Address data
 * @returns void
 */
export const updateRecipient = (
  projectId: string,
  addressId: string,
  address: Omit<EmailAddressForm, 'contact_id' | 'address_type_code'>,
): Promise<SaveDigitalAddress> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      resolve(saveDigitalAddressResponse);
    }
    const response = PODService.put('/customization', `/${accountId}/${projectId}/recipients/${addressId}`, address);
    processResponse(response, resolve, reject);
  });
};

/**
 * Disassociates a recipient from a project.
 *
 * @param projectId - ProjectID associated with the card
 * @param addressId - AddressID associated with the address
 * @returns void
 */
export const deleteRecipient = (projectId: string, addressId: string): Promise<ApiResponse<null> | null> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      resolve(null);
    }
    const response = PODService.delete('/customization', `/${accountId}/${projectId}/recipients/${addressId}`);
    processResponse(response, resolve, reject);
  });
};

/**
 * Saves recipient-specific assets associated with a project.
 *
 * @param projectId
 * @param recipientId
 * @param assetsUrl
 * @returns
 */
export const saveRecipientAssets = (projectId: string, assetsUrl: AssetFormData): Promise<SaveAssetResponse> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      resolve(saveDigitalAddressResponse);
    }
    const recipientId = sessionStorage.getItem('recipient_id');
    const response = PODService.post(
      '/customization',
      `/${accountId}/${projectId}/recipients/${recipientId}/assets`,
      assetsUrl,
    );
    processResponse(response, resolve, reject);
  });
};
