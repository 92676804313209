import { datadogRum } from '@datadog/browser-rum';
import { useInitializationDataContext } from '../context/data-context/index';
import { getCookie } from '../utils/index';

export type TextAction = {
  text: string;
  color: string;
  fontSize: number;
  fontStyle: string;
  order: number;
  cardFace: string;
  userZoneId?: string;
  textPosition: {
    positionX: number;
    positionY: number;
  };
};

export enum ActionType {
  ADD_TEXT = 'add-text',
  DELETE_TEXT = 'delete-text',
  SELECT_TEXT = 'select-text',
  DESELECT_TEXT = 'deselect-text',
  EDIT_TEXT = 'edit-text',
  EDIT_TEXT_STYLE = 'edit-text-style',
}

/**
 * Adds an action to datadog with data automatically derived from the necessary contexts
 */
export const useDatadog = () => {
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();

  /**
   * Adds an action to datadog with data automatically derived from the necessary contexts
   *
   * @param actionName The name of the action to add
   * @param actionData The data to add to the action
   * @param overrides An object with optional overrides for the "user" and "project" objects
   */
  const addAction = (
    actionName: string,
    actionData: { [key: string]: unknown },
    overrides?: {
      user?: { [key: string]: unknown };
      project?: { [key: string]: unknown };
    },
  ) =>
    datadogRum.addAction(actionName, {
      ...actionData,
      project: {
        productId: initializedData?.product_id,
        projectId: initializedData?.project_id,
        productType: initializedData?.project_type_code,
        ...overrides?.project,
      },
      user: {
        accountId: initializedData?.account_id,
        accountType: getCookie('accountType'),
        customerId: initializedData,
        ...overrides?.user,
      },
    });

  /**
   * Starts a timed vital through datadog. It will return a function that you call to stop the vital timing
   *
   * @param vitalName The name of the vital you're starting
   * @param vitalData Any extra data you may want to include with the vital
   * @param overrides An object with optional overrides for the "user" and "project" objects
   */
  const startVital = (
    vitalName: string,
    vitalData?: { [key: string]: unknown },
    overrides?: {
      user?: { [key: string]: unknown };
      project?: { [key: string]: unknown };
    },
  ) => {
    const context = {
      ...vitalData,
      project: {
        productId: initializedData?.product_id,
        projectId: initializedData?.project_id,
        productType: initializedData?.project_type_code,
        ...overrides?.project,
      },
      user: {
        accountId: initializedData?.account_id,
        accountType: getCookie('accountType'),
        customerId: initializedData,
        ...overrides?.user,
      },
    };

    const vital = datadogRum.startDurationVital(vitalName, {
      context,
    });

    return (vitalData?: { [key: string]: unknown }) =>
      datadogRum.stopDurationVital(vital, {
        context: {
          ...context,
          ...vitalData,
        },
      });
  };

  const textAction = (type: ActionType, textAction: TextAction) => {
    addAction(type, textAction);
  };

  return {
    addAction,
    startVital,
    textAction,
  };
};
