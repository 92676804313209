const isScriptInDocument = (url: string) => {
  const scripts = document.getElementsByTagName('script');
  for (let i = scripts.length; i--; ) {
    if (scripts[`${i}`].src === url) return true;
  }
  return false;
};

export const initializeGtmAnalytics = (
  argumentWindow: Window,
  argumentDocument: Document,
  argumentScriptType: string,
  argumentDataLayer: string,
  gtmID: string,
) => {
  argumentWindow.dataLayer = window.dataLayer || [];
  argumentWindow.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  const assembledDataLayer = argumentDataLayer !== 'dataLayer' ? '&l=' + argumentDataLayer : '';
  const assembledGtmScript = 'https://www.googletagmanager.com/gtm.js?id=' + gtmID + assembledDataLayer;
  /* To avoid Multiple installations of google tag manager detected warning */
  if (!isScriptInDocument(assembledGtmScript)) {
    const documentScripts = argumentDocument.getElementsByTagName(argumentScriptType)[0],
      documentGtmScriptTag: HTMLScriptElement = argumentDocument.createElement('script');
    documentGtmScriptTag.async = true;
    documentGtmScriptTag.src = assembledGtmScript;
    documentScripts?.parentNode?.insertBefore(documentGtmScriptTag, documentScripts);
  }
};
