export const editAddressFormFieldList = [
  {
    typeOfField: 'textfield',
    name: 'first_name',
  },
  {
    typeOfField: 'textfield',
    name: 'last_name',
  },
  {
    typeOfField: 'textfield',
    name: 'address_line_1',
  },
  {
    typeOfField: 'textfield',
    name: 'address_line_2',
  },
  {
    typeOfField: 'textfield',
    name: 'city',
  },
  {
    typeOfField: 'dropdown',
    name: 'state_code',
  },
  {
    typeOfField: 'textfield',
    name: 'zip',
  },
];
