import { Contact } from '../../components/address-book';
import { AddressSelectionType, Dispatch } from './address-selection-types';

const setAddressSelectionContacts = (dispatch: Dispatch, contacts: Contact[]) => {
  dispatch({
    type: AddressSelectionType.SET_CONTACTS,
    payload: contacts,
  });
};

const setAddressSelectionCount = (dispatch: Dispatch, count: number) => {
  dispatch({
    type: AddressSelectionType.SET_COUNT,
    payload: count,
  });
};

const resetAddressSelectionCount = (dispatch: Dispatch) => {
  dispatch({
    type: AddressSelectionType.RESET_COUNT,
  });
};

const increaseAddressSelectionCount = (dispatch: Dispatch) => {
  dispatch({
    type: AddressSelectionType.INCREASE_COUNT,
  });
};

const decreaseAddressSelectionCount = (dispatch: Dispatch) => {
  dispatch({
    type: AddressSelectionType.DECREASE_COUNT,
  });
};

const setAddressSelectionMaxCount = (dispatch: Dispatch, maxCount: number) => {
  dispatch({
    type: AddressSelectionType.SET_MAX_COUNT,
    payload: maxCount,
  });
};

const setAddressSelectionIsMaxCountReached = (dispatch: Dispatch, isMaxCountReached: boolean) => {
  dispatch({
    type: AddressSelectionType.SET_IS_MAX_COUNT_REACHED,
    payload: isMaxCountReached,
  });
};

export {
  setAddressSelectionContacts,
  setAddressSelectionCount,
  resetAddressSelectionCount,
  increaseAddressSelectionCount,
  decreaseAddressSelectionCount,
  setAddressSelectionMaxCount,
  setAddressSelectionIsMaxCountReached,
};
