import React, { createContext, useCallback, useContext, useEffect, useRef } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { CardFaceTypes } from '../../global-types/card-face';
import { useFeatureFlags } from '../../hooks';
import { getGroupedItemByName, CanvasDataTypes } from '../../utils';
import { useAppContext } from '../app-context';
import { useCardContext } from '../card-context';
import { useInitializationDataContext } from '../data-context';
import { useAnalytics } from './analytics-context-hooks';
import { POD_TAGS } from './analytics-context-tags';
import {
  AnalyticsProviderProps,
  CreateContextProps,
  EditFormats,
  ParamsElementPosition,
  TrackEventParams,
  leaveAction,
} from './analytics-context-types';
import { getDefaultProductOptions } from './utils/get-default-product-options';
import { getElementPosition } from './utils/get-element-position';
import { initializeDataDog } from './utils/initialize-data-dog';
import { initializeGtmAnalytics } from './utils/initialize-gtm';
import { trackEvent } from './utils/track-event';

const defaultEditFormats: EditFormats = {
  textAlignment: '',
  addColor: '',
  color: '',
  fontName: '',
  fontSize: '',
  crop: '',
  order: '',
  rotate: '',
  size: '',
  replace: '',
  move: null,
  eventAction: null,
  isTextModified: null,
};

export const AnalyticsContext = createContext<CreateContextProps>(undefined);

export const AnalyticsContextProvider = ({ children, shouldInjectScripts }: AnalyticsProviderProps) => {
  const { buildTag, track, dataLayerAvailable } = useAnalytics();
  const { initializedDataState } = useInitializationDataContext();
  const { cardState } = useCardContext();
  const { appState } = useAppContext();
  const { isUS, isUK } = initializedDataState;
  // README: below is useful in testing analytics as all analytics tags use the trackEvent function
  // make sure not to commit this as 'true'
  const debug = false;

  const editFormats = useRef<EditFormats>(defaultEditFormats);
  const uploadedPhotos = useRef<number[]>([]);
  const firstElementSelected = useRef(false);
  const isReplacingImage = useRef(false);
  const { IS_CHATBOT_ENABLED, IS_ADOBE_ANALYTICS_ENABLED } = useFeatureFlags();
  const pageNavigationButtonClicked = useRef(null);
  const isOnEmailSplashScreen = useRef(false);
  const { activeCardIndex, cardFacesList } = cardState;
  const activeCardFace = cardFacesList[`${activeCardIndex}`] ?? {};
  const canvas = activeCardFace?.canvas;
  const publicUrl = process.env.PUBLIC_URL;
  const theme = process.env.REACT_APP_THEME;
  const dataLayer = process.env.REACT_APP_DATA_LAYER_URL;
  const analyticsDtm = process.env.REACT_APP_ADOBE_DTM_URL;
  const chatbotUrl = process.env.REACT_APP_CHATBOT_URL;

  const updateEditFormats = useCallback((editedFormat: Partial<EditFormats>) => {
    editFormats.current = { ...editFormats.current, ...editedFormat };
  }, []);

  const resetEditFormats = (keepFormats?) => {
    updateEditFormats({
      ...defaultEditFormats,
      ...keepFormats,
    });
  };

  const getEditFormats = () => editFormats.current;

  /**
   * Get the active card general area when an event was triggered,
   * with no specification of positioning inside a card face.
   * Can be used when tracking an event without an active object on the canvas.
   * @returns outside-front | outside-back | inside | (card-face)
   */
  const getCurrentCardArea = (): string => {
    const cardFace = activeCardFace.type?.split('-')[0];
    if (cardFace === CardFaceTypes.FACEFRONT) {
      return CardFaceTypes.AREAFRONT;
    } else if (cardFace === CardFaceTypes.FACEBACK) {
      return CardFaceTypes.AREABACK;
    } else if (cardFace === CardFaceTypes.FACEINSIDE) {
      return CardFaceTypes.AREAINSIDE;
    }
    return cardFace;
  };

  const eventParams: TrackEventParams = {
    dataLayerAvailable,
    buildTag,
    debug,
    track,
  };

  const trackElementPositionParams: ParamsElementPosition = {
    cardState,
    initializedDataState,
    activeCardFace,
    canvas,
  };

  const trackStartFlow = (eventAction: string) => {
    const { START_FLOW } = POD_TAGS;
    const product = getDefaultProductOptions(initializedDataState, appState);
    const options = {
      elementPosition: getElementPosition({ paramsElement: trackElementPositionParams }) || 'outside-front',
      eventAction: eventAction,
      ...product,
    };
    trackEvent({ tagType: START_FLOW, trackType: 'page', options: options, params: eventParams });
  };

  const trackExitFlow = () => {
    const { EXIT_FLOW } = POD_TAGS;
    trackEvent({
      tagType: EXIT_FLOW,
      trackType: 'page',
      options: getDefaultProductOptions(initializedDataState, appState),
      params: eventParams,
    });
  };

  const trackDeleteElement = () => {
    const { DELETE_ELEMENT } = POD_TAGS;
    const activeObject = canvas?.current?.getActiveObject();
    const originalTextbox =
      activeObject && activeObject?.type === 'group'
        ? getGroupedItemByName(CanvasDataTypes.PhotoZoneTextbox, activeObject as fabric.Group)
        : null;
    if (activeObject || originalTextbox) {
      const options = {
        ...getDefaultProductOptions(initializedDataState, appState),
        elementType: originalTextbox ? originalTextbox.type : activeObject?.type,
        elementPosition: getElementPosition({
          paramsElement: trackElementPositionParams,
          object: originalTextbox as fabric.Object,
        }),
      };
      trackEvent({ tagType: DELETE_ELEMENT, trackType: 'link', options, params: eventParams });
    }
  };

  // TODO: this has been disabled because we don't want anything to be tracked on simply
  // selection. Please review at a later time.
  const trackObjectSelection = (targetObject: fabric.Object, actionType: string) => {
    const { OBJECT_SELECTION } = POD_TAGS;
    const options = {
      ...getDefaultProductOptions(initializedDataState, appState),
      elementType: targetObject?.data?.type ?? targetObject?.type,
      elementPosition: getElementPosition({ paramsElement: trackElementPositionParams, object: targetObject }),
      actionType: actionType,
    };
    trackEvent({ tagType: OBJECT_SELECTION, trackType: 'link', options, params: eventParams });
  };

  const trackClickAddressRecipient = () => {
    const { CLICK_ADDRESS_RECIPIENT } = POD_TAGS;
    trackEvent({
      tagType: CLICK_ADDRESS_RECIPIENT,
      trackType: 'page',
      options: getDefaultProductOptions(initializedDataState, appState),
      params: eventParams,
    });
  };

  const trackClickNextToAddressSender = () => {
    const { CLICK_NEXT_ADDRESS_SENDER } = POD_TAGS;
    trackEvent({
      tagType: CLICK_NEXT_ADDRESS_SENDER,
      trackType: 'page',
      options: getDefaultProductOptions(initializedDataState, appState),
      params: eventParams,
    });
  };

  const trackLoadPreview = () => {
    const { CLICK_PREVIEW } = POD_TAGS;
    trackEvent({
      tagType: CLICK_PREVIEW,
      trackType: 'page',
      options: getDefaultProductOptions(initializedDataState, appState),
      params: eventParams,
    });
  };

  const trackClickPreviewMode = () => {
    const { CLICK_PREVIEW_MODE } = POD_TAGS;
    const product = getDefaultProductOptions(initializedDataState, appState);
    const elementPosition = getElementPosition({ paramsElement: trackElementPositionParams });
    const cardFace = elementPosition === CardFaceTypes.FACEFRONT ? CardFaceTypes.AREAFRONT : CardFaceTypes.AREABACK;

    const options = {
      elementPosition: pageNavigationButtonClicked.current === 'Thumbnail' ? cardFace : elementPosition,
      button: pageNavigationButtonClicked.current,
      ...product,
    };
    trackEvent({ tagType: CLICK_PREVIEW_MODE, trackType: 'link', options, params: eventParams });
  };

  const trackRetryHandlerTriggered = (eventAction: string, errorMessages: string[]) => {
    const { RETRY_TRIGGERED } = POD_TAGS;
    const options = {
      ...getDefaultProductOptions(initializedDataState, appState),
      eventAction: eventAction,
      errorMessages: errorMessages,
    };
    trackEvent({ tagType: RETRY_TRIGGERED, trackType: 'link', options, params: eventParams });
  };

  const trackReturnToEdit = () => {
    const { RETURN_TO_EDIT } = POD_TAGS;
    trackEvent({
      tagType: RETURN_TO_EDIT,
      trackType: 'page',
      options: getDefaultProductOptions(initializedDataState, appState),
      params: eventParams,
    });
  };

  const trackAddToCart = () => {
    const { ADD_TO_CART } = POD_TAGS;
    trackEvent({
      tagType: ADD_TO_CART,
      trackType: 'page',
      options: getDefaultProductOptions(initializedDataState, appState),
      params: eventParams,
    });
  };

  /**
   * Tracks when the user clicks send mail now for DG
   * @param eventAction
   */
  const trackSendEmailNow = (eventAction: string) => {
    const { SEND_EMAIL_NOW } = POD_TAGS;

    const options = {
      ...getDefaultProductOptions(initializedDataState, appState),
      eventAction,
    };
    trackEvent({ tagType: SEND_EMAIL_NOW, trackType: 'page', options, params: eventParams });
  };

  const trackTextEdits = (activeObject: fabric.Object) => {
    const { TEXT_FORMATS } = POD_TAGS;
    const isTypeAMessage =
      !activeObject?.data?.type &&
      (activeObject?.name?.startsWith(CanvasDataTypes.TemporaryTAMInput) ||
        activeObject?.name?.startsWith(CanvasDataTypes.PhotoTextZone));

    const options = {
      ...getDefaultProductOptions(initializedDataState, appState),
      elementPosition: getElementPosition({ paramsElement: trackElementPositionParams }),
      elementType: isTypeAMessage ? 'type-a-message' : activeObject?.data?.type ?? activeObject?.type,
      eventAction: editFormats.current.eventAction,
      textAlignment: editFormats.current.textAlignment,
      addColor: editFormats.current.addColor,
      color: editFormats.current.color,
      fontName: editFormats.current.fontName,
      fontSize: editFormats.current.fontSize,
      isTextModified: editFormats.current.isTextModified,
      move: editFormats.current.move,
      order: editFormats.current.order,
    };
    trackEvent({ tagType: TEXT_FORMATS, trackType: 'link', options, params: eventParams });
  };

  const trackPhotoEdits = (activeObject: fabric.Object) => {
    const { IMAGE_FORMATS } = POD_TAGS;
    const isWriteAMessage =
      !activeObject?.data?.type && activeObject?.data?.zoneName?.indexOf(CanvasDataTypes.PhotoTextZone) > -1;
    const options = {
      ...getDefaultProductOptions(initializedDataState, appState),
      elementPosition: getElementPosition({ paramsElement: trackElementPositionParams }),
      elementType: isWriteAMessage ? 'write-a-message' : activeObject?.data?.type ?? activeObject?.type,
      eventAction: editFormats.current.eventAction,
      rotate: editFormats.current.rotate,
      order: editFormats.current.order,
      crop: editFormats.current.crop,
      size: editFormats.current.size,
      move: editFormats.current.move,
      replace: editFormats.current.replace,
    };
    trackEvent({ tagType: IMAGE_FORMATS, trackType: 'link', options, params: eventParams });
  };

  const trackEditElement = () => {
    const activeObject = canvas?.current?.getActiveObject();
    const isActiveObjectModified =
      editFormats.current && Object.values(editFormats.current).some((val) => val !== '' && val !== null);

    // stops edits from being reset (at the end of this function updateFormats) if activeobject is write a message or if replacing image
    if (activeObject?.type === 'rect' || activeObject === undefined || isReplacingImage.current) {
      return false;
    }

    // check if element is a textbox and is modified
    activeObject?.type === 'textbox' && isActiveObjectModified && trackTextEdits(activeObject);

    // check if element is a photo and is modified
    activeObject?.type === 'image' && isActiveObjectModified && trackPhotoEdits(activeObject);

    // check if element is a photoTextZone/TAM and is modified (WAM works as the 'image' type above)
    if (activeObject?.type === 'group') {
      const originalTextbox = getGroupedItemByName(CanvasDataTypes.PhotoZoneTextbox, activeObject as fabric.Group);
      originalTextbox &&
        (originalTextbox as fabric.Textbox).text?.length &&
        isActiveObjectModified &&
        trackTextEdits(activeObject);
    }

    resetEditFormats();
  };

  const trackUploadPhotoError = (errorMessage: string) => {
    const { UPLOAD_PHOTO_ERROR } = POD_TAGS;

    const options = {
      ...getDefaultProductOptions(initializedDataState, appState),
      elementPosition: getElementPosition({ paramsElement: trackElementPositionParams }),
      errorMessage,
    };

    trackEvent({ tagType: UPLOAD_PHOTO_ERROR, trackType: 'link', options, params: eventParams });
  };

  const trackAddToCartError = (errorMessage: string) => {
    const { ADD_TO_CART_ERROR } = POD_TAGS;

    const options = {
      ...getDefaultProductOptions(initializedDataState, appState),
      errorMessage,
    };

    trackEvent({ tagType: ADD_TO_CART_ERROR, trackType: 'link', options, params: eventParams });
  };

  const updateUploadedPhotos = (uploadedImage) => uploadedPhotos.current.push(uploadedImage);

  const trackUploadedPhotos = () => {
    const { UPLOAD_PHOTO } = POD_TAGS;

    const options = {
      ...getDefaultProductOptions(initializedDataState, appState),
      elementPosition: getElementPosition({ paramsElement: trackElementPositionParams }),
      uploadedPhotos: uploadedPhotos.current,
    };

    trackEvent({ tagType: UPLOAD_PHOTO, trackType: 'link', options, params: eventParams });
    uploadedPhotos.current = [];
  };

  /**
   * Tracks when user chooses to have their envelope printed or left blank
   * @param choice - The user's choice for envelope printing (e.g., 'blank' or 'printed')
   * @param options - Other options needed for tracking (e.g., productType, productName, etc.)
   */
  const trackEnvelopePrintChoice = (eventAction: string) => {
    const { ENVELOPE_PRINT_CHOICE } = POD_TAGS;

    const options = {
      ...getDefaultProductOptions(initializedDataState, appState),
      eventAction,
    };

    trackEvent({ tagType: ENVELOPE_PRINT_CHOICE, trackType: 'link', options, params: eventParams });
  };

  /**
   * Tracks when user attempts to move to addressing page, but still has unedited zones on the front of their card.  In these cases,
   * the user is warned and brought to the front of the card.
   *
   * @param statusMessage - The message that is displayed to the user
   */
  const trackUneditedZoneError = (statusMessage: string, object: fabric.Object, face: string) => {
    const { UNEDITED_ZONE_ERROR } = POD_TAGS;

    const typeOfObjectEvent = { eventName: '', eventAction: '' };

    if (object.data.type === CanvasDataTypes.EditableText) {
      typeOfObjectEvent.eventName = 'text';
      typeOfObjectEvent.eventAction = 'edit text-error';
    }

    if (object.data.type === CanvasDataTypes.PhotoZone) {
      typeOfObjectEvent.eventName = 'photo';
      typeOfObjectEvent.eventAction = 'missing image - error';
    }

    const options = {
      ...getDefaultProductOptions(initializedDataState, appState),
      elementPosition: getElementPosition({ paramsElement: trackElementPositionParams, object, face }),
      typeOfObjectEvent,
      statusMessage,
    };

    trackEvent({ tagType: UNEDITED_ZONE_ERROR, trackType: 'link', options, params: eventParams });
  };

  /**
   * Tracks when user clicks on the project summary area (quantity-link)
   * to open the project summary drawer
   */
  const trackViewProjectSummary = () => {
    const { VIEW_PROJECT_SUMMARY } = POD_TAGS;
    const options = {
      ...getDefaultProductOptions(initializedDataState, appState),
      eventAction: 'view',
    };

    trackEvent({ tagType: VIEW_PROJECT_SUMMARY, trackType: 'link', options, params: eventParams });
  };

  /**
   * Tracks when user leaves the project summary top drawer on a 1:many card
   *
   * @param eventAction - The method which the user leaves, either the x button or the exit button
   */

  const trackLeaveProjectSummary = (eventAction: 'x' | 'exit') => {
    const { LEAVE_PROJECT_SUMMARY } = POD_TAGS;
    const options = {
      ...getDefaultProductOptions(initializedDataState, appState),
      eventAction,
    };

    trackEvent({ tagType: LEAVE_PROJECT_SUMMARY, trackType: 'link', options, params: eventParams });
  };

  /**
   * Tracks when user attempts to change the product quantity from project summary
   */
  const trackChangeQuantityProjectSummary = () => {
    const { CHANGE_QUANTITY_FROM_PROJECT_SUMMARY } = POD_TAGS;

    const options = getDefaultProductOptions(initializedDataState, appState);

    trackEvent({ tagType: CHANGE_QUANTITY_FROM_PROJECT_SUMMARY, trackType: 'link', options, params: eventParams });
  };

  /**
   * Tracks when user navigates to another page in the card select bar in edit or preview views
   *
   * @param button - Current product quantity in project summary.
   * @param page - Page navigated to
   */
  const trackPageNavigation = () => {
    const { PAGE_NAVIGATION } = POD_TAGS;

    const options = {
      ...getDefaultProductOptions(initializedDataState, appState),
      button: pageNavigationButtonClicked.current,
      page: getElementPosition({ paramsElement: trackElementPositionParams }),
    };

    trackEvent({ tagType: PAGE_NAVIGATION, trackType: 'link', options, params: eventParams });
  };

  /**
   * Tracks how a user opens the image-upload-drawer to upload a photo (photozone or toolbar btn)
   *
   * @param eventAction - 'photo object' (toolbar btn) | 'camera icon' (photozone btn)
   */
  const trackPhotoUploadMethod = (eventAction: string) => {
    const { PHOTO_UPLOAD_METHOD } = POD_TAGS;
    const options = {
      ...getDefaultProductOptions(initializedDataState, appState),
      elementPosition: getCurrentCardArea(),
      eventAction: eventAction,
    };

    trackEvent({ tagType: PHOTO_UPLOAD_METHOD, trackType: 'link', options, params: eventParams });
  };

  const trackSignIn = (eventAction: string) => {
    const { SIGN_IN } = POD_TAGS;

    const options = {
      elementPosition: getElementPosition({ paramsElement: trackElementPositionParams }),
      eventAction: eventAction,
      ...getDefaultProductOptions(initializedDataState, appState),
    };

    trackEvent({ tagType: SIGN_IN, trackType: 'link', options, params: eventParams });
  };

  const trackDGPlayAnimation = () => {
    const { DG_PLAY_ANIMATION } = POD_TAGS;
    const options = {
      ...getDefaultProductOptions(initializedDataState, appState),
      elementPosition: getCurrentCardArea(),
      isOnEmailSplashScreen: isOnEmailSplashScreen.current,
    };

    trackEvent({ tagType: DG_PLAY_ANIMATION, trackType: 'link', options, params: eventParams });
  };

  const trackSuccesfullySentEmail = () => {
    const { SUCCESFULLY_SENT_EMAIL } = POD_TAGS;
    const options = {
      ...getDefaultProductOptions(initializedDataState, appState),
    };

    trackEvent({ tagType: SUCCESFULLY_SENT_EMAIL, trackType: 'page', options, params: eventParams });
  };

  const trackViewEmailSplash = () => {
    const { VIEW_EMAIL_SPLASH } = POD_TAGS;
    const options = getDefaultProductOptions(initializedDataState, appState);

    trackEvent({ tagType: VIEW_EMAIL_SPLASH, trackType: 'link', options, params: eventParams });
  };

  const trackKeepShopping = () => {
    const { KEEP_SHOPPING } = POD_TAGS;
    const options = getDefaultProductOptions(initializedDataState, appState);

    trackEvent({ tagType: KEEP_SHOPPING, trackType: 'link', options, params: eventParams });
  };

  const trackEmailDeliveryRecipient = () => {
    const { EMAIL_DELIVERY_RECIPIENT } = POD_TAGS;
    const options = getDefaultProductOptions(initializedDataState, appState);

    trackEvent({ tagType: EMAIL_DELIVERY_RECIPIENT, trackType: 'page', options, params: eventParams });
  };

  const trackDGViewFacePreview = (isEnvelope) => {
    const { DG_VIEW_FACE_PREVIEW } = POD_TAGS;
    const product = getDefaultProductOptions(initializedDataState, appState);
    const options = {
      elementPosition: isEnvelope ? 'Envelope' : getElementPosition({ paramsElement: trackElementPositionParams }),
      button: pageNavigationButtonClicked.current,
      ...product,
    };
    trackEvent({ tagType: DG_VIEW_FACE_PREVIEW, trackType: 'link', options, params: eventParams });
  };

  const trackClickPreviewCarouselImageTag = (isEnvelope: boolean, activeCardFace: any) => {
    trackElementPositionParams.activeCardFace = activeCardFace;
    const { CLICK_PREVIEW_CAROUSEL_IMAGE } = POD_TAGS;
    const product = getDefaultProductOptions(initializedDataState, appState);
    const options = {
      elementPosition: isEnvelope ? 'envelope' : getElementPosition({ paramsElement: trackElementPositionParams }),
      button: pageNavigationButtonClicked.current,
      ...product,
    };
    trackEvent({ tagType: CLICK_PREVIEW_CAROUSEL_IMAGE, trackType: 'link', options, params: eventParams });
  };

  const trackEditProjectName = () => {
    const { EDIT_PROJECT_NAME } = POD_TAGS;

    const options = {
      ...getDefaultProductOptions(initializedDataState, appState),
      elementPosition: getElementPosition({ paramsElement: trackElementPositionParams }),
      onSplashScreen: isOnEmailSplashScreen.current,
    };

    trackEvent({ tagType: EDIT_PROJECT_NAME, trackType: 'link', options, params: eventParams });
  };

  const trackDefaultProjectName = () => {
    const { DEFAULT_PROJECT_NAME } = POD_TAGS;

    const options = {
      ...getDefaultProductOptions(initializedDataState, appState),
      elementPosition: getElementPosition({ paramsElement: trackElementPositionParams }),
      onSplashScreen: isOnEmailSplashScreen.current,
    };

    trackEvent({ tagType: DEFAULT_PROJECT_NAME, trackType: 'link', options, params: eventParams });
  };

  const trackSaveLink = () => {
    const { SAVE_LINK } = POD_TAGS;

    const options = {
      ...getDefaultProductOptions(initializedDataState, appState),
      elementPosition: getElementPosition({ paramsElement: trackElementPositionParams }),
      onSplashScreen: isOnEmailSplashScreen.current,
    };

    trackEvent({ tagType: SAVE_LINK, trackType: 'link', options, params: eventParams });
  };

  const trackExitSaveProject = (eventAction: 'defaultName' | 'editName', eventExitAction: 'exit' | 'exit-x') => {
    const { EXIT_SAVE_PROJECT } = POD_TAGS;
    const options = {
      ...getDefaultProductOptions(initializedDataState, appState),
      eventAction,
      eventExitAction,
    };

    trackEvent({ tagType: EXIT_SAVE_PROJECT, trackType: 'link', options, params: eventParams });
  };

  const trackWamPhotoUpload = (imageSize: string) => {
    const { WAM_PHOTO_UPLOAD } = POD_TAGS;

    const options = {
      ...getDefaultProductOptions(initializedDataState, appState),
      elementPosition: getElementPosition({ paramsElement: trackElementPositionParams }),
      imageSize,
    };

    trackEvent({ tagType: WAM_PHOTO_UPLOAD, trackType: 'link', options, params: eventParams });
  };

  const trackAbandonOrDeleteProject = (eventAction: leaveAction) => {
    const { ABANDON_OR_DELETE_PROJECT } = POD_TAGS;
    const options = {
      ...getDefaultProductOptions(initializedDataState, appState),
      eventAction,
    };

    trackEvent({ tagType: ABANDON_OR_DELETE_PROJECT, trackType: 'link', options, params: eventParams });
  };

  const trackEditProjectSummaryName = () => {
    const { EDIT_PROJECT_SUMMARY_NAME } = POD_TAGS;

    const options = {
      ...getDefaultProductOptions(initializedDataState, appState),
    };

    trackEvent({ tagType: EDIT_PROJECT_SUMMARY_NAME, trackType: 'link', options, params: eventParams });
  };

  const trackUpdatedProjectNameSuccess = () => {
    const { UPDATED_PROJECT_NAME_SUCCESS } = POD_TAGS;

    const options = {
      ...getDefaultProductOptions(initializedDataState, appState),
    };

    trackEvent({ tagType: UPDATED_PROJECT_NAME_SUCCESS, trackType: 'link', options, params: eventParams });
  };

  const trackRefresh = () => {
    const { REFRESH_TAG } = POD_TAGS;
    const options = {
      ...getDefaultProductOptions(initializedDataState, appState),
      elementPosition: getElementPosition({ paramsElement: trackElementPositionParams }),
      cardFacesList,
    };

    trackEvent({ tagType: REFRESH_TAG, trackType: 'page', options, params: eventParams });
  };

  const trackSaveQuickAddress = (isSender: boolean) => {
    const { SAVE_QUICK_ADDRESS } = POD_TAGS;

    const options = {
      ...getDefaultProductOptions(initializedDataState, appState),
      elementPosition: getElementPosition({ paramsElement: trackElementPositionParams }),
      isSender,
    };

    trackEvent({
      tagType: SAVE_QUICK_ADDRESS,
      trackType: 'page',
      options,
      params: eventParams,
    });
  };

  const trackSelectedQuickAddress = (formTitle: string) => {
    const { SELECTED_QUICK_ADDRESS } = POD_TAGS;

    const options = {
      ...getDefaultProductOptions(initializedDataState, appState),
      elementPosition: getElementPosition({ paramsElement: trackElementPositionParams }),
      formTitle,
    };

    trackEvent({ tagType: SELECTED_QUICK_ADDRESS, trackType: 'link', options, params: eventParams });
  };

  const trackDeleteQuickAddress = (formTitle: string) => {
    const { DELETE_QUICK_ADDRESS } = POD_TAGS;

    const options = {
      ...getDefaultProductOptions(initializedDataState, appState),
      elementPosition: getElementPosition({ paramsElement: trackElementPositionParams }),
      formTitle,
    };

    trackEvent({ tagType: DELETE_QUICK_ADDRESS, trackType: 'link', options, params: eventParams });
  };

  const trackWamTamReset = (resetButton: boolean | null) => {
    const { TAM_EDIT_RESET } = POD_TAGS;

    const options = {
      ...getDefaultProductOptions(initializedDataState, appState),
      eventAction: resetButton ? 'reset button' : 'edit menu',
    };

    // Track WAM/TAM reset
    trackEvent({ tagType: TAM_EDIT_RESET, trackType: 'link', options, params: eventParams });
  };

  const trackSpellcheckDialog = (action: 'fix' | 'ignore' | 'cancel') => {
    const { SPELLCHECK_DIALOG } = POD_TAGS;

    const options = {
      ...getDefaultProductOptions(initializedDataState, appState),
      eventAction: action,
      spellcheckCalls: 1,
    };

    trackEvent({
      tagType: SPELLCHECK_DIALOG,
      trackType: 'link',
      options,
      params: eventParams,
    });
  };

  const trackUneditedTextBoxWarningTag = (statusMessage: string[]) => {
    const { UNEDITED_TEXT_BOX_WARNING } = POD_TAGS;
    const options = {
      ...getDefaultProductOptions(initializedDataState, appState),
      elementPosition: getElementPosition({ paramsElement: trackElementPositionParams }),
      statusMessage,
    };

    trackEvent({ tagType: UNEDITED_TEXT_BOX_WARNING, trackType: 'link', options, params: eventParams });
  };

  useEffect(() => {
    initializeDataDog();
  }, []);

  useEffect(() => {
    isUK && initializeGtmAnalytics(window, document, 'script', 'dataLayer', 'GTM-NJJHJR6');
  }, [isUK]);

  const onTextChanged = useCallback(() => updateEditFormats({ isTextModified: true }), [updateEditFormats]);
  const onObjectMoving = useCallback(() => updateEditFormats({ move: true }), [updateEditFormats]);
  const onObjectRotated = useCallback(
    () => updateEditFormats({ rotate: `${canvas?.current?.getActiveObject()?.angle}` }),
    [updateEditFormats],
  );
  const onBeforeDeselect = useCallback(() => {
    !isReplacingImage.current && trackEditElement();
  }, [trackEditElement, isReplacingImage]);

  const onSelectionUpdate = useCallback(
    (options: any) => {
      const selectedObj = options.deselected && options.deselected[0];
      const { textAlign, fill, fontFamily, fontSize } = selectedObj;

      updateEditFormats({ textAlignment: textAlign, fontName: fontFamily, color: fill, fontSize: fontSize });
      trackTextEdits(selectedObj);
    },
    [canvas],
  );

  useEffect(() => {
    canvas?.current?.on('selection:updated', onSelectionUpdate);

    return () => {
      canvas?.current?.off('selection:updated', onSelectionUpdate);
    };
  }, [onSelectionUpdate]);

  // tracks if text is changed
  useEffect(() => {
    canvas?.current?.on('text:changed', onTextChanged);

    // send activeObject to tracking function before it goes away on deselect
    canvas?.current?.on('before:selection:cleared', onBeforeDeselect);

    // tracks if object is moved
    canvas?.current?.on('object:moving', onObjectMoving);

    // tracks if object is rotated
    canvas?.current?.on('object:rotated', onObjectRotated);

    // unsubscribe from all events
    return () => {
      canvas?.current?.off('text:changed', onTextChanged);
      canvas?.current?.off('before:selection:cleared', onBeforeDeselect);
      canvas?.current?.off('object:moving', onObjectMoving);
      canvas?.current?.off('object:rotated', onObjectRotated);
    };
  }, [canvas, onTextChanged, onBeforeDeselect, onObjectMoving, onObjectRotated]);

  return (
    <AnalyticsContext.Provider
      value={{
        firstElementSelected,
        pageNavigationButtonClicked,
        isReplacingImage,
        isOnEmailSplashScreen,
        trackAddToCart,
        trackAddToCartError,
        trackChangeQuantityProjectSummary,
        trackClickAddressRecipient,
        trackClickNextToAddressSender,
        trackDeleteElement,
        trackLoadPreview,
        trackClickPreviewMode,
        trackObjectSelection,
        trackRetryHandlerTriggered,
        trackReturnToEdit,
        trackStartFlow,
        trackSendEmailNow,
        trackUneditedZoneError,
        updateEditFormats,
        resetEditFormats,
        getEditFormats,
        trackEditElement,
        updateUploadedPhotos,
        uploadedPhotos,
        trackUploadedPhotos,
        trackEnvelopePrintChoice,
        trackUploadPhotoError,
        trackExitFlow,
        trackLeaveProjectSummary,
        trackPageNavigation,
        trackViewProjectSummary,
        trackPhotoUploadMethod,
        trackDGPlayAnimation,
        trackSuccesfullySentEmail,
        trackViewEmailSplash,
        trackKeepShopping,
        trackEmailDeliveryRecipient,
        trackDGViewFacePreview,
        trackEditProjectName,
        trackDefaultProjectName,
        trackExitSaveProject,
        trackSaveLink,
        trackSignIn,
        trackWamPhotoUpload,
        trackAbandonOrDeleteProject,
        trackEditProjectSummaryName,
        trackUpdatedProjectNameSuccess,
        trackRefresh,
        trackSaveQuickAddress,
        trackClickPreviewCarouselImageTag,
        trackTextEdits,
        trackSelectedQuickAddress,
        trackWamTamReset,
        trackSpellcheckDialog,
        trackUneditedTextBoxWarningTag,
        trackDeleteQuickAddress,
      }}
    >
      {shouldInjectScripts && isUS && (
        <HelmetProvider context={{}}>
          <Helmet defer={false}>
            <>
              <script type="text/javascript" src={dataLayer}></script>
              {IS_ADOBE_ANALYTICS_ENABLED && analyticsDtm && <script src={analyticsDtm} async></script>}
              {IS_CHATBOT_ENABLED && theme === 'us' && <script src={publicUrl + chatbotUrl} async></script>}
            </>
          </Helmet>
        </HelmetProvider>
      )}
      {children}
    </AnalyticsContext.Provider>
  );
};

// Hooks
export const useAnalyticsContext = () => {
  const context = useContext(AnalyticsContext);
  if (context === undefined) {
    throw new Error('useAnalyticsContext must be used within AnalyticsProvider');
  }

  return context;
};
