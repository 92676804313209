import {
  TEXT_BOX_LEFT_OFFSET_LANDSCAPE,
  TEXT_BOX_LEFT_OFFSET_PORTRAIT,
  TEXT_BOX_RIGHT_OFFSET_LANDSCAPE,
  TEXT_BOX_RIGHT_OFFSET_PORTRAIT,
  TEXT_BOX_TOP_OFFSET_LANDSCAPE,
  TEXT_BOX_TOP_OFFSET_PORTRAIT,
} from '../../constants/user-zone-textbox-offsets';

export const getUserZoneTextboxOffsets = (
  orientation: string,
): { TOP_OFFSET: number; LEFT_OFFSET: number; RIGHT_OFFSET: number } => {
  return {
    TOP_OFFSET: orientation === 'landscape' ? TEXT_BOX_TOP_OFFSET_LANDSCAPE : TEXT_BOX_TOP_OFFSET_PORTRAIT,
    LEFT_OFFSET: orientation === 'landscape' ? TEXT_BOX_LEFT_OFFSET_LANDSCAPE : TEXT_BOX_LEFT_OFFSET_PORTRAIT,
    RIGHT_OFFSET: orientation === 'landscape' ? TEXT_BOX_RIGHT_OFFSET_LANDSCAPE : TEXT_BOX_RIGHT_OFFSET_PORTRAIT,
  };
};
