import { useLocation, useNavigate } from 'react-router-dom';
import { cleanAddresses, useInitializationDataContext } from '../context/data-context';
import { AddressResponseData } from '../global-types';
import { EnvelopeType } from '../global-types/envelope';
import { deleteAddress } from '../services/address';
import { SubmittedAddresses } from '../views/address/address-types';

export type UseSkipAddressValidationParams = {
  submittedAddresses: SubmittedAddresses;
  envelope: EnvelopeType;
};

export const useSkipAddressValidation = (params?: UseSkipAddressValidationParams) => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const {
    initializedDataState: { data: initializedData },
    initializationDataDispatch,
  } = useInitializationDataContext();

  // filling the submittedAddresses with the recipient and sender addresses
  const submittedAddresses = params ? [params.submittedAddresses.recipient, params.submittedAddresses.sender] : [];

  const removeSavedAddresses = (projectId: string, addresses: AddressResponseData[]) => {
    const addressIds = addresses.map((address) => address.address_id);
    for (let i = 0; i < addressIds.length; i++) {
      deleteAddress(projectId, addressIds[`${i}`]);
    }
  };

  const skipAddressValidation = () => {
    const addresses: AddressResponseData[] = submittedAddresses.filter(
      (element) => element !== null,
    ) as unknown as AddressResponseData[];

    if (params?.envelope === EnvelopeType.Blank) {
      cleanAddresses(initializationDataDispatch);
    }

    if (addresses.length > 0) {
      // TODO: updated this condition to greater than 0, review this when implementing 1:M
      // Delete address from backend
      removeSavedAddresses(initializedData?.project_id as string, addresses);
      // Remove address from state
      cleanAddresses(initializationDataDispatch);
    }

    // set envelope to blank by default if no envelope is passed
    const searchParams = new URLSearchParams(search);
    searchParams.set('envelope', params ? params.envelope : EnvelopeType.Blank);

    // navigate to preview view
    navigate({ pathname: '/card/customization/preview', search: searchParams.toString() });
  };

  return skipAddressValidation;
};
