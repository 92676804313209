import { DELETE_ICON_TOP_OFFSET } from '../../../constants/user-zone-textbox-offsets';
import { CardContextState } from '../../../context/card-context';
import { CanvasDataTypes } from '../../../utils';
import { getUserZoneTextboxOffsets } from '../../../utils/utility/get-user-zone-textbox-offsets';

export const createTextElement = ({
  width,
  fabric,
  initialTextMessage,
  isUK,
  defaultTextSettings,
  cardFaceClipPath,
  cardState,
}: {
  width;
  fabric;
  initialTextMessage;
  isUK;
  defaultTextSettings;
  cardFaceClipPath;
  cardState: CardContextState;
}) => {
  const textElement = new fabric.Textbox(initialTextMessage, {
    top: 100,
    left: 100,
    width,
    height: 100,
    hasControls: true,
    borderColor: isUK ? '#333333' : '#604099',
    textAlign: 'center',
    ...defaultTextSettings,
  });
  textElement.set({ data: { type: CanvasDataTypes.UserText } });
  const { TOP_OFFSET, LEFT_OFFSET, RIGHT_OFFSET } = getUserZoneTextboxOffsets(cardState.cardFormat);
  if (cardFaceClipPath) {
    textElement.set({
      // 62 is default textBox height
      top: cardFaceClipPath.top + DELETE_ICON_TOP_OFFSET + TOP_OFFSET,
      left: cardFaceClipPath.left + LEFT_OFFSET,
      width: cardFaceClipPath.width - (LEFT_OFFSET + RIGHT_OFFSET),
    });
    textElement.clipPath = cardFaceClipPath;
  }

  return textElement;
};
