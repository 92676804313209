import React from 'react';
import { Dropdown, SelectTypes } from '@hallmark/web.core.forms.dropdown';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { SummaryRowProps } from '../summary-drawer-types';
import styles from '../summary-drawer.module.scss';

export const SummaryRow = ({
  data,
  options,
  selectedOption,
  onSelect,
  register,
  isSelectDisabled,
}: SummaryRowProps): React.ReactElement => {
  return (
    <div className={styles['summary-row']} data-testid="summary-grid-row">
      <div className={styles.concept}>
        <div className={styles['title-container']}>
          <Typography variant={TypographyVariants.Body}>{data.title}</Typography>
        </div>
        {data.subtitle && (
          <div className={styles['subtitle-container']}>
            <Typography variant={TypographyVariants.Breadcrumb}>{data.subtitle}</Typography>
          </div>
        )}
      </div>
      {onSelect && options && options.length > 0 && (
        <div className={styles['options-container']}>
          <form>
            <Dropdown
              testId="summary-grid-dropdown"
              hideLabel={true}
              label="Select an option"
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onSelect(e.target.value)}
              options={options}
              variant={SelectTypes.Default}
              value={selectedOption || ''}
              isDisabled={isSelectDisabled}
              register={register('quantity')}
            />
          </form>
        </div>
      )}
      <div className={styles.detail}>
        <Typography variant={TypographyVariants.Body}>{data.detail}</Typography>
      </div>
    </div>
  );
};
