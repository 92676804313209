import React, { lazy, ReactElement, Suspense, useCallback, useEffect, useMemo } from 'react';
import { fabric } from 'fabric';
import { SizeDrawer } from '@hallmark/web.page-components.print-on-demand.text-editor.size-drawer';
import { useAnalyticsContext } from '../../../context/analytics-context';
import { setAllDrawersClosed, setIsImageEditDrawerOpen, useAppContext } from '../../../context/app-context';
import { useCropContext } from '../../../context/crop-context';
import { useActiveCanvas, useFeatureFlags } from '../../../hooks';
import { convertPixelToPoint, getGroupedTextObject } from '../../../utils';
import { FontDrawer } from '../../card-controls/font-drawer';
import { PhotoDrawer } from '../../photo-drawer';
import { ImageEditDrawer } from '../image-edit-drawer/image-edit-drawer';
import { OrderDrawer } from '../order-drawer';
import { RotateDrawer } from '../rotate-drawer';
import { ScaleDrawer } from '../scale-drawer';
import { WamInstructionsDrawer } from '../wam-instructions-drawer';
import styles from './drawer-container.module.scss';
import { useDrawerContainerActions } from './hooks/use-drawer-container-actions';
import { useImageUploadHandlers } from './hooks/use-image-upload-handlers';

const DeleteConfirmationComponent = lazy(() =>
  import('../../confirmation-dialog/confirmation-dialog').then((module) => ({
    default: module.DeleteConfirmationDialog,
  })),
);

const ResetConfirmationComponent = lazy(() =>
  import('../../confirmation-dialog/confirmation-dialog').then((module) => ({
    default: module.ResetConfirmationDialog,
  })),
);

const ClearConfirmationComponent = lazy(() =>
  import('../../confirmation-dialog/confirmation-dialog').then((module) => ({
    default: module.ClearConfirmationDialog,
  })),
);

const ImageUploadDrawerComponent = lazy(() =>
  import('../../card-controls/image-upload-drawer').then((module) => ({
    default: module.ImageUploadDrawer,
  })),
);

export const DrawerContainer = (): ReactElement => {
  const canvas = useActiveCanvas();
  const [initialAngle, setInitialAngle] = React.useState(canvas?.current?.getActiveObject()?.angle ?? 0);
  const {
    appState: {
      isImageUploadDrawerOpen,
      isTextDrawerOpen,
      isWamDrawerOpen,
      isRotationDrawerOpen,
      isSizeDrawerOpen,
      isImageEditDrawerOpen,
      isOrderDrawerOpen,
      isScaleDrawerOpen,
      isImageUploadToPhotoZoneOpen,
      isDeleteConfirmationDialogOpen,
      isResetConfirmationDialogOpen,
      isClearConfirmationDialogOpen,
    },
    appDispatch,
  } = useAppContext();
  const { startCropping, isCropping, finishCropping, cancelCropping } = useCropContext();
  const {
    handleRotateOpenDrawer,
    onChangeOrder,
    openDeleteConfirmationDialog,
    closeDeleteConfirmationDialog,
    closeClearConfirmationDialog,
    onSubmitSize,
    onSubmitRotate,
    onSubmitOrder,
    onFinishCropping,
    onCloseImageEditDrawer,
    onSizeDrawerClose,
    closeResetConfirmationDialog,
    handleClearConfirmation,
    handleResetConfirmation,
    handleDeleteConfirmation,
    handleSizeChange,
  } = useDrawerContainerActions(canvas, setInitialAngle);
  const { IS_PHOTO_DRAWER_ENABLED } = useFeatureFlags();
  const { updateEditFormats } = useAnalyticsContext();

  const closeAllDrawers = useCallback(() => setAllDrawersClosed(appDispatch), [appDispatch]);
  const openImageEditDrawer = useCallback(() => setIsImageEditDrawerOpen(appDispatch), [appDispatch]);

  // TODO: Create a custom hook like this one for each drawer.
  const { onImageSelect, onImageUpload } = useImageUploadHandlers();

  useEffect(() => {
    setInitialAngle(canvas?.current?.getActiveObject()?.angle ?? 0);
  }, [canvas?.current?.getActiveObject()?.angle]);

  const OpenDrawer = useMemo(() => {
    if (isTextDrawerOpen) {
      return <FontDrawer />;
    }

    if (isImageEditDrawerOpen || isCropping) {
      const isHandwriting = canvas?.current?.getActiveObject()?.data?.isPodHandwriting;
      return (
        <ImageEditDrawer
          isOpen={isImageEditDrawerOpen || isCropping}
          onClose={onCloseImageEditDrawer}
          onCrop={startCropping}
          onFinishCropping={onFinishCropping}
          onCancelCropping={cancelCropping}
          isCropping={isCropping}
          onDelete={openDeleteConfirmationDialog}
          onImageSelect={openImageEditDrawer}
          isHandwriting={isHandwriting}
        />
      );
    }

    if (isWamDrawerOpen) {
      return (
        <WamInstructionsDrawer
          onImageSelect={openImageEditDrawer}
          isOpen={isWamDrawerOpen}
          onCrop={startCropping}
          onClose={closeAllDrawers}
        />
      );
    }

    if (isSizeDrawerOpen) {
      const textObject = getGroupedTextObject(canvas?.current) as fabric.Textbox;
      const textFontSize: number = textObject?.fontSize ?? 30;
      return (
        <div className={styles['drawer-container']}>
          <SizeDrawer
            handleSubmit={onSubmitSize}
            isOpen={isSizeDrawerOpen}
            onClose={() => onSizeDrawerClose(textObject, textFontSize)}
            sizeValue={convertPixelToPoint(textFontSize).toString()}
            sizeOnChange={(valueInRange: string) => handleSizeChange(textObject, valueInRange)}
          />
        </div>
      );
    }

    if (isScaleDrawerOpen) {
      return (
        <div className={styles['drawer-container']}>
          <ScaleDrawer
            isOpen={isScaleDrawerOpen}
            onApply={openImageEditDrawer}
            onChangeCallback={(value) => updateEditFormats({ size: value.toString() })}
            onCancel={openImageEditDrawer}
          />
        </div>
      );
    }

    if (isRotationDrawerOpen) {
      return (
        <div className={styles['drawer-container']}>
          <RotateDrawer
            isOpen={isRotationDrawerOpen}
            onDrawerApply={onSubmitRotate}
            onDrawerCancel={handleRotateOpenDrawer}
            initialAngle={initialAngle}
          />
        </div>
      );
    }

    if (isOrderDrawerOpen) {
      return (
        <div className={styles['drawer-container']}>
          <OrderDrawer
            isOpen={isOrderDrawerOpen}
            onClose={closeAllDrawers}
            onSubmit={onSubmitOrder}
            handleOrderChange={onChangeOrder}
          />
        </div>
      );
    }

    return <React.Fragment />;
  }, [
    isTextDrawerOpen,
    isWamDrawerOpen,
    isRotationDrawerOpen,
    isSizeDrawerOpen,
    isImageEditDrawerOpen,
    isOrderDrawerOpen,
    isScaleDrawerOpen,
    isCropping,
    finishCropping,
    startCropping,
  ]);

  return (
    <div data-testid="drawer-container">
      {OpenDrawer}
      <Suspense fallback={null}>
        {IS_PHOTO_DRAWER_ENABLED ? (
          <PhotoDrawer />
        ) : (
          <ImageUploadDrawerComponent
            onImageSelect={onImageSelect}
            isOpen={isImageUploadDrawerOpen || isImageUploadToPhotoZoneOpen}
            onUpload={onImageUpload}
            onClose={closeAllDrawers}
          />
        )}
      </Suspense>
      <Suspense fallback={null}>
        <DeleteConfirmationComponent
          isOpen={isDeleteConfirmationDialogOpen}
          onClose={closeDeleteConfirmationDialog}
          onConfirm={handleDeleteConfirmation}
        />
      </Suspense>
      <Suspense fallback={null}>
        <ResetConfirmationComponent
          isOpen={isResetConfirmationDialogOpen}
          onClose={closeResetConfirmationDialog}
          onConfirm={handleResetConfirmation}
        />
      </Suspense>
      <Suspense fallback={null}>
        <ClearConfirmationComponent
          isOpen={isClearConfirmationDialogOpen}
          onClose={closeClearConfirmationDialog}
          onConfirm={handleClearConfirmation}
        />
      </Suspense>
    </div>
  );
};
