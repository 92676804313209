import { BrandColors } from '@hallmark/web.styles.colors';
import { CardContextState } from '../../../context/card-context';
import colorsList from '../../../styles/util.scss';
import { deepCopy, getFontName, CanvasDataTypes } from '../../../utils';
import { helperSettingsConfig } from '../../../utils/configs/helper-settings.configs';
import { getTextSettingsParams } from '../card-editor-types';

const { scalingFactor } = helperSettingsConfig;
const spacingUnit = 20; // this is the old textPadding

export const textDefaultSettings = {
  left: spacingUnit * scalingFactor * 2,
  padding: (0.75 * spacingUnit) / scalingFactor,
  editable: true,
  fill: BrandColors.Black,
  opacity: 1,
};

export function isSpecialCard(cardState: CardContextState) {
  const { isChocolate, isWooden, isPostcard } = cardState;
  return isChocolate || isWooden || isPostcard;
}

// Gets the placeholder text from the DefaultTextArea object
export function getDefaultPlaceholderText(settings, defaultText) {
  const placeHolderText = settings.defaultOptions ? settings.defaultOptions.Text : defaultText;
  return placeHolderText;
}

export function getTextSettings({
  cardState,
  defaultFontSize,
  color,
  isUK,
  name,
  defaultOptions,
}: getTextSettingsParams) {
  const defaultColor = isUK ? colorsList[BrandColors.CharcoalGrey] : color || colorsList[BrandColors.Black];
  // Used to only get the values that we needed from the defaultOptions object and avoid unexpected behavior
  const propsToUse = [
    'IsFixed',
    'IsHybrid',
    'IsMultiline',
    'Rotation',
    'TextAlign',
    'CanResizeTextArea',
    'CanEditFontSize',
    'CanEditFontColor',
    'FontAutoSize',
    'Text',
  ];
  const dynamicTextBoxOptions = Object.fromEntries(
    Object.entries(defaultOptions || {}).filter(([key, value]) => propsToUse.includes(key) && value !== undefined),
  );

  return Object.assign(deepCopy(textDefaultSettings), {
    fontSize: isSpecialCard(cardState) ? 36 : defaultFontSize,
    fill: defaultColor,
    fontFamily: getFontName(defaultOptions?.FontFamilyId || 107),
    name,
    data: {
      type: CanvasDataTypes.Placeholder,
    },
    ...dynamicTextBoxOptions,
  });
}

/**
 * Determines whether the card should show bleed or not
 *
 * @param {CardContextState} cardState card state
 */
export function hasNoBleed(cardState: CardContextState) {
  return cardState.isChocolate || cardState.cardType === 'photo' || cardState.isPostcard;
}
