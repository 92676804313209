import React, { ReactElement } from 'react';
import { IconButton, IconButtonBrandColors, IconNames } from '@hallmark/web.core.buttons.icon-button';
import { ActionButtonsProps } from '../contact-types';
import styles from '../contact.module.scss';

export default function ActionButtons({ contact, onEdit, onDelete }: ActionButtonsProps): ReactElement {
  const handleEdit = () => {
    onEdit(contact);
  };

  const handleDelete = () => {
    onDelete(contact.contact_id);
  };

  return (
    <div className={styles['address-book-address-actions']}>
      <IconButton
        icon={IconNames.TrashBold}
        click={handleDelete}
        size={16}
        iconColor={IconButtonBrandColors.DarkGray}
        addClass={styles.icon}
        testId={`delete-contact-${contact.contact_id}`}
        altText="delete contact"
      />
      <IconButton
        icon={IconNames.Edit}
        click={handleEdit}
        size={16}
        iconColor={IconButtonBrandColors.DarkGray}
        addClass={styles.icon}
        testId={`edit-contact-${contact.contact_id}`}
        altText="edit contact"
      />
    </div>
  );
}
