import { Error } from '../../global-types/services';

/**
 *
 * @param errors Array of errors given in the address response
 * @returns Address error
 */
export const getAddressError = (errors: Error[]) =>
  errors.find((error) => {
    return error.field === 'address';
  });
