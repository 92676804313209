import { AddressForm } from '../../../global-types';

export const formatAddress = (data: AddressForm) => {
  return {
    first_name: data.first_name,
    last_name: data.last_name,
    company_name: data.company_name,
    address_line_1: data.address_line_1,
    address_line_2: data.address_line_2,
    city: data.city,
    state_code: data.state_code,
    zip: data.zip,
    country_code: data.country_code,
  };
};
