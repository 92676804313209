// TODO: Remove this function and replace it's implementation with the savePersonalization call in services
export const savePersonalization = async (
  options: Parameters<typeof saveProject>[0],
  isEditorView: boolean,
  saveProject,
) => {
  if (isEditorView) {
    await saveProject(options);
  }
};
