import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastVariants } from '@hallmark/web.core.feedback.toast';
import { useAnalyticsContext } from '../../../context/analytics-context';
import { setIsToasterOpen, useAppContext } from '../../../context/app-context';
import { useInitializationDataContext } from '../../../context/data-context';
import { appendContacts, setContactPaging } from '../../../context/data-context/data-context-actions';
import { AddressForm, QuickAddressSubmitData } from '../../../global-types';
import { getQuickAddresses, saveQuickAddress } from '../../../services';
import { capitalize, helperSettingsConfig, isLoggedIn } from '../../../utils';
import { throwErrorLoadAddressBook } from './util/throw-error-load-address-book';

export const DEFAULT_PAGE = '1';
export const DEFAULT_CONTACT_LIMIT = '20';
export const SAVED_CONTACT_LIMIT = 300;
/**
 * Custom hook that handles all Address Book related logic:
 * - Loads the address book if the user is logged by getting the saved contacts from API and then store them in the data-context
 * - Creates the function to save a new contact on the BE and also handles toast messages giving feedback to the user
 * @returns saveContact: Function to save a new contact on the BE
 */
export const useAddressBook = () => {
  const { appDispatch } = useAppContext();

  const {
    initializationDataDispatch,
    initializedDataState: { savedContacts, contactPaging: paging },
  } = useInitializationDataContext();
  const { t } = useTranslation();
  const { trackSaveQuickAddress } = useAnalyticsContext();
  const [isLoadingAddresses, setIsLoadingAddresses] = useState<boolean>(false);

  /**
   * Displays an error toaster to the user with the provided error text and title
   *
   * @param error Error message to be the content of the toast
   * @param title Optional. Text to be the title of the toast
   */
  const showErrorToast = useCallback(
    (error: string, title = t('addressView.failedToast')) =>
      setIsToasterOpen(appDispatch, {
        variant: ToastVariants.Error,
        title: title,
        children: error,
      }),
    [appDispatch, t],
  );

  /**
   * Get all saved contacts from the BE for the logged-in user, and update the data-context with the API response
   * by saving the gathered contacts in savedContacts state.
   */
  const loadAddressBook = (params?: { page?: string; limit?: string }) => {
    setIsLoadingAddresses(true);

    getQuickAddresses(params)
      .then((res) => {
        const contacts = res.data?.contacts ?? [];
        appendContacts(initializationDataDispatch, contacts);
        setContactPaging(initializationDataDispatch, res.data?.paging ?? null);
        setIsLoadingAddresses(false);
      })
      .catch(() => {
        throwErrorLoadAddressBook(showErrorToast, t('addressView.somethingWrong'), setIsLoadingAddresses);
      });
  };

  /**
   * Saves the address passed as parameter as a new contact for the logged in user by hitting the customization API
   * Handles logic to give the correct feedback to the user
   *
   * @param address Address to be saved as a contact (quick address)
   * @param shouldSkipNextContact If the entered address is the last contact to be saved since the limit has been reached. If true, a feedback toast will be shown to the user
   * @param index To compare if it is 0, it would be the recipient; and if it is 1, it would be the sender.
   */
  const saveContact = useCallback(
    (address: Omit<AddressForm, 'isQuickAddress'>, shouldSkipNextContact: boolean, index: number) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { skip_usps_validation, address_type_code, ...quickAddress } = address;
      const totalContacts = paging?.total_items ?? 0;

      if (totalContacts >= helperSettingsConfig.limitSaveQuickAddresses) {
        const contactsLimitError = t('addressView.contactLimitError');
        const contactsErrorTitle = t('addressView.contactsErrorTitle');
        setTimeout(() => {
          showErrorToast(contactsLimitError, contactsErrorTitle);
        }, 2000);
        return;
      }
      const quickAddressError = t('addressView.quickAddressError');
      const quickAddressData: QuickAddressSubmitData = {
        ...quickAddress,
        contact_type_code: 'A',
        skip_address_verification: true,
      };

      return saveQuickAddress(quickAddressData)
        .then(() => {
          if (shouldSkipNextContact) {
            setTimeout(() => {
              const contactsLimitError = t('addressView.contactSoftLimitError', {
                contact: `${capitalize(address.first_name)} ${capitalize(address.last_name)}`,
              });
              showErrorToast(contactsLimitError, '');
            }, 2000);
          } else {
            setIsToasterOpen(appDispatch, {
              title: t('addressView.quickAddressSuccessTitle'),
              children: t('addressView.quickAddressAdded'),
            });

            const isSender = index === 1;

            trackSaveQuickAddress(isSender);
          }
        })
        .catch(() => {
          showErrorToast(quickAddressError);
        });
    },
    [savedContacts, showErrorToast, t, paging],
  );

  /**
   * Get the next page of contacts from the BE and update the data-context with the new contacts.
   * The function will be recreated every time the dependencies change because it relies on paging and isLoadingAddresses.
   */
  const getNextPage = useCallback(() => {
    if ((paging && !paging.next_page) || isLoadingAddresses) {
      return;
    }

    loadAddressBook({
      page: paging?.next_page?.toString() ?? undefined,
      limit: DEFAULT_CONTACT_LIMIT,
    });
  }, [paging, isLoadingAddresses]);

  useEffect(() => {
    if (isLoggedIn()) {
      loadAddressBook({
        page: DEFAULT_PAGE,
        limit: DEFAULT_CONTACT_LIMIT,
      });
    }
  }, []);

  return {
    saveContact,
    isLoadingAddresses,
    getNextPage,
    paging,
    loadAddressBook,
  };
};
