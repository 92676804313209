import { useCallback, useEffect, useState } from 'react';
import { useInitializationDataContext } from '../../../context/data-context/data-context';
import useAddressSelection from '../../../hooks/use-address-selection';
import { useSystemErrorHandling } from '../../../hooks/useSystemErrorHandling';
import { getAddresses as fetchAddresses } from '../../../services/address';
import { mapAddressesToContacts } from '../../../utils/address/map-addresses-to-contacts';

export const useGetAddresses = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { contacts, handleSetContacts } = useAddressSelection();

  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();
  const { onSystemError } = useSystemErrorHandling();

  const projectId = initializedData?.project_id;
  const accountId = initializedData?.account_id;

  const getAddresses: () => Promise<void> = useCallback(async () => {
    if (projectId) {
      setIsLoading(true);
      fetchAddresses(projectId)
        .then((response) => {
          if (response.data) {
            handleSetContacts(mapAddressesToContacts({ addresses: response.data, accountId }));
          } else {
            handleSetContacts([]);
          }
        })
        .catch((error) => {
          onSystemError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [projectId]);

  const getAddressesAndOpen = async () => {
    await getAddresses();
    setIsOpen(true);
  };

  useEffect(() => {
    getAddresses();
  }, []);

  return { getAddresses, isOpen, setIsOpen, contacts, isLoading, getAddressesAndOpen };
};
