import { useTranslation } from 'react-i18next';
import { NavigationProps } from '../../../components/address-form/address-form-types';

type UseOneToOneNavigationParams = {
  submitLabel: string;
  isFormValid: boolean;
  isTouched: boolean;
  isAddressLoading: boolean;
  isAddressFormValid: boolean;
  handlePrimaryActionButton: () => void;
  handleSecondaryActionButton: () => void;
};

// This hook handles the navigation logic for 1:1 cards
export const useOneToOneNavigation = ({
  submitLabel,
  isFormValid,
  isTouched,
  isAddressLoading,
  isAddressFormValid,
  handlePrimaryActionButton,
  handleSecondaryActionButton,
}: UseOneToOneNavigationParams) => {
  const { t } = useTranslation();

  const oneToOneProps: NavigationProps = {
    secondaryActionButton: {
      onClick: handleSecondaryActionButton,
      text: t('addressForm.back'),
      testId: 'address-form-back-submit',
    },
    primaryActionButton: {
      onClick: handlePrimaryActionButton,
      text: submitLabel,
      testId: 'address-form-continue-submit',
      isValid: isFormValid || isAddressFormValid,
      isLoading: isAddressLoading,
      isDisabled: !isFormValid || isAddressLoading,
      isTouched: isTouched,
    },
  };

  return {
    oneToOneProps,
  };
};
