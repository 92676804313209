import { useCallback, useEffect, useState } from 'react';
import type { IEvent } from 'fabric/fabric-impl';
import { useActiveCanvas, useIsPodProductCode } from '../../../hooks';
import { isPhotoTextZone } from '../../../utils';

/**
 * Custom hook used in toolbar component to handle logic to show and hide buttons.
 * This hook checks the project type code and the active canvas object to determine if the buttons should be displayed
 * @returns true the toolbar buttons should be displayed
 */
export const useShowToolbar = () => {
  const canvas = useActiveCanvas();
  const isPhotoCard = useIsPodProductCode();
  const [showToolbar, setShowToolbar] = useState(false);

  const handleSelection = useCallback((options: IEvent<Event>) => {
    const selectedObject = options.selected?.[0];
    if (selectedObject && isPhotoTextZone(selectedObject)) {
      return setShowToolbar(true);
    }
    setShowToolbar(false);
  }, []);

  const handleClearSelection = useCallback(() => setShowToolbar(false), []);

  useEffect(() => {
    if (isPhotoCard) {
      return;
    }
    canvas?.current?.on('selection:created', handleSelection);
    canvas?.current?.on('selection:updated', handleSelection);
    canvas?.current?.on('selection:cleared', handleClearSelection);
    return () => {
      canvas?.current?.off('selection:created', handleSelection);
      canvas?.current?.off('selection:updated', handleSelection);
      canvas?.current?.off('selection:cleared', handleClearSelection);
    };
  }, [canvas, isPhotoCard]);

  return isPhotoCard ? true : showToolbar;
};
