import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastVariants } from '@hallmark/web.core.feedback.toast';
import { AddToCartDialog } from '@hallmark/web.page-components.add-to-cart-dialog';
import { useAnalyticsContext } from '../../context/analytics-context';
import { useAppContext } from '../../context/app-context';
import { setIsToasterOpen } from '../../context/app-context/app-context-actions';
import { useCardContext } from '../../context/card-context';
import { useInitializationDataContext } from '../../context/data-context';
import { getCurrentRegion } from '../../regional-config/get-regional-config';
import { removeBeforeUnloadEvent, getMonolithUrl } from '../../utils';
import { setAssetUrl } from '../../utils/utility/set-thumbnail-url';
import { AddToCartProps } from './add-to-cart-types';

export const AddToCart = ({
  recentBasket,
  basket,
  basketError,
  closeModalOverride,
  keepShoppingOverride,
}: AddToCartProps) => {
  const { appDispatch } = useAppContext();
  const { trackAddToCart, trackAddToCartError } = useAnalyticsContext();
  const [isOpen, setIsOpen] = useState(false);
  const [addToCartThumbnail, setAddToCartThumbnail] = useState<string>('');
  const [title, setTitle] = useState('Item added');
  const recentLineItem = recentBasket?.line_item_added;
  const { title: skuName, price } = recentLineItem || {};
  const region = getCurrentRegion();

  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();

  const { assets } = initializedData || {};

  const {
    cardState: { thumbnailUrl, coverPreviewUrl },
  } = useCardContext();

  useMemo(() => {
    if (thumbnailUrl) {
      setAddToCartThumbnail(thumbnailUrl);
    } else {
      const url = setAssetUrl(assets, coverPreviewUrl);
      setAddToCartThumbnail(url);
    }
  }, [thumbnailUrl, assets, coverPreviewUrl]);

  const onCloseDialogClick = () => {
    setIsOpen(false);
  };

  const { t } = useTranslation();

  const onKeepShoppingClick = () => {
    if (keepShoppingOverride) {
      keepShoppingOverride();
    } else {
      onCloseDialogClick();
    }
  };

  const closeAndRedirect = () => {
    removeBeforeUnloadEvent();
    window.location.href = `${getMonolithUrl()}/shopping-cart/`;
  };
  const errorMessage = t('addToCart.cantAddToCart');

  const lineItemAddedMessage = (quantity) => {
    return quantity > 1 ? t('addToCart.itemsAdded') : t('addToCart.itemAdded');
  };

  useEffect(() => {
    if (recentBasket) {
      setIsOpen(true);
      setTitle(() => {
        return `${recentLineItem ? `${recentLineItem.quantity} ` : ''}${lineItemAddedMessage(
          recentLineItem?.quantity,
        )}`;
      });
      !basketError && trackAddToCart();
    } else if (basketError) {
      trackAddToCartError(t('previewView.toastErrorDescription'));
      setIsToasterOpen(appDispatch, {
        title: t('addToCart.wereSorry'),
        children: errorMessage,
        variant: ToastVariants.Error,
      });
    }
  }, [recentBasket, basketError]);

  useEffect(() => {
    if (!isOpen && closeModalOverride) {
      closeModalOverride();
    }
  }, [isOpen]);

  return (
    <>
      {recentLineItem && (
        <AddToCartDialog
          isOpen={isOpen}
          close={onCloseDialogClick}
          closeAndRedirect={closeAndRedirect}
          keepShoppingClick={onKeepShoppingClick}
          cartSubtotal={recentBasket.basket_subtotal.toFixed(2).toString()}
          numItems={parseInt(basket, 10) || 0}
          skuThumbnail={addToCartThumbnail}
          skuName={skuName || ''}
          skuPrice={{ list: price?.toFixed(2).toString() || '' }}
          title={title}
          locale={region}
        />
      )}
    </>
  );
};
