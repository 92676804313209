import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastVariants } from '@hallmark/web.core.feedback.toast';
import { useAppContext } from '../../../context/app-context/app-context';
import { setIsToasterOpen } from '../../../context/app-context/app-context-actions';
import { useInitializationDataContext } from '../../../context/data-context/data-context';
import { deleteAddress as deleteAddressService } from '../../../services/address';
import { useGetAddresses } from './use-get-addresses';

export const useDeleteAddress = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const { getAddresses } = useGetAddresses();

  const {
    initializedDataState: { data },
  } = useInitializationDataContext();
  const { appDispatch } = useAppContext();

  const projectId = data?.project_id;
  const accountId = data?.account_id;

  const deleteAddress = (contactId: string) => {
    if (!projectId || !accountId) {
      throw new Error('Project ID and Account ID is required');
    }

    setIsLoading(true);
    deleteAddressService(projectId, contactId)
      .then(() => {
        getAddresses();
      })
      .catch((error) => {
        setIsToasterOpen(appDispatch, {
          variant: ToastVariants.Error,
          title: t('recipientAddresses.delete.errorTitle'),
          children: t('recipientAddresses.delete.errorMessage') + error.message,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { deleteAddress, isLoading };
};
