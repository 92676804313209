import React, { useEffect } from 'react';
import { ToastVariants } from '@hallmark/web.core.feedback.toast';
import { EnvelopePreview } from '../../components/envelope-preview/envelope-preview';
import { Layout } from '../../components/layout';
import { hideLoadingScreen, setIsToasterOpen, showLoadingScreen, useAppContext } from '../../context/app-context';
import { SelectEnvelopeCard } from './components/select-envelope-card';
import { useEnvelopeSelection } from './hooks/use-envelope-selection';
import styles from './select-envelope.module.scss';

export const SelectEnvelopeView = () => {
  const {
    envelopeForm: { register, setValue },
    selectedEnvelope,
    handleBack,
    handleContinue,
  } = useEnvelopeSelection();

  const { appDispatch } = useAppContext();

  useEffect(() => {
    if (selectedEnvelope) {
      setValue('envelopeOption', selectedEnvelope);
    }
  }, []);

  const onContinue = () => {
    showLoadingScreen(appDispatch, '');
    handleContinue()
      ?.then(() => {
        hideLoadingScreen(appDispatch);
      })
      .catch(() => {
        hideLoadingScreen(appDispatch);
        setIsToasterOpen(appDispatch, {
          variant: ToastVariants.Error,
          title: 'Error',
          children: 'An error occurred saving your envelope selection',
        });
      });
  };

  return (
    <Layout hideToolbar>
      <main className={styles['envelope-container']} data-testid={'envelope-container'}>
        <EnvelopePreview selectedEnvelope={selectedEnvelope} />
        <SelectEnvelopeCard
          register={register}
          handleBack={handleBack}
          handleContinue={onContinue}
          selectedEnvelope={selectedEnvelope}
        />
      </main>
    </Layout>
  );
};
