import { datadogRum } from '@datadog/browser-rum';
import packageInfo from '../../../../package.json';
import { DatadogEnvs } from '../analytics-context-types';
import { getMissingVariableList } from './get-missing-variable-list';
import { getTrackingConsent } from './get-tracking-consent';
import { setGlobalContextProperties } from './set-global-context-properties';

export const initializeDataDog = () => {
  const env = process.env.REACT_APP_DATADOG_ENV;
  const clientToken = process.env.REACT_APP_DATADOG_CLIENT_TOKEN;
  const applicationId = process.env.REACT_APP_DATADOG_APPLICATION_ID;
  const site = process.env.REACT_APP_DATADOG_SITE;
  const dataLayer = window.dataLayer as any;

  try {
    if (!env || !clientToken || !applicationId || !site) {
      const missingVariablesList = getMissingVariableList({ env, clientToken, applicationId, site });
      throw Error(`Missing data dog environmental variables: ${missingVariablesList.slice(0, -2)}`);
    }

    const tracingUrls = [
      'https://www.(dev.services|stage.services|services).hallmark.com/.*',
      'https://www.(dev.|stage.)?hallmark.com/card/customization/.*',
    ];

    const trackingConsent = env === DatadogEnvs.DEV || env === DatadogEnvs.STAGE ? 'granted' : getTrackingConsent();

    datadogRum.init({
      env,
      clientToken,
      applicationId,
      site,
      version: packageInfo.version,
      service: 'customization',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 5,
      trackUserInteractions: true,
      trackingConsent: trackingConsent,
      trackResources: true,
      trackSessionAcrossSubdomains: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'allow',
      allowedTracingUrls: tracingUrls,
    });

    setGlobalContextProperties(dataLayer);
    datadogRum.startSessionReplayRecording();
    // eslint-disable-next-line no-console
    console.log('Datadog has loaded successfully!');
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error with loading datadog');
  }
};
