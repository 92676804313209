import { EnvelopeType } from '../../../global-types/envelope';
import { useFeatureFlags } from '../../../hooks';

export type UseShouldHideFormResponse = {
  shouldHideForm: boolean;
};

export const useShouldHideForm = (
  envelope: EnvelopeType,
  selectedEnvelope: EnvelopeType,
): UseShouldHideFormResponse => {
  const { IS_ENVELOPE_PRINTING_OPTIONS_ENABLED } = useFeatureFlags();

  if (!IS_ENVELOPE_PRINTING_OPTIONS_ENABLED) {
    return {
      shouldHideForm: envelope === EnvelopeType.Blank,
    };
  }

  return {
    shouldHideForm: selectedEnvelope === EnvelopeType.Blank,
  };
};
