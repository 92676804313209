export const trimObjectStrings = (obj: unknown): unknown => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  return Object.keys(obj as Record<string, unknown>).reduce(
    (acc, key) => {
      const value = (obj as Record<string, unknown>)[`${key}`];
      acc[`${key}`] = typeof value === 'string' ? value.trim() : trimObjectStrings(value);
      return acc;
    },
    Array.isArray(obj) ? [] : ({} as Record<string, unknown>),
  );
};
