import React from 'react';
import { Button, ButtonModes, ButtonTypes } from '@hallmark/web.core.buttons.button';
import { IconNames } from '@hallmark/web.core.buttons.icon-button';
import { NavigationButtonsProps } from './navigation-buttons-types';
import styles from './navigation-buttons.module.scss';

export const NavigationButtons = ({ secondaryActionButton, primaryActionButton }: NavigationButtonsProps) => {
  return (
    <div className={styles['navigation-buttons']}>
      <Button
        mode={ButtonModes.TextLink}
        startIcon={{ name: IconNames.ArrowsTailLeftBold }}
        click={secondaryActionButton.onClick}
        testId={secondaryActionButton.testId || `secondary-navigation-button-${secondaryActionButton.text}`}
      >
        {secondaryActionButton.text}
      </Button>

      <Button
        tabIndex={-1}
        type={ButtonTypes.Button}
        mode={ButtonModes.Primary}
        click={primaryActionButton.onClick}
        ariaLabel={primaryActionButton.ariaLabel || 'primary-action-button'}
        disabled={primaryActionButton.isDisabled}
        testId={primaryActionButton.testId || `primary-navigation-button-${primaryActionButton.text}`}
      >
        {primaryActionButton.text}
      </Button>
    </div>
  );
};
