import { FormFieldAddress } from '../../../global-types/addresses';
import { Contact } from '../../address-book';

export const parseContactFormData = (contact: Contact): FormFieldAddress => {
  return {
    first_name: contact.first_name,
    last_name: contact.last_name,
    address_line_1: contact.address_line_1,
    address_line_2: contact.address_line_2,
    company_name: contact.company_name,
    city: contact.city,
    state_code: contact.state_code || '',
    zip: contact.zip,
    isQuickAddress: false,
  };
};
