import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { RadioButton } from '@hallmark/web.core.forms.radio-button';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { EnvelopeType } from '../../../global-types/envelope';
import { EnvelopeRadioGroupProps } from '../address-form-types';
import styles from '../address-form.module.scss';

/**
 * This component handles the envelope part of the address form.
 * Contains a selection of the envelope based on radio buttons
 */
export const EnvelopeRadioGroup = ({ register, watch }: EnvelopeRadioGroupProps) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const [searchParams] = useSearchParams(search);
  const navigate = useNavigate();

  const selectedEnvelope = watch('envelope');

  useEffect(() => {
    if (!selectedEnvelope) {
      searchParams.set('envelope', EnvelopeType.Blank);
      navigate({ pathname: '/card/customization/address', search: searchParams.toString() });
      return;
    }

    searchParams.set('envelope', selectedEnvelope);
    navigate({ pathname: '/card/customization/address', search: searchParams.toString() });
  }, [selectedEnvelope]);

  return (
    <div className={styles.addressing}>
      <Typography variant={TypographyVariants.Body} data-testid={'description'}>
        {t('addressing.description')}
      </Typography>
      <div role="radiogroup" aria-labelledby="radio_button_group">
        <RadioButton
          domId={'blank-envelope-card'}
          testId={'blank-envelope-card'}
          value={EnvelopeType.Blank}
          addClass={styles['radio-card']}
          register={register('envelope', {
            required: true,
          })}
        >
          {t('addressing.blankEnvelope')}
        </RadioButton>
        <RadioButton
          domId={'return-envelope-card'}
          testId={'return-envelope-card'}
          value={EnvelopeType.Return}
          addClass={styles['radio-card']}
          register={register('envelope', {
            required: true,
          })}
        >
          {t('addressing.returnEnvelope')}
        </RadioButton>
      </div>
    </div>
  );
};
