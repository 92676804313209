import { getGroupedTextObject } from '../../../../utils/canvas-utils/get-grouped-text-object';

/**
 * Restores the original styles of a text object and triggers the 'changed' event.
 * @param {object} initialTextSelected - The initial text object.
 * @param {object} canvas - The canvas object.
 * @param {array} originalObjectsOrder - The original order of the objects.
 * @param {function} callback - The callback function.
 */
export const onRevertStyles = (initialTextSelected, canvas, originalObjectsOrder, callback) => {
  const textObject = getGroupedTextObject(canvas?.current);
  const { fontFamily, fontSize, fill, left, top, text } = initialTextSelected;
  const textAlign = initialTextSelected?.textAlign || 'left';

  // if the user cancels the changes, revert the order of all objects
  // this is necessary since the order is rearranged on change and the images have child icons above
  originalObjectsOrder.forEach((object, index) => {
    object.moveTo(index);
  });

  if (textObject) {
    textObject.setOptions({
      fontFamily,
      fontSize,
      fill,
      left,
      top,
      text,
      textAlign,
    });

    canvas?.current?.renderAll();

    textObject.fire('changed');

    callback();
  }
};
