import { datadogRum } from '@datadog/browser-rum';

export const setGlobalContextProperties = (dataLayer: any) => {
  const properties = {
    dwsid: dataLayer?.user?.profile?.dwsid?.substr(0, 10),
    'profile_info.accountId': document.cookie
      ?.split('; ')
      .find((c) => c.startsWith('accountId'))
      ?.split('=')[1],
    'profile_info.accountType': document.cookie
      ?.split('; ')
      .find((c) => c.startsWith('accountType'))
      ?.split('=')[1],
    'profile_info.memberType': dataLayer?.user?.profile?.memberType,
    'profile_info.CRNumber': dataLayer?.user?.profile?.rewardsID,
    'profile_info.KOCMembershipID': dataLayer?.user?.profile?.kocMembershipID,
    ip_address: dataLayer?.page?.pageInfo?.IP,
    page_type: dataLayer?.page?.pageInfo?.pageType,
    'order_info.order_id': dataLayer?.transaction?.transactionID,
    'order_info.totals.order_total': dataLayer?.transaction?.total?.cartTotal,
    'order_info.discounts.discount_total': dataLayer?.transaction?.total?.discountTotal,
    'order_info.discounts.discount_total_order_level': dataLayer?.transaction?.total?.orderLevelDiscountTotal,
    'order_info.discounts.discount_total_item_level': dataLayer?.transaction?.total?.itemLevelDiscountTotal,
    'order_info.totals.shipping_total': dataLayer?.transaction?.total?.shippingInfo?.shippingTotal,
    'order_info.totals.tax_amount': dataLayer?.transaction?.total?.taxAmount,
    'order_info.total_quantity': dataLayer?.transaction?.total?.totalQty,
    'order_info.number_of_shipments': dataLayer?.transaction?.total?.numberOfShipments,
    'order_info.shipment_info': dataLayer?.transaction?.total?.shipmentInfo?.reduce(
      (o, s, i) => ({ ...o, [`shipment_${i + 1}`]: s }),
      {},
    ),
    'order_info.payment_methods': dataLayer?.transaction?.total?.paymentMethods?.reduce(
      (o, p, i) => ({ ...o, [`payment_method_${i + 1}`]: p }),
      {},
    ),
  };

  Object.entries(properties).forEach(([key, value]) => {
    datadogRum.setGlobalContextProperty(key, value);
  });
};
