import { UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { FormFieldAddress } from '../../global-types/addresses';

export const handleZipChange = (
  isHandleZipChange: boolean | undefined,
  trigger: UseFormTrigger<FormFieldAddress>,
  zip: string,
  setValue: UseFormSetValue<FormFieldAddress>,
) => {
  if (isHandleZipChange) {
    let newZip = zip.replace(/ /g, '');
    if (newZip.length > 4) {
      const firstHalf = newZip.substring(0, newZip.length - 3);
      const lastHalf = newZip.slice(newZip.length - 3);
      newZip = `${firstHalf} ${lastHalf}`;
      setValue('zip', newZip.toUpperCase());
    } else {
      setValue('zip', newZip.toUpperCase());
    }
    setTimeout(() => {
      trigger('zip');
    }, 250);
  }
};
