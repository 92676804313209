import React from 'react';
import { ContactProps } from './contact-types';
import ActionButtons from './fragments/action-buttons';
import SelectionCardContent from './fragments/selection-card-content';

export const Contact = ({ contact, showStateCode, onDelete, onEdit }: ContactProps) => {
  return (
    <>
      <SelectionCardContent contact={contact} showStateCode={showStateCode} />
      <ActionButtons contact={contact} onDelete={onDelete} onEdit={onEdit} />
    </>
  );
};
