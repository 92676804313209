import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { useAddressSelectionContext } from '../../context/address-selection-indicator/address-selection';
import { EnvelopeType } from '../../global-types/envelope';
import { useEnvelopeSelection } from '../../views/select-envelope/hooks/use-envelope-selection';

export const AddressSelectionIndicator = () => {
  const { t } = useTranslation();
  const {
    addressSelectionState: { count, maxCount },
  } = useAddressSelectionContext();
  const { selectedEnvelope } = useEnvelopeSelection();

  return maxCount === 0 || selectedEnvelope !== EnvelopeType.ReturnRecipient ? (
    <span data-testid={'address-selection-indicator'} hidden />
  ) : (
    <Typography variant={TypographyVariants.Body} data-testid={'address-selection-indicator'}>
      {t('addressing.address')} {count}/{maxCount}
    </Typography>
  );
};
