import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { datadogRum } from '@datadog/browser-rum';
import { fabric } from 'fabric';
import { Button, ButtonModes } from '@hallmark/web.core.buttons.button';
import { Icon, IconNames } from '@hallmark/web.core.display.icon';
import { Drawer, DrawerPositions } from '@hallmark/web.core.feedback.drawer';
import { ToastVariants } from '@hallmark/web.core.feedback.toast';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { BrandColors } from '@hallmark/web.styles.colors';
import { useAnalyticsContext } from '../../../context/analytics-context';
import {
  setIsOrderDrawerOpen,
  setIsScaleDrawerOpen,
  setIsRotationDrawerOpen,
  setIsResetConfirmationDialogOpen,
  useAppContext,
  showLoadingScreen,
  setIsToasterOpen,
  hideLoadingScreen,
  addPhotoTrayImages,
} from '../../../context/app-context';
import { useCardContext } from '../../../context/card-context';
import { useInitializationDataContext } from '../../../context/data-context';
import { CardType } from '../../../global-types/card';
import { useActiveCanvas, useIsPodProductCode } from '../../../hooks';
import { getObjectByName, hideMiddleControls, CanvasDataTypes, getCookie } from '../../../utils';
import { fillPhotoZone } from '../../../utils/canvas-utils';
import { getCardFaceClipPath } from '../../../utils/canvas-utils/get-card-face-clip-path';
import uploadImage, { MAX_FILE_SIZE } from '../../../utils/utility/image-upload';
import { InvalidImageFormatError } from '../../../utils/utility/validate-uploaded-image-format';
import { ConfirmationDialog } from '../../confirmation-dialog/confirmation-dialog';
import { DrawerButtonProps, ImageEditDrawerProps } from './image-edit-drawer-types';
import styles from './image-edit-drawer.module.scss';

const DrawerButton = ({ onClick, icon, label }: DrawerButtonProps): React.ReactElement => {
  return (
    <Button testId={`drawer-button-${label}`} addClass={styles.button} click={onClick} mode={ButtonModes.Icon}>
      <Icon name={icon} size={24} addClass={styles.icon} color={BrandColors.DarkGray} />
      <Typography variant={TypographyVariants.Helper} color={BrandColors.DarkGray}>
        {label}
      </Typography>
    </Button>
  );
};

export const ImageEditDrawer = ({
  isOpen,
  onClose,
  onDelete,
  onCrop,
  isCropping,
  onImageSelect,
  onFinishCropping,
  onCancelCropping,
  isHandwriting,
}: ImageEditDrawerProps): React.ReactElement => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isReplaceConfirmationOpen, setIsReplaceConfirmationOpen] = useState<boolean>(false);
  const { appDispatch } = useAppContext();
  const {
    cardState: { cardFacesList, activeCardIndex },
  } = useCardContext();
  const canvas = useActiveCanvas();
  const { trackUploadPhotoError, isReplacingImage, resetEditFormats, getEditFormats } = useAnalyticsContext();
  const {
    initializedDataState: { isUS, data: initializedData },
  } = useInitializationDataContext();
  const isPodProductCode = useIsPodProductCode();
  const isSignAndSend = initializedData?.project_type_code === CardType.SAS;
  const isPhotoZoneImage = canvas?.current?.getActiveObject()?.data?.type === CanvasDataTypes.PhotoZoneImage;
  const currentCardFace = cardFacesList[`${activeCardIndex}`];

  const { t } = useTranslation();

  const setError = (title: string, error: string) => {
    setIsToasterOpen(appDispatch, {
      variant: ToastVariants.Error,
      title,
      children: error,
    });
  };

  const setLoadingScreen = (message: string) => {
    showLoadingScreen(appDispatch, t(message));
  };

  const handleImageUpload = async (event: React.FormEvent<HTMLInputElement>): Promise<void> => {
    if (!initializedData) return;

    const uploadedFiles = (event.target as HTMLInputElement).files;
    if (!uploadedFiles) return;

    const imageToUpload = uploadedFiles[0];
    const isInvalidSize = imageToUpload.size >= MAX_FILE_SIZE;
    if (isInvalidSize) {
      trackUploadPhotoError(t('imageEditDrawer.sizeError'));
      onClose();
      return setError(`${t('imageEditDrawer.setErrorExceeded')}`, `${t('imageEditDrawer.sizeError')}`);
    }

    try {
      setLoadingScreen('imageEditDrawer.uploadingImage');

      datadogRum.addAction('upload-photo', {
        productId: initializedData.product_id,
        productType: initializedData.project_type_code,
        accountId: initializedData.account_id,
        accountType: getCookie('accountType'),
        file: {
          type: imageToUpload.type,
          size: imageToUpload.size,
          name: imageToUpload.name,
        },
      });

      const isPodProductCode =
        initializedData.project_type_code === CardType.POD || initializedData.project_type_code === CardType.DG;

      const { imageUrl, imageId, cleanUpVersionId } = await uploadImage(imageToUpload, initializedData.project_id, {
        cleanUp: !isPodProductCode,
        uploadMetadata: {
          is_handwriting_image: `${initializedData.project_type_code === CardType.SAS}`,
          display_indicator: `${isPodProductCode}`,
        },
        events: {
          onImageUpload: (image) => addPhotoTrayImages(appDispatch, [image]),
          onCleanUpStart: () => setLoadingScreen('imageEditDrawer.handwrittenImage'),
        },
      });

      fabric.Image.fromURL(
        imageUrl as string,
        (img) => {
          hideMiddleControls(img);
          const cardFaceClipPath = getCardFaceClipPath(currentCardFace, 0);
          if (cardFaceClipPath) {
            // Ensure image respects copyrighted zone boundaries
            img.clipPath = cardFaceClipPath;
          }
          const activeImage = canvas?.current?.getActiveObject();
          if (!activeImage || activeImage.type !== 'image') {
            return;
          }
          if (isPodProductCode) {
            // photo zone image
            const photoZoneId = activeImage.data?.photoZoneId;
            if (photoZoneId) {
              img.set({
                data: {
                  ...activeImage.data,
                  version_id: cleanUpVersionId,
                },
                name: imageId,
              });
              canvas?.current?.remove(activeImage);
              fillPhotoZone(photoZoneId, img, cardFacesList, handleIconAdded);
              hideLoadingScreen(appDispatch);
              onImageSelect();
              return;
            }
            img.scaleToWidth(200);
            img.scaleToHeight(200);
            img.set({
              left: activeImage.left,
              top: activeImage.top,
              opacity: 1,
              name: imageId,
              data: {
                ...activeImage.data,
                version_id: cleanUpVersionId,
              },
            });
            img.onSelect = activeImage.onSelect;
            isReplacingImage.current = true;
            canvas?.current?.remove(activeImage);
            canvas?.current?.add(img);
            canvas?.current?.setActiveObject(img);
            onClose();
            onImageSelect();
            isReplacingImage.current = false;
            hideLoadingScreen(appDispatch);
            return;
          }

          const activeZone = getObjectByName(activeImage.data?.zoneName, canvas?.current as fabric.Canvas);
          if (!activeZone) {
            return;
          }
          const { left, top, width, height } = activeZone;

          img.scaleToWidth(width as number);
          if (img.getScaledHeight() > (height as number)) {
            img.scaleToHeight(height as number);
          }
          const oImg = img.set({
            left: (left as number) - img.getScaledWidth() / 2,
            top: (top as number) - (height as number) / 2,
            originX: 'left',
            originY: 'top',
            name: imageId,
            data: {
              ...activeImage.data,
              version_id: cleanUpVersionId,
            },
          });

          oImg.onSelect = activeImage.onSelect;
          canvas?.current?.remove(activeImage);
          canvas?.current?.add(oImg);
          canvas?.current?.setActiveObject(oImg);
          onImageSelect();
          onCrop();
          hideLoadingScreen(appDispatch);
        },
        { crossOrigin: 'anonymous' },
      );
    } catch (e) {
      hideLoadingScreen(appDispatch);

      if (e instanceof InvalidImageFormatError) {
        return setIsToasterOpen(appDispatch, {
          variant: ToastVariants.Error,
          title: t('imageUploadDrawer.imageErrorTitle'),
          children: t('imageUploadDrawer.onlyImagesMessage'),
        });
      }

      const errorMessage = t('imageUploadDrawer.uploadImageErrorMessage');

      trackUploadPhotoError(errorMessage);
      setError('Image error', errorMessage);
    }
  };

  const handleIconAdded = (icon: fabric.Image) => {
    icon.on('mousedown', () => {
      setIsToasterOpen(appDispatch, {
        title: t('lowResolutionImage.title'),
        children: t('lowResolutionImage.message'),
        variant: ToastVariants.Warning,
      });
    });
  };

  const handleImageReplace = () => {
    const oldEditFormats: any = Object.assign({}, getEditFormats());
    const { move } = oldEditFormats;
    resetEditFormats({ replace: 'image', move: move });
    inputRef.current?.click();
    setIsReplaceConfirmationOpen(false);
  };

  const onSubmit = () => {
    if (isCropping && onFinishCropping) {
      onFinishCropping();
    }
    onClose();
  };

  const onSizeChange = () => {
    setIsScaleDrawerOpen(appDispatch);
  };
  const onRotateChange = () => {
    setIsRotationDrawerOpen(appDispatch);
  };
  const onOrderChange = () => {
    setIsOrderDrawerOpen(appDispatch);
  };
  const onReplace = () => {
    setIsReplaceConfirmationOpen(true);
  };
  const onReset = () => {
    setIsResetConfirmationDialogOpen(appDispatch);
  };

  return (
    <>
      <ConfirmationDialog
        domId="replace-confirmation"
        title={`${t('imageEditDrawer.replaceConfirmationTitle')}`}
        cancelButtonText={`${t('imageEditDrawer.replaceConfirmationCancelText')}`}
        actionButtonText={`${t('imageEditDrawer.replaceConfirmationConfirmText')}`}
        isOpen={isReplaceConfirmationOpen}
        onClose={() => setIsReplaceConfirmationOpen(false)}
        onConfirm={handleImageReplace}
      />
      <Drawer
        drawerPosition={DrawerPositions.Bottom}
        closeButtonText={`${t('imageEditDrawer.closeDrawer')}`}
        isOpen={isOpen}
        hideCloseButton={true}
        showVeil={false}
        onClose={onClose}
        role="toolbar"
        headerContent={
          <div className={styles['options-container']}>
            {!isCropping && (
              <>
                <DrawerButton icon={IconNames.CropBold} onClick={onCrop} label="Crop" />
                <DrawerButton icon={IconNames.ScaleRegular} onClick={onSizeChange} label="Size" />
                <DrawerButton icon={IconNames.RotateBold} onClick={onRotateChange} label="Rotate" />
                {isPodProductCode && <DrawerButton icon={IconNames.Trash} onClick={onDelete} label="Delete" />}
                {isPodProductCode && !isPhotoZoneImage && (
                  <DrawerButton icon={IconNames.LayersRegular} onClick={onOrderChange} label="Order" />
                )}
                {
                  // Replace button has been temporarily hidden on US cards until we can fine tune the experience.
                  !isHandwriting && !isUS && (
                    <DrawerButton icon={IconNames.Camera} onClick={onReplace} label="Replace" />
                  )
                }
                {isSignAndSend && <DrawerButton icon={IconNames.Trash} onClick={onReset} label="Reset" />}
              </>
            )}
          </div>
        }
        footerContent={
          // At the moment, we are only hiding the 'apply' button in US.
          (!isUS || isCropping) && (
            <div className={styles['submit-container']}>
              {isCropping && (
                <Button mode={ButtonModes.Secondary} click={onCancelCropping} addClass={styles['secondary-button']}>
                  {t('imageEditDrawer.cancel')}
                </Button>
              )}
              <Button mode={ButtonModes.Primary} click={onSubmit} addClass={styles['primary-button']}>
                {isCropping ? `${t('imageEditDrawer.apply')}` : `${t('imageEditDrawer.ok')}`}
              </Button>
            </div>
          )
        }
      >
        <span className={styles['drawer-content']}>{t('imageEditDrawer.photoEditorDrawer')}</span>
        <input
          type="file"
          aria-labelledby="wam-replace-button"
          ref={inputRef}
          onChange={handleImageUpload}
          style={{ display: 'none' }}
          accept="image/jpeg,image/jpg,image/gif,image/png,image/heic,image/heif,image/webp "
        />
      </Drawer>
    </>
  );
};
