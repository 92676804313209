const validStringRegExp = /^[a-z\s&'-]*$/i;
const validZipCodeRegExp = /(^\d{5}$)/;
const validPostCodeRegExp =
  /(^(([A-Z][0-9]{1,2})|(([A-Z][A-HJ-Y][0-9]{1,2})|(([A-Z][0-9][A-Z])|([A-Z][A-HJ-Y][0-9]?[A-Z])))) [0-9][A-Z]{2}$)/; // UK

export const validStringRule = (errorMessage: string) => (data: string) =>
  !!data.match(validStringRegExp) || errorMessage;

export const validZipCodeRule = (errorMessage: string) => (zip: string) => {
  const theme = process.env.REACT_APP_THEME;
  switch (theme) {
    case 'uk':
      return !!zip.match(validPostCodeRegExp) || errorMessage;
    default:
      return !!zip.match(validZipCodeRegExp) || errorMessage;
  }
};

export const getThemeZipLength = () => {
  const theme = process.env.REACT_APP_THEME;
  switch (theme) {
    case 'uk':
      return { min: 5, max: 8 };
    default:
      return { min: 5, max: 5 };
  }
};

// temporary regexs and error messages to match email form fields to know her validation
const validStringWithDotRegExp = /^[a-z\s&'-.]*$/i; // valid string with space, &, ', ., or -
const repeatingCharacterExp = /(\w|\w\w)\1{3}/; // repeating character 4 times
const restrictedNames = ['null', 'test', 'tester', 'testing', 'testin', 'delete'];
const beginsWithAcceptedSymbol = /^\B[\s&'-.]/; // begins with space, &, ', ., or -
const endsWithAcceptedSymbol = /[\s&'-]$/; // ends with space, &, ', or -

// temporary function to match email form fields to know her validation
export const validateNames = (value: string, translate: (string) => string): string | boolean => {
  if (!validStringWithDotRegExp.test(value)) return translate('addressUtil.restrictedCharactersError');
  if (beginsWithAcceptedSymbol.test(value)) return translate('addressUtil.alphabeticalCharacterError');
  if (endsWithAcceptedSymbol.test(value)) return translate('addressUtil.trailingSymbolError');
  if (restrictedNames.includes(value.toLowerCase())) return translate('addressUtil.validNameError');
  if (repeatingCharacterExp.test(value)) return translate('addressUtil.repeatingCharacterError');
  return true;
};
