import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { BrandColors } from '@hallmark/web.styles.colors';
import styles from '../recipient-addresses-dialog.module.scss';

export const OrderQuantity = ({ quantity }: { quantity: number }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.orderQuantity}>
      <Typography variant={TypographyVariants.Body} color={BrandColors.MediumGray}>
        {t('recipientAddresses.orderQuantity')}
      </Typography>
      <Typography variant={TypographyVariants.Body} color={BrandColors.MediumGray}>
        {quantity}
      </Typography>
    </div>
  );
};
