/**
 * Grabs appropriate envelope code to pass as envelope_type in /update call
 *
 * @param envelopeType 'blank', 'return', or 'return-recipient'
 * @returns EnvelopeCode of 'B', 'S', 'C'
 */
import { EnvelopeCode, EnvelopeType } from '../../global-types/envelope';

export const getEnvelopeCode = (envelopeType: string): EnvelopeCode => {
  switch (envelopeType) {
    case EnvelopeType.Blank:
      return EnvelopeCode.Blank;
    case EnvelopeType.Return:
      return EnvelopeCode.Return;
    case EnvelopeType.ReturnRecipient:
      return EnvelopeCode.ReturnRecipient;
    default:
      throw new Error(`Error getting envelope type for: ${envelopeType}`);
  }
};
