import { FormFieldSelected, FormFieldSelectedList, FormFields, FieldType } from '../form-fields-generator-types';

/**
 * Function to get the selected form fields, including the nested fields (Collapse component).
 * @param {FormFieldSelectedList[]} selectedFormFields - The list of selected form fields.
 * @param {FormFields[]} formFields - The array of all form fields.
 * @returns {FormFields[]} The list of selected form fields with their configurations.
 */
export const getSelectedFormFields = (
  selectedFormFields: FormFieldSelectedList[],
  formFields: FormFields[],
): FormFields[] => {
  // Get the nested fields with their configurations
  const getNestedFields = (nestedFields: FormFieldSelected[]): FormFieldSelected[] => {
    return nestedFields.map((nestedField: FormFieldSelected) => {
      const field = formFields.find((field) => field.name === nestedField.name);
      return {
        ...field,
        ...nestedField,
      };
    });
  };

  // Map through the selected form fields and handle nested fields if applicable
  const selectedFields = selectedFormFields.map((field: FormFieldSelectedList) => {
    if (field.typeOfField === FieldType.COLLAPSE && field.nestedFields) {
      return {
        ...field,
        nestedFields: getNestedFields(field.nestedFields),
      };
    } else {
      return formFields.find((formField) => formField.name === field.name);
    }
  });

  return selectedFields as FormFields[];
};
