import React from 'react';
import { useFeatureFlags } from '../../../hooks';
import { useIsOneToMany } from '../../../hooks/use-is-one-to-many';
import { NavigationButtons } from '../../navigation-buttons/navigation-buttons';
import { AddressFormFooterButtonsProps } from '../address-form-types';

export const AddressFormFooterButtons = ({ oneToOneProps, oneToManyProps }: AddressFormFooterButtonsProps) => {
  const { IS_MULT_ADDRESS_ENABLED } = useFeatureFlags();
  const isOneToMany = useIsOneToMany();

  return IS_MULT_ADDRESS_ENABLED ? (
    <footer>
      {!isOneToMany ? (
        <NavigationButtons
          secondaryActionButton={oneToOneProps.secondaryActionButton}
          primaryActionButton={oneToOneProps.primaryActionButton}
        />
      ) : (
        <NavigationButtons
          secondaryActionButton={oneToManyProps.secondaryActionButton}
          primaryActionButton={oneToManyProps.primaryActionButton}
        />
      )}
    </footer>
  ) : (
    <></>
  );
};
