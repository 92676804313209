import { useMemo, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useInitializationDataContext, updateDigitalAddress } from '../../../context/data-context';
import {
  EmailAddressForm as EmailAddressFormData,
  AddressTypes,
  DigitalAddress,
  ErrorResponse,
} from '../../../global-types';
import { DigitalAddressResponse } from '../../../global-types/addresses';
import { useSystemErrorHandling } from '../../../hooks';
import { getRecipients, saveRecipient, updateRecipient } from '../../../services';
import { getFieldsFromAddressResponse } from '../../../utils';
import { trimObjectStrings } from '../../../utils/trim-object-strings';

export const useAddressEmailSubmit = ({ setShouldSave }) => {
  // Contexts
  const {
    initializedDataState: { data: initializedData, addressData },
    initializationDataDispatch,
  } = useInitializationDataContext();
  const navigate = useNavigate();
  const currentRecipient = useRef<DigitalAddressResponse>();
  const hasRecipientLoaded = useRef(false);

  // Error handler to show dialog when error is catch
  const { onSystemError } = useSystemErrorHandling();

  // Set the default address values to pre-populate the email form (if an address had been stored to the project)
  const emailFormDefaultData = useMemo(() => {
    const storedAddress = getFieldsFromAddressResponse(addressData[AddressTypes.DIGITAL] as DigitalAddress);
    return { ...storedAddress };
  }, [addressData.digital]);

  const emailValues = useForm<EmailAddressFormData>({
    mode: 'onBlur',
    defaultValues: emailFormDefaultData,
  });

  const {
    handleSubmit: handleEmailFormSubmit,
    formState: { isValid: isValidEmail },
    getValues: getValuesEmailForm,
  } = emailValues;

  useEffect(() => {
    const fetchRecipient = async () => {
      const projectId = initializedData?.project_id;
      if (!projectId) return;
      hasRecipientLoaded.current = true;
      try {
        const getCurrentRecipient = await getRecipients(projectId);
        if (getCurrentRecipient.data && getCurrentRecipient.data?.length > 0) {
          currentRecipient.current = getCurrentRecipient.data[0];
        }
      } catch (errors) {
        onSystemError(errors as ErrorResponse);
      }
    };

    if (!hasRecipientLoaded.current) {
      fetchRecipient();
    }
  }, [initializedData?.project_id]);

  const handleEmailSubmit = async (data: EmailAddressFormData) => {
    const projectId = initializedData?.project_id;
    data = trimObjectStrings(data) as EmailAddressFormData;

    data.recipient_type_code = 'E';

    if (!projectId) return;

    if (data) {
      setShouldSave(true);
    }

    try {
      let recipientId = '';
      if (currentRecipient.current) {
        recipientId = currentRecipient.current.recipient_id || '';
        await updateRecipient(projectId, recipientId, data);
      } else {
        const recipientResponse = await saveRecipient(projectId, data);
        recipientId = recipientResponse.data?.recipient_id || '';
      }
      sessionStorage.setItem('recipient_id', recipientId);
      // update state by saving the submitted address
      updateDigitalAddress(initializationDataDispatch, data);
      // Digital addresses are updated, so proceed to preview view
      navigate({ pathname: '/card/customization/preview' });
    } catch (errors) {
      onSystemError(errors as ErrorResponse);
    }
  };

  return {
    emailValues,
    handleEmailFormSubmit,
    isValidEmail,
    getValuesEmailForm,
    handleEmailSubmit,
  };
};
