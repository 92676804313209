import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Range } from '@hallmark/web.core.forms.range';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { CustomFabricImage } from '../../../../global-types';
import { useActiveCanvas } from '../../../../hooks';
import { CanvasDataTypes } from '../../../../utils/canvas-utils';
import { getIconForLowResImage } from '../../../../utils/canvas-utils/get-icon-for-low-res-image';
import { validateImagePpi } from '../../../../utils/canvas-utils/validate-image-ppi';
import { HeaderContentProps } from '../scale-drawer-types';
import styles from '../scale-drawer.module.scss';

export const HeaderContent = ({ onChangeCallback }: HeaderContentProps): React.ReactElement => {
  const { scaleValue, handleChange } = useHeaderContent(onChangeCallback);
  const { register } = useForm();
  const rangeName = 'ScaleRange';
  const { t } = useTranslation();

  return (
    <div className={styles['header-wrapper']}>
      <div className={styles['scale-label']}>
        <Typography variant={TypographyVariants.FieldLabel}>{t('scaleDrawer.out')}</Typography>
        <Typography variant={TypographyVariants.InputText} addClass={styles['value']}>{`${scaleValue}`}</Typography>
        <Typography variant={TypographyVariants.FieldLabel}>{t('scaleDrawer.in')}</Typography>
      </div>
      <Range
        domId={'scale'}
        label={'Scale'}
        min={0}
        max={200}
        step={1}
        tickCount={0}
        onChange={handleChange}
        register={register(rangeName)}
      />
    </div>
  );
};

export const useHeaderContent = (onChange?: (val: number) => void) => {
  const [scaleValue, setScaleValue] = useState(100);
  const [originalScaleValue, setOriginalScaleValue] = useState<number>(1);
  const canvas = useActiveCanvas();

  const handleChange = (valueInRange: number) => {
    const scaleFactor = valueInRange / 100;
    const activeObject = canvas?.current?.getActiveObject() as CustomFabricImage;
    if (!activeObject) return;

    if (activeObject.type === 'image') {
      activeObject.set({
        scaleX: originalScaleValue * scaleFactor,
        scaleY: originalScaleValue * scaleFactor,
      });

      if (!canvas?.current) return;

      // Check if the active object has a photozone and update its icon visibility
      const photoZoneId = activeObject.data?.photoZoneId;
      if (photoZoneId) {
        // Find the icon associated with this image in the photozone
        const photoZoneIcon = canvas.current
          .getObjects()
          .find((obj) => obj.data?.type === CanvasDataTypes.PhotoIcon && obj.data?.parentName === activeObject.name);
        if (photoZoneIcon) {
          photoZoneIcon.visible = !validateImagePpi(activeObject);
        }
      } else {
        // get low res icon object for activeObject
        const lowResIcon = getIconForLowResImage(canvas.current, activeObject);
        if (lowResIcon) {
          // update icon visibility based on validateImagePpi
          lowResIcon.visible = !validateImagePpi(activeObject);
        }
      }

      canvas.current.renderAll();
      setScaleValue(valueInRange);

      if (onChange) onChange(valueInRange);
    }
  };

  useEffect(() => {
    const activeObject = canvas?.current?.getActiveObject();
    if (activeObject && activeObject?.type === 'image') {
      const scaleVal = activeObject.scaleX as number;
      setOriginalScaleValue(scaleVal);
    }
  }, []);

  return {
    scaleValue,
    handleChange,
  };
};
