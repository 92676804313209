import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { TextField } from '@hallmark/web.core.forms.text-field';
import { HtmlTagOverrides, Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { EmailAddressForm as EmailAddressFormData, PrefixForm } from '../../global-types/addresses';
import { getRequired } from '../../utils/address';
import { validateNames } from '../address-form/address-utils';
import { EmailAddressFormProps } from './email-address-form-types';
import styles from './email-address-form.module.scss';

export const EmailAddressForm = ({
  addClass,
  formHandlers: {
    register,
    formState: { errors },
    trigger,
  },
}: EmailAddressFormProps) => {
  const classes = classNames(styles['email-address-form-wrapper'], addClass);
  const { t } = useTranslation();

  const formTitle = `${t('emailAddressForm.emailTitle')}`;

  const shortFieldError = (min: number, max: number) => `${t('addressUtil.shortFieldError', { min, max })}`;
  const maxFirstNameLengthConfig = { value: 15, message: shortFieldError(2, 15) };
  const minFirstNameLengthConfig = { value: 2, message: shortFieldError(2, 15) };
  const maxLastNameLengthConfig = { value: 20, message: shortFieldError(2, 20) };
  const minLastNameLengthConfig = { value: 2, message: shortFieldError(2, 20) };

  const handleChange = (inputName: keyof EmailAddressFormData) => {
    trigger(inputName);
  };

  const handleValidateNames = (value) => {
    return validateNames(value as keyof EmailAddressFormData, t);
  };

  const handleValidateEmail = (value) =>
    /[a-zA-Z0-9+]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,3}/.test(value) || `${t('addressUtil.validEmailError')}`;

  return (
    <div className={classes}>
      <form className={styles['email-address-form']} data-testid="email-address-form">
        <div tabIndex={-1} className={styles['email-address-form-content']}>
          <Typography
            variant={TypographyVariants.Subtitle}
            addClass={styles.title}
            htmlTagOverride={HtmlTagOverrides.H2}
          >
            {formTitle}
          </Typography>

          <TextField
            register={register('recipient_first_name', {
              required: getRequired(PrefixForm.EMAIL_FORM, 'recipientFirstName'),
              validate: handleValidateNames,
              maxLength: maxFirstNameLengthConfig,
              minLength: minFirstNameLengthConfig,
            })}
            domId={`recipient-first-name`}
            label={`${t('emailAddressForm.recipientFirstName')}`}
            isError={Boolean(errors.recipient_first_name)}
            helperText={errors.recipient_first_name ? errors.recipient_first_name.message : ''}
            addClass={styles['form-field']}
            testId={'email_address_first_name'}
            required
            onChange={() => handleChange('recipient_first_name')}
          />

          <TextField
            register={register('recipient_last_name', {
              required: getRequired(PrefixForm.EMAIL_FORM, 'recipientLastName'),
              validate: handleValidateNames,
              maxLength: maxLastNameLengthConfig,
              minLength: minLastNameLengthConfig,
            })}
            domId={`recipient-last-name`}
            label={`${t('emailAddressForm.recipientLastName')}`}
            isError={Boolean(errors.recipient_last_name)}
            helperText={errors.recipient_last_name ? errors.recipient_last_name.message : ''}
            addClass={styles['form-field']}
            testId={'email_address_last_name'}
            required
            onChange={() => handleChange('recipient_last_name')}
          />

          <TextField
            register={register('contact_info', {
              required: getRequired(PrefixForm.EMAIL_FORM, 'contactInfo'),
              maxLength: { value: 55, message: shortFieldError(1, 55) },
              minLength: { value: 1, message: shortFieldError(1, 55) },
              validate: handleValidateEmail,
            })}
            domId={`contact-info`}
            label={`${t('emailAddressForm.contactInfo')}`}
            isError={Boolean(errors.contact_info)}
            helperText={errors.contact_info ? errors.contact_info.message : ''}
            addClass={styles['form-field']}
            testId={'email_address_recipient'}
            required
            onChange={() => handleChange('contact_info')}
          />

          <TextField
            register={register('sender_first_name', {
              required: getRequired(PrefixForm.EMAIL_FORM, 'senderFirstName'),
              validate: handleValidateNames,
              maxLength: maxFirstNameLengthConfig,
              minLength: minFirstNameLengthConfig,
            })}
            domId={`sender-first-name`}
            label={`${t('emailAddressForm.senderFirstName')}`}
            isError={Boolean(errors.sender_first_name)}
            helperText={errors.sender_first_name ? errors.sender_first_name.message : ''}
            addClass={styles['form-field']}
            testId={'email_address_your_first_name'}
            required
            onChange={() => handleChange('sender_first_name')}
          />

          <TextField
            register={register('sender_last_name', {
              required: getRequired(PrefixForm.EMAIL_FORM, 'senderLastName'),
              validate: handleValidateNames,
              maxLength: maxLastNameLengthConfig,
              minLength: minLastNameLengthConfig,
            })}
            domId={`sender-last-name`}
            label={`${t('emailAddressForm.senderLastName')}`}
            isError={Boolean(errors.sender_last_name)}
            helperText={errors.sender_last_name ? errors.sender_last_name.message : ''}
            addClass={styles['form-field']}
            testId={'email_address_your_last_name'}
            required
            onChange={() => handleChange('sender_last_name')}
          />
        </div>
      </form>
    </div>
  );
};
