import { useTranslation } from 'react-i18next';
import { Address, AddressResponseData, DigitalAddress } from '../global-types';
import { PrefixForm } from '../global-types/addresses';

/**
 * It takes an address and take just the necessary fields to be sent to /addresses endpoint in the request payload
 *
 * @param address Address object stored in app-context
 * @returns Address object with the necessary keys to be sent in payload
 */
export const getFieldsFromAddressResponse = (address: DigitalAddress | AddressResponseData | Address | null) => {
  if (!address) {
    return;
  }
  const keysToRemove = ['address_id', 'created_at', 'last_updated_at', 'is_verified'];
  const addressFormEntries = Object.entries(address).filter(([key]) => !keysToRemove.includes(key));
  return Object.fromEntries(addressFormEntries);
};
/**
 * Generates a required message for a specific field by combining the field name and a translation key.
 *
 * @param prefix The prefix indicating the form type (e.g., 'emailAddressForm' or 'addressForm').
 * @param field The specific field name to generate the message for.
 * @returns The generated required message for the specified field.
 */
export const getRequired = (prefix: PrefixForm, field: string) => {
  const { t } = useTranslation();
  return `${t(`${prefix}.isRequired`, { requirement: t(`${prefix}.${field}`) })}`;
};
