import { BaseAddress } from './addresses';
import { Page } from './page';
import { ApiResponse } from './services';

type Envelope = {
  template_json: {
    customData: unknown;
    documentPreferences: {
      pageHeight: number;
      pageWidth: number;
    };
    marginPreferences: {
      bottom: number;
      left: number;
      right: number;
      top: number;
    };
    name: string;
    pages: Page[];
    type: string;
    viewPreferences: {
      horizontalMeasurementUnits: string;
      verticalMeasurementUnits: string;
    };
  };
};

type EnvelopePreviewResponseData = {
  encoded_image: string;
};

type EnvelopeAddress = Omit<BaseAddress, 'address_type_code' | 'country_code'>;

type EnvelopePreviewBody = {
  color: string;
  envelope_size: string;
  recipient_address?: EnvelopeAddress;
  sender_address?: EnvelopeAddress;
};

type EnvelopePreviewResponse = ApiResponse<EnvelopePreviewResponseData>;

export enum EnvelopeType {
  Blank = 'blank',
  Return = 'return',
  ReturnRecipient = 'return-recipient',
}

export enum EnvelopeCode {
  Blank = 'B',
  Return = 'S',
  ReturnRecipient = 'C',
}

/**
 * This is the envelope that is selected by the user on the envelope view.
 */
type SelectedEnvelope = {
  /** Identifies the envelope type. This will also be used to get the label from the translations file */
  id: EnvelopeType;
  /** The image of the envelope*/
  image: string;
};

export type { Envelope, EnvelopePreviewResponse, EnvelopePreviewBody, SelectedEnvelope };
