import { useCookies } from 'react-cookie';
import { getMonolithUrl } from '../utils';
import { preventUnload } from './dom';

export const getCookie = (name: string): string | undefined => {
  // cookies are stored in key-value pairs
  // key-value pairs are separated by semicolons
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop()?.split(';').shift();
  }
};

/**
 *
 * @param projectId id of the project
 * @param guestAccountId account id of the guest user
 * @param lineItemUUID comes from url param and is set in initializedData
 * @returns object with redirect urls for edit and address views
 */
export const getLoginRedirectUrl = (projectId: string, guestAccountId: string, lineItemUUID: string | null) => {
  const redirectUrl = new URL(window.location.href);
  const isAddressUrl = redirectUrl.pathname.includes('/address');
  redirectUrl.searchParams.append('migrateFrom', guestAccountId);
  if (isAddressUrl) {
    redirectUrl.searchParams.append('projectId', projectId);
  }
  if (lineItemUUID) {
    redirectUrl.searchParams.append('lineItemUUID', lineItemUUID);
  }
  return redirectUrl;
};

/**
 * Sets the url to redirect the user after log in and navigate the user to the login url
 *
 * @param projectId id of the project
 * @param guestAccountId account id of the guest user
 * @param step view to be redirected after login
 */
export const loginRedirect = (
  projectId: string,
  guestAccountId: string,
  lineItemUUID: string | null,
  isUK?: boolean,
) => {
  window.removeEventListener('beforeunload', preventUnload);
  const loginUrl = getLoginUrl(projectId, guestAccountId, lineItemUUID, isUK);
  window.location.assign(loginUrl);
};

type AccountCookies = 'accountId' | 'accountType' | 'accessToken';
/**
 *
 * @param removeCookie Remove cookies function from react-cookie useCookies hook.
 *
 * @example
 *   const [cookies, setCookie, removeCookie] = useCookies(['accountId', 'accountType', 'accessToken']);
 *   ...
 *   cleanAccountCookies(removeCookie);
 */
export const cleanAccountCookies = (removeCookie: ReturnType<typeof useCookies>[2]) => {
  const accountCookies: AccountCookies[] = ['accountId', 'accountType', 'accessToken'];
  const currentDomain = window.location.host.slice(4);
  accountCookies.forEach((cookie) => {
    removeCookie(cookie, { path: '/', domain: currentDomain });
  });
};

/**
 * Returns the login url when we need to redirect user to sign in
 * @param projectId the POD project's id
 * @param guestAccountId used to migrate project to signed in account after redirect
 * @param lineItemUUID comes from url param and is set in initializedData
 * @returns login url with redirect param
 */
export const getLoginUrl = (projectId: string, guestAccountId: string, lineItemUUID: string | null, isUK?: boolean) => {
  const loginPath = isUK ? '/account/login' : '/login';

  const redirectUrl = getLoginRedirectUrl(projectId, guestAccountId, lineItemUUID);
  const accountUrl = new URL(loginPath, getMonolithUrl());
  accountUrl.searchParams.append('URL', redirectUrl?.href ?? '');
  const { origin, pathname, hash, search } = accountUrl;

  return `${origin}${pathname}${hash}${search}`;
};

export const isLoggedIn = () => {
  return getCookie('accountType') === 'R';
};

// Tokens keys for API calls authorization
const SSOAuthKeys = ['jwt', 'jwt_refresh', 'jwt_expires', 'jwt_refresh_expires'];

/**
 * Removes all tokens from localStorage
 */
export const removeTokens = () => {
  SSOAuthKeys.forEach((key) => {
    localStorage.removeItem(key);
  });
};

/**
 * Gets all tokens from cookies, and store them in localStorage.
 * Our api-middleware this needs tokens stored in LS
 */
export const getTokensFromCookies = () => {
  // Create SSOAuth object with all values from the cookies
  const SSOAuth: Record<string, string> = SSOAuthKeys.reduce((result, currentKey) => {
    result[`${currentKey}`] = getCookie(currentKey);
    return result;
  }, {});

  // store tokens in localStorage
  Object.entries(SSOAuth).forEach(([key, value]) => {
    localStorage.setItem(key, value);
  });
};

/**
 * Checks if the tokens in localStorage are defined, and if the last accountId stored in localStorage matches the one in the cookies.
 *
 * @returns true if the tokens are defined and match the current accountId in cookies
 */
export const areTokensValid = () => {
  const areTokensDefined = SSOAuthKeys.every((key) => {
    const token = localStorage.getItem(key) ?? '';
    return token !== '';
  });
  const isAccountIdValid = localStorage.getItem('accountId') === getCookie('accountId');

  return areTokensDefined && isAccountIdValid;
};
