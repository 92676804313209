/**
 * Sets the 'envelope' search parameter based on the provided conditions.
 *
 * @param {URLSearchParams} searchParams - A URLSearchParams instance representing the current URL search parameters.
 * @param {boolean} isOneToMany - A flag indicating if the card is of "one to many" type.
 * @param {boolean} skipAddress - A flag indicating if we skip address view and thus won't need an envelope param.
 */
import { EnvelopeType } from '../../../global-types/envelope';

export const setEnvelopeSearchParam = (searchParams: URLSearchParams, isOneToMany: boolean, skipAddress?: boolean) => {
  if (skipAddress || !isOneToMany) {
    // removes envelope parameter if exists
    searchParams.delete('envelope');
    return;
  }

  const envelope = searchParams.get('envelope') as EnvelopeType | null;

  if (envelope && !Object.values(EnvelopeType).includes(envelope)) {
    // if the envelope is not a valid value, set it to blank by default
    searchParams.set('envelope', EnvelopeType.Blank);
    return;
  }

  // if the envelope is not set, set it to blank by default
  if (!envelope) searchParams.set('envelope', EnvelopeType.Blank);
};
