import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@hallmark/web.core.display.icon';
import { HtmlTagOverrides, Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { BrandColors } from '@hallmark/web.styles.colors';
import { IconNames } from '@hallmark/web.styles.fonts.icomoon';
import { useInitializationDataContext } from '../../../context/data-context';
import { EnvelopeType } from '../../../global-types/envelope';
import { navigateTo } from '../../../utils/dom';
import { getLoginUrl, isLoggedIn } from '../../../utils/login';
import { useEnvelopeSelection } from '../../../views/select-envelope/hooks/use-envelope-selection';
import { AddressBookButtonProps } from '../address-form-types';
import styles from '../address-form.module.scss';

export const AddressBookButton = ({ click }: AddressBookButtonProps): React.ReactElement => {
  const {
    initializedDataState: { data: initializedData, lineItemUUID },
  } = useInitializationDataContext();
  const { selectedEnvelope } = useEnvelopeSelection();
  const isReturnRecipient = selectedEnvelope === EnvelopeType.ReturnRecipient;

  const { t } = useTranslation();
  const loginRedirectUrl = getLoginUrl(
    initializedData?.project_id as string,
    initializedData?.account_id as string,
    lineItemUUID,
  );

  // onClick handles the redirect to login page if user is not logged in,
  // otherwise, it calls the click function passed as a prop which should open the address book
  const onClick = () => {
    if (isLoggedIn()) {
      click();
    } else {
      navigateTo(loginRedirectUrl);
    }
  };

  return (
    <button
      onClick={onClick}
      className={styles['open-address-book-button']}
      type="button"
      data-testid="address-book-button"
      aria-label="AddressBookButton"
    >
      <div className={styles['open-address-book-button-container']}>
        <div className={styles['open-address-book-button-icon-container']}>
          <Icon size={40} name={IconNames.AddressBookBold} />
        </div>
        <div className={styles['open-address-book-button-text-container']}>
          <Typography
            htmlTagOverride={HtmlTagOverrides.Div}
            variant={TypographyVariants.BodyBold}
            data-testid={'address-book-title'}
          >
            {t('AddressBookButton.addressBookTitle')}
          </Typography>
          <Typography
            addClass={styles['open-address-book-button-text-label']}
            htmlTagOverride={HtmlTagOverrides.Div}
            variant={TypographyVariants.Body}
            data-testid={'address-book-label'}
          >
            {isReturnRecipient
              ? t('AddressBookButton.addressBookReturnRecipientLabel')
              : t('AddressBookButton.addressBookLabel')}
          </Typography>
        </div>
        <div className={styles['open-address-book-caret-container']}>
          <Icon color={BrandColors.Black} size={16} name={IconNames.ArrowsCaretUp} />
        </div>
      </div>
    </button>
  );
};
