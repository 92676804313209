import React, { ReactElement } from 'react';
import { IconButtonBrandColors } from '@hallmark/web.core.buttons.icon-button';
import { HtmlTagOverrides, Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { SelectionCardContentProps } from '../contact-types';

export default function SelectionCardContent({
  contact: { first_name, last_name, address_line_1, city, state_code, zip },
  showStateCode,
}: SelectionCardContentProps): ReactElement {
  return (
    <>
      <Typography
        htmlTagOverride={HtmlTagOverrides.Div}
        variant={TypographyVariants.FieldLabel}
        color={IconButtonBrandColors.Black}
      >
        {`${first_name} ${last_name}`}
      </Typography>
      <Typography
        htmlTagOverride={HtmlTagOverrides.Div}
        variant={TypographyVariants.Body}
        color={IconButtonBrandColors.Black}
      >
        {address_line_1}
      </Typography>
      <Typography
        htmlTagOverride={HtmlTagOverrides.Div}
        variant={TypographyVariants.Body}
        color={IconButtonBrandColors.Black}
      >
        {!showStateCode ? `${city}, ${zip}` : `${city}, ${state_code} ${zip}`}
      </Typography>
    </>
  );
}
