import { useCallback, useState } from 'react';
import { useAnalyticsContext } from '../context/analytics-context';
import { useAppContext } from '../context/app-context';
import { preventUnload, triggerAbandonOrDeleteProjectEvent, getMonolithUrl } from '../utils';

export const useConfirmationDialog = () => {
  const { trackExitFlow, trackAbandonOrDeleteProject } = useAnalyticsContext();

  const { appState } = useAppContext();
  const { exitUrl, triggeringElementRef } = appState;

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [shouldGoToHomePage, setShouldGoToHomePage] = useState(false);

  const dialogGoToHomePage = useCallback(() => {
    setShouldGoToHomePage(true);
    setIsDialogOpen(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setShouldGoToHomePage(true);
    setIsDialogOpen(false);
    triggeringElementRef?.focus();
  }, [triggeringElementRef]);

  const discardChanges = () => {
    trackAbandonOrDeleteProject(triggerAbandonOrDeleteProjectEvent());
    window.removeEventListener('beforeunload', preventUnload);
    window.location.href = shouldGoToHomePage ? getMonolithUrl() : exitUrl;
    trackExitFlow();
  };

  return {
    isDialogOpen,
    setIsDialogOpen,
    shouldGoToHomePage,
    setShouldGoToHomePage,
    dialogGoToHomePage,
    handleCloseDialog,
    discardChanges,
  };
};
