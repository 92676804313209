import React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogIconNames, DialogContentType, DialogFooterType, Dialog } from '@hallmark/web.core.feedback.dialog';
import { BrandColors } from '@hallmark/web.styles.colors';
import { getCurrentRegion } from '../../regional-config/get-regional-config';
import { ActionButton } from './fragments/action-button';
import { CancelButton } from './fragments/cancel-button';
import { QuantityChangeDialogProps } from './quantity-change-dialog-types';

export const QuantityChangeDialog = ({
  actionButton,
  cancelButton,
  onClose,
  isOpen,
  isIncrementing,
}: QuantityChangeDialogProps) => {
  const { t } = useTranslation();
  const region = getCurrentRegion();
  const title = isIncrementing ? t('quantityChangeDialog.increaseTitle') : t('quantityChangeDialog.decreaseTitle');
  const body = isIncrementing ? t('quantityChangeDialog.increaseBody') : t('quantityChangeDialog.decreaseBody');
  const actionButtonText = isIncrementing
    ? t('quantityChangeDialog.increaseAction')
    : t('quantityChangeDialog.decreaseAction');
  const cancelButtonText = isIncrementing
    ? t('quantityChangeDialog.increaseCancel')
    : t('quantityChangeDialog.decreaseCancel');

  return (
    <Dialog
      id={'quantity-change-dialog'}
      isOpen={isOpen}
      onClose={onClose}
      closeButtonId={'close-quantity-dialog-button'}
      accentIcon={DialogIconNames.CardFeaturesBold}
      accentIconColor={BrandColors.White}
      hasGrayBackground={true}
      type={DialogContentType.Modal}
      testId="quantity-change-dialog"
      title={title}
      footerType={DialogFooterType.Cancel}
      disableDialogScroll={true}
      actionButton={<ActionButton label={actionButtonText} click={actionButton} />}
      cancelButton={<CancelButton label={cancelButtonText} click={cancelButton} />}
      locale={region}
    >
      <p>{body}</p>
    </Dialog>
  );
};
