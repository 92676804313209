import { ReactNode } from 'react';
import { Contact } from '../../components/address-book';

export type AddressSelectionProviderProps = {
  children: ReactNode;
};

export type AddressSelectionContextState = {
  contacts: Contact[];
  count: number;
  maxCount: number;
  isMaxCountReached: boolean;
};

export enum AddressSelectionType {
  SET_CONTACTS = 'ADD_CONTACTS',
  SET_COUNT = 'SET_COUNT',
  INCREASE_COUNT = 'INCREASE_COUNT',
  DECREASE_COUNT = 'DECREASE_COUNT',
  SET_MAX_COUNT = 'SET_MAX_COUNT',
  RESET_COUNT = 'RESET_COUNT',
  SET_IS_MAX_COUNT_REACHED = 'SET_IS_MAX_COUNT_REACHED',
}

export type SetAddressSelectionContactsAction = {
  type: AddressSelectionType.SET_CONTACTS;
  payload: Contact[];
};

export type SetAddressSelectionCountAction = {
  type: AddressSelectionType.SET_COUNT;
  payload: number;
};

export type ResetAddressSelectionCountAction = {
  type: AddressSelectionType.RESET_COUNT;
};

export type IncrementAddressSelectionCountAction = {
  type: AddressSelectionType.INCREASE_COUNT;
};

export type DecrementAddressSelectionCountAction = {
  type: AddressSelectionType.DECREASE_COUNT;
};

export type SetAddressSelectionCountMaxAction = {
  type: AddressSelectionType.SET_MAX_COUNT;
  payload: number;
};

export type SetIsMaxCountReachedAction = {
  type: AddressSelectionType.SET_IS_MAX_COUNT_REACHED;
  payload: boolean;
};

export type AddressSelectionAction =
  | SetAddressSelectionContactsAction
  | SetAddressSelectionCountAction
  | ResetAddressSelectionCountAction
  | IncrementAddressSelectionCountAction
  | DecrementAddressSelectionCountAction
  | SetAddressSelectionCountMaxAction
  | SetIsMaxCountReachedAction;

export type Dispatch = (action: AddressSelectionAction) => void;

export type CreateContextProps =
  | {
      addressSelectionState: AddressSelectionContextState;
      addressSelectionDispatch: Dispatch;
    }
  | undefined;
