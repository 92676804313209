import { useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthenticatedFormData } from '../components/save-project-dialog/save-project-dialog-types';
import { useAnalyticsContext } from '../context/analytics-context';
import { setIsSaving, useAppContext } from '../context/app-context';
import { useInitializationDataContext } from '../context/data-context';
import { getLoginUrl, isLoggedIn, navigateTo, triggerAbandonOrDeleteProjectEvent, getMonolithUrl } from '../utils';
import { savePersonalization } from '../utils/save-project';
import { useProjectSummary } from './useProjectSummary';
import { useQueryParams } from './useQueryParams';
import { useSaveProject } from './useSaveProject';

export const useSaveProjectDialog = () => {
  const {
    trackSignIn,
    trackEditProjectName,
    trackDefaultProjectName,
    trackExitFlow,
    trackExitSaveProject,
    trackAbandonOrDeleteProject,
  } = useAnalyticsContext();
  const { defaultProjectName } = useProjectSummary();
  const { saveDraftName, saveProject } = useSaveProject();
  const {
    initializedDataState: { isUK },
  } = useInitializationDataContext();

  const { appDispatch, appState } = useAppContext();

  const { exitUrl: exitUrlForAbandon } = appState;

  const { pathname } = useLocation();
  const isEditorView = pathname.includes('edit');

  const navigate = useNavigate();

  const queryParams = useQueryParams();
  const exitUrl = queryParams.get('exitUrl');
  const isAbandoningProject = queryParams.get('exitUrl') !== null;

  const [isExitFromXButton, setIsExitFromXButton] = useState(false);

  const isSaveProjectDialogOpen =
    queryParams.get('saveProjectDialog') === 'true' || queryParams.get('exitUrl') !== null;

  const hasUpdatedDraftName = useRef(false);
  const {
    initializedDataState: { data: initializedData, lineItemUUID },
  } = useInitializationDataContext();

  const handleClose = () => {
    queryParams.delete('saveProjectDialog');
    queryParams.delete('exitUrl');
    navigate({ pathname, search: queryParams.toString() }, { replace: true });
  };

  const onCloseDialog = () => {
    trackSignIn('cancel');
    handleClose();
  };

  const handleExitFlow = (url: string) => {
    if (url) {
      navigateTo(url);
    }
  };

  const onSaveProject = async (data: AuthenticatedFormData, CTAFromXbutton: boolean) => {
    handleClose();
    setIsSaving(appDispatch, true);
    await Promise.all([
      savePersonalization(
        { shouldRestoreCanvas: true, showLoadingScreen: true, generateFrontPreview: false },
        isEditorView,
        saveProject,
      ),
      saveDraftName(data.projectName, true),
    ]);
    if (data.projectName !== defaultProjectName) {
      trackEditProjectName();
    } else {
      trackDefaultProjectName();
    }
    hasUpdatedDraftName.current = true;
    setIsSaving(appDispatch, false);
    const exitSaveProject = CTAFromXbutton ? 'exit-x' : 'exit';
    trackExitSaveProject('editName', exitSaveProject);
    handleExitFlow(exitUrl || '');
  };

  const onSignIn = () => {
    trackSignIn('sign in');
    handleClose();
    savePersonalization(
      { shouldRestoreCanvas: false, showLoadingScreen: true, generateFrontPreview: false },
      isEditorView,
      saveProject,
    ).then(() => {
      navigateTo(loginUrl);
    });
  };

  const onAbandonProject = async () => {
    if (isLoggedIn() && initializedData?.name) {
      await saveDraftName(initializedData.name, false);
    }
    trackAbandonOrDeleteProject(triggerAbandonOrDeleteProjectEvent());
    const redirectUrl = !isExitFromXButton ? getMonolithUrl() : exitUrlForAbandon;
    handleExitFlow(redirectUrl || '');
    trackExitFlow();
  };

  const loginUrl = getLoginUrl(
    initializedData?.project_id as string,
    initializedData?.account_id as string,
    lineItemUUID,
    isUK,
  );

  return {
    isSaveProjectDialogOpen,
    isEditorView,
    isAbandoningProject,
    onCloseDialog,
    onSignIn,
    onAbandonProject,
    onSaveProject,
    setIsExitFromXButton,
    isExitFromXButton,
  };
};
