import { CustomerAuthResponseData, HallmarkMembershipStatus } from '../../global-types';
import { capitalizeFirstLetter } from '../utility/capitalize-first-letter';

export enum HPlusMemberType {
  CR = 'CR',
  HP = 'HP',
  CR_HP = 'CR-HP',
  CR_HP_INACTIVE = 'CR-HP-Inactive',
}

export enum SubscriptionHMPlusCodes {
  BLANK = '',
  NEVER = 'Never',
  TRIAL = 'Trial',
  MONTHLY = 'Monthly',
  ANNUAL = 'Annual',
  EXPIRED = 'Expired',
  INACTIVE = 'Inactive',
}

export const parseMembershipData = (membershipData: CustomerAuthResponseData): HallmarkMembershipStatus => {
  if (!membershipData || !membershipData.c_CRMemberDetails) {
    // eslint-disable-next-line no-console
    console.warn('Could not get membership data');

    return {
      memberType: '',
      rewardsMembershipLevel: '',
      rewardId: '',
      plusMembership: '',
      customerId: '',
    };
  }

  const { c_CRMemberDetails, c_subscriptionHMPlusCode, c_subscriptionHMPlusCardNumber, customer_id } = membershipData;
  // c_CRMemberDetails is returned from the API as a JSON string so we need to parse it
  const crMemberDetails = JSON.parse(c_CRMemberDetails);

  const getMemberType = (isCRSetupComplete) => {
    let memberType = '';
    // Potential values for  values for "c_subscriptionHMPlusCode": Never, Trial, Monthly, Annual, Expired
    // See https://hallmark.atlassian.net/wiki/spaces/NMA/pages/4206755883/Enterprise+Subscriptions
    // See https://hallmark.atlassian.net/browse/ES-1391

    const isHMPlus =
      c_subscriptionHMPlusCode &&
      c_subscriptionHMPlusCode !== SubscriptionHMPlusCodes.BLANK &&
      c_subscriptionHMPlusCode !== SubscriptionHMPlusCodes.NEVER &&
      c_subscriptionHMPlusCode !== SubscriptionHMPlusCodes.EXPIRED;

    if (isCRSetupComplete && isHMPlus && c_subscriptionHMPlusCode === SubscriptionHMPlusCodes.INACTIVE) {
      memberType = HPlusMemberType.CR_HP_INACTIVE;
    } else if (isCRSetupComplete && isHMPlus) {
      memberType = HPlusMemberType.CR_HP;
    } else if (isCRSetupComplete) {
      memberType = HPlusMemberType.CR;
    } else if (isHMPlus) {
      memberType = HPlusMemberType.HP;
    }

    return memberType;
  };

  const getPlusMembership = (c_subscriptionHMPlusCode) => {
    if (c_subscriptionHMPlusCode === 'H+ Monthly') {
      return SubscriptionHMPlusCodes.MONTHLY;
    }
    if (!c_subscriptionHMPlusCode) {
      return SubscriptionHMPlusCodes.BLANK;
    }
    return capitalizeFirstLetter(c_subscriptionHMPlusCode);
  };

  return {
    memberType: getMemberType(crMemberDetails.IsCRSetupComplete),
    rewardsMembershipLevel: crMemberDetails.Tier,
    rewardId: crMemberDetails.CRNumber || c_subscriptionHMPlusCardNumber,
    plusMembership: getPlusMembership(c_subscriptionHMPlusCode),
    customerId: customer_id,
  };
};
