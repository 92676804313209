import React from 'react';
import { Icon, IconNames } from '@hallmark/web.core.display.icon';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { BrandColors } from '@hallmark/web.styles.colors';
import styles from '../recipient-addresses-dialog.module.scss';

export const DetailRecipients = ({
  shouldEdit,
  iconName,
  description,
  amount,
}: {
  shouldEdit?: boolean;
  iconName: IconNames;
  description: string;
  amount: number;
}) => {
  return (
    <div className={styles.detailRecipientsContainer}>
      <div className={styles.detail}>
        <Icon name={iconName} size={24} color={BrandColors.DarkGray} />
        <Typography variant={TypographyVariants.BodyBold} className={styles.text} color={BrandColors.DarkGray}>
          {description}
          <Typography variant={TypographyVariants.Body} className={styles.text} color={BrandColors.DarkGray}>
            {amount}
          </Typography>
        </Typography>
      </div>
      {shouldEdit && <Icon name={IconNames.Edit} size={16} color={BrandColors.DarkGray} />}
    </div>
  );
};
