import { useQueryParams } from '../../../hooks/useQueryParams';

export const useOrderQuantitySummary = ({
  printedEnvelopes,
}: {
  printedEnvelopes: number;
}): {
  quantity: number;
  blankEnvelopes: number;
} => {
  const queryParams = useQueryParams();
  const quantity = Number(queryParams.get('qty') || 1);

  if (printedEnvelopes === 0) return { quantity, blankEnvelopes: quantity };

  const blankEnvelopes = quantity > 1 ? quantity - printedEnvelopes : 0;

  return {
    quantity,
    blankEnvelopes,
  };
};
