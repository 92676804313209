import API from '@dotcom/api-middleware';
import axios from 'axios';
import {
  assetStorageResponse,
  getImagesResponse,
  linkImageResponse,
  uploadImageResponse,
  cleanUpImageResponse,
  cropAndRotateResponse,
  deletePhotoTrayImageResponse,
  successNoContentResponse,
} from '../data';
import {
  ImageResponse,
  LinkPhotoResponse,
  CleanUpImageResponse,
  CropAndRotateResponse,
  linkPhotoFormData,
  cleanUpFormData,
  cropAndRotateFormData,
} from '../global-types';
import { AssetStorageResponse, LinkedAsset, LinkedAssets, PhotoTrayImage } from '../global-types/images';
import { ApiResponse, ErrorResponse, SuccessNoContentResponse } from '../global-types/services';
import { getDateFromDaysAgo } from '../utils/get-date-from-days-ago';
import { getAccountIdFromCookie } from '../utils/utility/get-account-id-from-cookie';
import { processResponse } from './middleware';

const PODService = new API();

const mockResponse = process.env.REACT_APP_MOCK_INITIALIZATION_SERVICE === 'true';

const accountId = getAccountIdFromCookie();

export const linkPhoto = (formData: linkPhotoFormData, project_id: string): Promise<ApiResponse<LinkPhotoResponse>> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      const response: ApiResponse<LinkPhotoResponse> = linkImageResponse;
      resolve(response);
    }

    const response = PODService.post('/customization', `/${accountId}/${project_id}/images`, formData);
    processResponse(response, resolve, reject);
  });
};

export const linkAssets = (assets: LinkedAsset[], project_id: string): Promise<LinkedAssets> => {
  return new Promise((resolve, reject) => {
    const response = PODService.post('/customization', `/${accountId}/${project_id}/assets/link`, { assets });
    processResponse(response, resolve, reject);
  });
};

export const uploadPreviewToS3 = async (formData: FormData, s3Url: string): Promise<ApiResponse<unknown> | string> => {
  const file = formData.get('file') as File;
  const [imageUrl] = s3Url.split('?');
  return await new Promise((resolve, reject) => {
    axios
      .put(s3Url, file)
      .then(() => {
        resolve(imageUrl);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const uploadPhotoS3 = async (
  formData: FormData,
  isHandwritingImage: boolean,
  s3Url: string,
): Promise<ApiResponse<ImageResponse> | unknown> => {
  const file = formData.get('file') as File;

  return axios
    .put(s3Url, file, { headers: { 'Content-Type': file.type } })
    .then((assetResponse) => {
      if (assetResponse.data) {
        return Promise.reject(assetResponse);
      }
      return new Promise((resolve, reject) => {
        if (mockResponse) {
          const response: ApiResponse<ImageResponse> = uploadImageResponse;
          resolve(response);
        }

        const [image_url] = s3Url.split('?');
        const requestBody = {
          photo_tray_images: [
            {
              image_url: image_url,
              display_indicator: true,
              is_handwriting_image: isHandwritingImage,
            },
          ],
        };

        const response = PODService.post('/customization', `/${accountId}/photo-tray-images/images`, requestBody, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        processResponse(response, resolve, reject);
      });
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      return Promise.reject(error);
    });
};

export const associatePhotoTrayImages = (images: PhotoTrayImage[]): Promise<ApiResponse<ImageResponse>> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      const response: ApiResponse<ImageResponse> = uploadImageResponse;
      resolve(response);
    }
    const response = PODService.post(
      '/customization',
      `/${accountId}/photo-tray-images/images`,
      {
        photo_tray_images: images,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    processResponse(response, resolve, reject);
  });
};

export const getAssetUrls = (count, format): Promise<ApiResponse<AssetStorageResponse>> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      const response: ApiResponse<AssetStorageResponse> = assetStorageResponse;
      resolve(response);
    }

    const response = PODService.post('/customization', `/${accountId}/asset-storage-url`, {
      count: count,
      format,
      location: 'images',
    });
    processResponse(response, resolve, reject);
  });
};

export const uploadToAssetStorage = (
  assetUrl: string,
  file: File,
): Promise<SuccessNoContentResponse<unknown> | ErrorResponse> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      const response: SuccessNoContentResponse<unknown> = assetStorageResponse;
      resolve(response);
    }

    axios.put(`${assetUrl}`, file).then(
      (response) => {
        resolve(response);
      },
      (err) => {
        reject(err.toJSON());
      },
    );
  });
};

export const getPhotos = (): Promise<ApiResponse<ImageResponse[]>> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      const response: ApiResponse<ImageResponse[]> = getImagesResponse;
      resolve(response);
    }

    const response = PODService.get('/customization', `/${accountId}/photo-tray-images`);
    processResponse(response, resolve, reject);
  });
};

export const getPhotosPagination = (page = 1): Promise<ApiResponse<ImageResponse[]>> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      const response: ApiResponse<ImageResponse[]> = getImagesResponse;
      resolve(response);
    }
    const ninetyDaysAgo = getDateFromDaysAgo(90);
    const queryParams = `p[page]=[{"eq": "${page}"}]&p[limit]=[{"eq": "15"}]&q[created_at]=[{"gte":"${ninetyDaysAgo}"}]&q[sort_by]=[{"field":"created_at","order":"asc"}]`;
    const url = `/customization/${accountId}/photo-tray-images?${queryParams}`;
    const response = PODService.get(url);
    processResponse(response, resolve, reject);
  });
};

export const cleanUpImage = (
  projectId: string,
  imageId: string,
  formData: cleanUpFormData,
): Promise<ApiResponse<CleanUpImageResponse>> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      const response: ApiResponse<CleanUpImageResponse> = cleanUpImageResponse;
      resolve(response);
    }

    const response = PODService.post(
      '/customization',
      `/${accountId}/${projectId}/images/${imageId}/cleanup`,
      formData,
    );
    processResponse(response, resolve, reject);
  });
};

export const deleteImage = (projectId: string, imageId: string): Promise<SuccessNoContentResponse<null>> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      resolve(successNoContentResponse);
    }

    const response = PODService.delete('/customization', `/${accountId}/${projectId}/images/${imageId}`);
    processResponse(response, resolve, reject);
  });
};

export const deletePhotoTrayImage = (photoTrayImageId: string) => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      resolve(deletePhotoTrayImageResponse);
    }
    const response = PODService.delete('/customization', `/${accountId}/photo-tray-images/${photoTrayImageId}`);
    processResponse(response, resolve, reject);
  });
};

export const deletePhotoTrayImages = (photoTrayImageIds: string[]) => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      resolve(successNoContentResponse);
    }

    const response = PODService.delete('/customization', `/${accountId}?photo_tray_image_ids=${photoTrayImageIds}`);
    processResponse(response, resolve, reject);
  });
};

export const cropAndRotateImage = (
  projectId: string,
  imageId: string,
  formData: cropAndRotateFormData,
): Promise<ApiResponse<CropAndRotateResponse>> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      const response: ApiResponse<CropAndRotateResponse> = cropAndRotateResponse;
      resolve(response);
    }

    const response = PODService.post(
      '/customization',
      `/${accountId}/${projectId}/images/${imageId}/crop-and-rotate`,
      formData,
    );

    processResponse(response, resolve, reject);
  });
};
