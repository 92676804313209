import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAddressSelectionContext } from '../../../context/address-selection-indicator/address-selection';
import { useInitializationDataContext } from '../../../context/data-context';
import { AddressForm } from '../../../global-types';
import { EnvelopeType } from '../../../global-types/envelope';
import useAddressSelection from '../../../hooks/use-address-selection';
import { useEnvelopeSelection } from '../../select-envelope/hooks/use-envelope-selection';
import { onHandleGoNext } from '../utils/on-handle-go-next';

type UseOneToManyNavigationProps = {
  isFormValid: boolean;
  isAddressLoading: boolean;
  isAddressFormValid: boolean;
  handlePrimaryActionButton: () => void;
  handleSecondaryActionButton: () => void;
  addressFormValues: UseFormReturn<AddressForm>;
  getAddresses: () => void;
  isDialogOpen: boolean;
  isGetAddressesLoading: boolean;
};

// This hook handles the navigation logic for 1:M cards
export const useOneToManyNavigation = ({
  isFormValid,
  isAddressLoading,
  isAddressFormValid,
  handlePrimaryActionButton,
  handleSecondaryActionButton,
  addressFormValues,
  getAddresses,
  isDialogOpen,
  isGetAddressesLoading,
}: UseOneToManyNavigationProps) => {
  const { t } = useTranslation();
  const { handleIncrease: increaseAddressCounter, handleDecrease, handleSetCount, maxCount } = useAddressSelection();

  const {
    addressSelectionState: { isMaxCountReached, count },
  } = useAddressSelectionContext();
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();

  const {
    formState: { isValid, isDirty: isTouched },
    reset: resetFormAddresses,
    getValues: handleGetFormValues,
  } = addressFormValues;

  // secondary button props
  const onHandleGoPreviousAddress = () => {
    // todo: use reset to put a old values on address with the index of count
    handleDecrease();
  };
  const secondaryActionButton = {
    onClick: count > 1 ? onHandleGoPreviousAddress : handleSecondaryActionButton,
    text: count > 1 ? t('addressForm.previous') : t('addressForm.back'),
    testId: 'address-form-return-address-button',
  };

  // primary button props
  const onAddAddress = () => {
    // this function is called
    onHandleGoNext({
      projectId: initializedData?.project_id,
      addressFormValues: handleGetFormValues(),
      increaseAddressCounter,
      resetFormAddresses,
    });
  };

  const { search } = useLocation();
  const navigate = useNavigate();

  const { getEnvelopeSelection } = useEnvelopeSelection();
  const envelop = getEnvelopeSelection();

  const isReturnEnvelope = envelop === EnvelopeType.Return;

  // handling navigation to preview when the dialog is open
  const handleNavigateToPreview = () => {
    const searchParams = new URLSearchParams(search);

    // navigate to preview view
    navigate({ pathname: '/card/customization/preview', search: searchParams.toString() });
  };
  // functionality for 1:M return-recipient card
  const onHandleReachingMaxCount = () => {
    isMaxCountReached && !isDialogOpen ? getAddresses() : handleNavigateToPreview();
  };
  const onHandleLeaveRemainingBlank = () => {
    handleSetCount(maxCount);
  };
  const onHandleNextButtonWithDirtyText = isTouched ? onAddAddress : onHandleLeaveRemainingBlank;
  const onHandleNextButton = isMaxCountReached ? onHandleReachingMaxCount : onHandleNextButtonWithDirtyText;
  // trigger the existing functionality when the envelope is "return", to prevent handling it as 1:M for return-recipient envelopes
  const onHandleReturnOnly = isReturnEnvelope ? handlePrimaryActionButton : onHandleNextButton;

  // next label text
  const isTouchedText = isTouched ? t('addressForm.nextButton.addAddress') : t('addressForm.nextButton.leaveBlanks');
  const nextButtonText = isMaxCountReached ? t('addressForm.nextButton.preview') : isTouchedText;
  // validate the text field when the envelope is "return"
  const primaryActionText = isReturnEnvelope ? t('addressForm.preview') : nextButtonText;

  const primaryActionButton = {
    onClick: onHandleReturnOnly,
    text: primaryActionText,
    testId: 'address-form-continue-submit',
    isValid: isValid && isFormValid && isAddressFormValid,
    isLoading: isAddressLoading,
    isDisabled: isReturnEnvelope
      ? !isFormValid
      : (isTouched && !isFormValid) || isGetAddressesLoading || isAddressLoading,
    isTouched: isTouched,
  };

  return {
    oneToManyProps: {
      secondaryActionButton,
      primaryActionButton,
    },
  };
};
