import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { cleanCanvas } from '../components/card-editor/utils/clean-canvas';
import {
  hideLoadingScreen,
  setIsSystemErrorOpen,
  setPersonalizationData,
  showLoadingScreen,
  useAppContext,
} from '../context/app-context';
import { useCardContext } from '../context/card-context';
import { updateProjectName, useInitializationDataContext } from '../context/data-context';
import { CardType } from '../global-types';
import { getPreview, savePersonalization } from '../services';
import { updateDraftName } from '../services/customization';
import { getTransformedPersonalizationData, isArrayPopulated, isDataPopulated, validateImages } from '../utils';
import { useGeneratePreviews } from '../utils/previews/generate-preview-images';
import { getProjectTypeCode } from '../utils/utility/get-project-type-code';
import { useIsOneToMany } from './use-is-one-to-many';
import { useSystemErrorHandling } from './useSystemErrorHandling';

type SaveProjectOptions = {
  showLoadingScreen: boolean;
  shouldRestoreCanvas: boolean;
  generateFrontPreview: boolean;
  generateFEPreviews?: boolean;
};
/**
 * Custom hook used to save a project's draft name and personalization.
 * After the project is saved, the card canvas gets restored to its original state.
 * @returns callbacks to save the current project and update draft name
 */
export const useSaveProject = () => {
  const {
    initializedDataState: { data: initializedData },
    initializationDataDispatch,
  } = useInitializationDataContext();
  const { cardState } = useCardContext();
  const { generatePreviewImages } = useGeneratePreviews();
  const {
    appState: { productQuantity },
    appDispatch,
  } = useAppContext();
  const isOneToMany = useIsOneToMany();
  const { onSystemError } = useSystemErrorHandling();
  const { t } = useTranslation();

  const missingBackgroundImageError = t('editorView.missingBackgroundImageError');

  const saveProject = useCallback(
    async (
      options: SaveProjectOptions = {
        shouldRestoreCanvas: false,
        showLoadingScreen: true,
        generateFrontPreview: false,
      },
    ) => {
      if (!initializedData) {
        onSystemError(
          {
            status: 0,
            data: {
              errors: [
                {
                  code: '0',
                  description: 'Initialized data is not available',
                },
              ],
            },
          },
          {
            errorCallback: () => {
              throw new Error('Initialized data is not available');
            },
          },
        );
        return;
      }

      /**
       * handleTransformedPersonalizationData
       */
      const projectTypeCode = getProjectTypeCode(initializedData);
      // Destructure cardState to avoid mutating the original object
      const cardStateCopy = { ...cardState };

      // Clean canvas for /save-personalization call
      cleanCanvas(cardStateCopy.cardFacesList);

      if (!isArrayPopulated(cardState.cardFacesList)) {
        onSystemError(
          {
            status: 0,
            data: {
              errors: [
                {
                  code: '0',
                  description: 'Card faces list is empty when saving project',
                },
              ],
            },
          },
          {
            errorCallback: () => {
              throw new Error('Card faces list is empty when saving project');
            },
          },
        );
        return;
      }

      // Transform and fetch personalization data
      const personalizationJson = await getTransformedPersonalizationData(
        cardStateCopy,
        projectTypeCode,
        // TODO: Remove this logic when issue with one-to-many validation is fixed
        isOneToMany,
        initializedData?.variables?.template_data,
      ).catch((error) => {
        onSystemError(
          {
            status: 0,
            data: {
              errors: [
                {
                  code: '0',
                  description: error,
                },
              ],
            },
          },
          {
            errorCallback: () => {
              throw new Error(error);
            },
          },
        );
      });

      if (!personalizationJson) {
        // Error will be thrown in onSystemError.
        return;
      }

      if (options.showLoadingScreen) {
        showLoadingScreen(appDispatch, 'Saving your greeting...');
      }

      // confirm all card faces have valid background images
      const faces = initializedData?.variables?.template_data?.Faces;
      validateImages(faces, onSystemError, missingBackgroundImageError);
      if (!isDataPopulated(personalizationJson)) {
        // If we have an invalid background image SRC in save-personalization data or empty canvasJSON,
        // display system error and don't save
        options.showLoadingScreen && hideLoadingScreen(appDispatch);
        setIsSystemErrorOpen(appDispatch, true);
        return;
      }
      if (options.generateFEPreviews) {
        await generatePreviewImages(personalizationJson);
      }
      return savePersonalization(personalizationJson, initializedData?.project_id as string)
        .then(() => {
          setPersonalizationData(appDispatch, personalizationJson);
        })
        .then(() => {
          if (!options.generateFrontPreview) {
            return;
          }
          const projectId = initializedData.project_id;
          const frontFaceIndex = cardState.cardFacesList.findIndex((face) => face.type === 'front');
          const frontFace = personalizationJson[+frontFaceIndex];
          if (frontFace) {
            const { objects, version, backgroundImage } = frontFace.PrintJson;
            return getPreview(projectId, {
              save_preview_image: true,
              asset_type_code: 'P',
              personalization_data_json: {
                objects,
                version,
                backgroundImage,
              },
            });
          }
        })
        .catch((error) => {
          onSystemError(error, {
            errorCallback: () => {
              throw new Error(error);
            },
          });
        })
        .finally(() => {
          (!options.generateFEPreviews || projectTypeCode === CardType.DG) &&
            options.showLoadingScreen &&
            hideLoadingScreen(appDispatch);
        });
    },
    [
      initializedData?.project_type_code,
      initializedData?.project_id,
      initializedData?.variables?.template_data?.Faces,
      cardState,
      onSystemError,
    ],
  );

  /**
   * Update a project's name
   * @param draftName the new name for the project
   * @param isDraft
   */
  const saveDraftName = useCallback(
    (draftName: string, isDraft: boolean) => {
      const projectId = initializedData?.project_id;
      if (!projectId) {
        return;
      }

      return updateDraftName(draftName, projectId, productQuantity, isDraft)
        .then(() => updateProjectName(initializationDataDispatch, draftName))
        .catch((error) => onSystemError(error));
    },
    [initializedData?.project_id, onSystemError],
  );

  return { saveProject, saveDraftName };
};
