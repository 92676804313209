import { FabricTextBox } from '../global-types';
import { TextAction } from '../hooks/useDatadog';

// parses the text action from the fabric text element
export const parseTextAction = (textElement: FabricTextBox): TextAction => {
  return {
    text: textElement.text?.toString() || '',
    color: textElement.fill?.toString() || '',
    fontSize: (textElement.fontSize as number) || 0,
    fontStyle: textElement.fontStyle?.toString() || '',
    order: textElement.data?.order || 0,
    cardFace: textElement.data?.type || '',
    userZoneId: textElement.data?.zoneName,
    textPosition: {
      positionX: textElement.left || 0,
      positionY: textElement.top || 0,
    },
  };
};
