import React, { createContext, Reducer, useContext, useReducer } from 'react';
import { initialAddressBookState } from './address-book-context-state';
import {
  AddressBookAction,
  AddressBookType,
  AddressBookContextState,
  AddressBookProviderProps,
  CreateContextProps,
} from './address-book-context-types';

export const AddressBookContext = createContext<CreateContextProps>(undefined);

const addressBookReducer: Reducer<AddressBookContextState, AddressBookAction> = (state, action) => {
  switch (action.type) {
    case AddressBookType.SET_IS_BULK_UPLOAD_DIALOG_OPEN: {
      return {
        ...state,
        isBulkUploadDialogOpen: action.payload,
      };
    }
    default: {
      throw new Error('Unhandled action type in address-selection-context');
    }
  }
};

export const AddressBookProvider = ({ children }: AddressBookProviderProps) => {
  const [addressBookState, addressBookDispatch] = useReducer<Reducer<AddressBookContextState, AddressBookAction>>(
    addressBookReducer,
    initialAddressBookState,
  );

  return (
    <AddressBookContext.Provider value={{ addressBookState, addressBookDispatch }}>
      {children}
    </AddressBookContext.Provider>
  );
};

export const useAddressBookContext = () => {
  const context = useContext(AddressBookContext);

  if (!context) {
    throw new Error('useAddressBookContext must be used within AddressBookProvider');
  }

  return context;
};
