import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonModes } from '@hallmark/web.core.buttons.button';
import { removeBeforeUnloadEvent } from '../../../utils';
import { CancelButtonProps } from '../system-failure-dialog-types';

export const CancelButton = ({ click }: CancelButtonProps): React.ReactElement => {
  const { t } = useTranslation();

  const onClick = () => {
    removeBeforeUnloadEvent();
    click();
  };

  return (
    <Button click={onClick} mode={ButtonModes.TextLink} testId="cancel-button">
      {t('systemFailureDialog.exit')}
    </Button>
  );
};
