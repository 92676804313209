import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastVariants } from '@hallmark/web.core.feedback.toast';
import { useAnalyticsContext } from '../../../../context/analytics-context';
import {
  setAllDrawersClosed,
  setIsClearConfirmationDialogClosed,
  setIsDeleteConfirmationDialogClosed,
  setIsDeleteConfirmationDialogOpen,
  setIsImageEditDrawerOpen,
  setIsResetConfirmationDialogClosed,
  setIsTextDrawerOpen,
  setIsToasterOpen,
  useAppContext,
} from '../../../../context/app-context';
import { useCardContext } from '../../../../context/card-context';
import { useCropContext } from '../../../../context/crop-context';
import { useInitializationDataContext } from '../../../../context/data-context';
import { FabricObject, FabricTextBox } from '../../../../global-types';
import { useFeatureFlags } from '../../../../hooks';
import { useIsOneToMany } from '../../../../hooks/use-is-one-to-many';
import { ActionType, useDatadog } from '../../../../hooks/useDatadog';
import { deleteImage } from '../../../../services';
import {
  CanvasDataTypes,
  clearPhotoTextZone,
  convertPointToPixel,
  getObjectByName,
  getObjectsByType,
  togglePhotoTextZoneButtons,
} from '../../../../utils';
import { getIconForLowResImage } from '../../../../utils/canvas-utils/get-icon-for-low-res-image';
import { parseTextAction } from '../../../../utils/parse-text-action';
import { addPhotozoneBackground } from '../../../card-editor/card-editor-utils';
import { addEditableAreas } from '../../../card-editor/utils';
import { handleOrderChange } from '../utils/handle-order-change';
import { isUserZoneEmpty } from '../utils/is-user-zone-empty';

export const useDrawerContainerActions = (
  canvas: React.RefObject<fabric.Canvas> | undefined,
  setInitialAngle: React.Dispatch<React.SetStateAction<number>>,
) => {
  const { addAction, textAction } = useDatadog();
  const { appDispatch } = useAppContext();
  const { finishCropping } = useCropContext();
  const { updateEditFormats, trackEditElement, trackDeleteElement, isReplacingImage, trackWamTamReset } =
    useAnalyticsContext();
  const { SAS_DYNAMIC_BUTTONS } = useFeatureFlags();
  const { cardState } = useCardContext();
  const isOneToMany = useIsOneToMany();
  const { t } = useTranslation();

  const openImageEditDrawer = useCallback(() => setIsImageEditDrawerOpen(appDispatch), [appDispatch]);

  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();

  const resetErrorMessage = t('drawerContainer.resetErrorMessage');
  const imageErrorTitle = t('drawerContainer.imageErrorTitle');

  const handleRotateOpenDrawer = () => {
    const activeObject = canvas?.current?.getActiveObject();
    if (activeObject?.type === 'image') {
      openImageEditDrawer();
    } else {
      setIsTextDrawerOpen(appDispatch);
    }
  };

  const onChangeOrder = (order: number, object?: fabric.Object) => {
    if (!canvas?.current) return;
    handleOrderChange(order, canvas.current, object).then(() => {
      updateEditFormats({ order: order.toString() });
    });
  };

  const openDeleteConfirmationDialog = () => {
    setIsDeleteConfirmationDialogOpen(appDispatch);
  };

  const closeDeleteConfirmationDialog = () => {
    setIsDeleteConfirmationDialogClosed(appDispatch);
  };

  const closeClearConfirmationDialog = () => {
    trackDeleteElement();
    setIsClearConfirmationDialogClosed(appDispatch);
    resetSelection();
  };

  const onSubmitSize = () => {
    setIsTextDrawerOpen(appDispatch);
  };

  const onSubmitRotate = () => {
    setInitialAngle(canvas?.current?.getActiveObject()?.angle ?? 0);
    handleRotateOpenDrawer();
  };

  const onSubmitOrder = () => {
    const activeObject = canvas?.current?.getActiveObject();

    if (activeObject?.type === 'image') {
      setIsImageEditDrawerOpen(appDispatch);
    } else {
      setIsTextDrawerOpen(appDispatch);
    }
  };

  const onFinishCropping = async () => {
    if (finishCropping) {
      await finishCropping();
    }
  };

  const onCloseImageEditDrawer = () => {
    if (!isReplacingImage.current) {
      trackEditElement();
      resetSelection();
    }
  };

  /**
   *  When size drawer is closed by cancel, revert text to its original size
   *
   * @param textObject - object to revert back to its original size
   * @param fontSize - the text object's original size

   * @returns void
   */
  const onSizeDrawerClose = (textObject: fabric.Textbox, fontSize: number) => {
    setIsTextDrawerOpen(appDispatch);
    textObject?.setOptions({ fontSize });
    canvas?.current?.renderAll();
  };

  const closeResetConfirmationDialog = () => {
    setIsResetConfirmationDialogClosed(appDispatch);
  };

  const resetSelection = () => {
    setAllDrawersClosed(appDispatch);
    if (canvas?.current) {
      canvas.current.discardActiveObject();
      canvas.current.renderAll();
    }
  };

  const deleteSelectedObject = () => {
    const activeObject = canvas?.current?.getActiveObject();
    if (activeObject) {
      trackDeleteElement();
      canvas?.current?.remove(activeObject);
    }

    if (
      activeObject?.type === CanvasDataTypes.EditableText ||
      activeObject?.type === CanvasDataTypes.UserText ||
      activeObject?.type === CanvasDataTypes.PhotoZoneTextbox
    ) {
      textAction(ActionType.DELETE_TEXT, parseTextAction(activeObject as FabricTextBox));
    }
    return activeObject;
  };

  const handleClearConfirmation = () => {
    if (canvas?.current) {
      clearPhotoTextZone(canvas.current, SAS_DYNAMIC_BUTTONS);
      setAllDrawersClosed(appDispatch);
      closeClearConfirmationDialog();
    }
  };

  const handleResetConfirmation = () => {
    const activeImage = canvas?.current?.getActiveObject() as fabric.Object;
    if (!activeImage) {
      return;
    }

    if (activeImage.data?.type === CanvasDataTypes.WamImage) {
      addAction('remove-handwriting-from-card-face', {
        handwriting: activeImage.data,
      });
    }

    deleteImage(initializedData?.project_id as string, activeImage?.name as string).then((response) => {
      if (response?.meta?.code >= 400) {
        setIsToasterOpen(appDispatch, {
          title: imageErrorTitle,
          children: resetErrorMessage,
          variant: ToastVariants.Error,
        });
        closeResetConfirmationDialog();
      } else {
        const deletedImage = deleteSelectedObject();
        if (deletedImage && deletedImage.data?.zoneName && canvas?.current) {
          const photoTextZone = getObjectByName(deletedImage.data.zoneName, canvas.current) as fabric.Group;
          if (photoTextZone) {
            photoTextZone.data.hasContent = false;
            photoTextZone.setOptions({ selectable: true });
            togglePhotoTextZoneButtons(true, canvas.current, photoTextZone, SAS_DYNAMIC_BUTTONS);
            canvas?.current?.renderAll();
          }
        }
        setAllDrawersClosed(appDispatch);
        closeResetConfirmationDialog();

        if (deletedImage?.data?.zoneName?.indexOf(CanvasDataTypes.PhotoTextZone) !== -1) {
          //Track Wam/Tam reset
          trackWamTamReset(false);
        }
      }
    });
  };

  const handleDeleteConfirmation = async () => {
    const activeObject = canvas?.current?.getActiveObject() as FabricObject | null;

    if (!canvas || !canvas.current || !activeObject) return;

    if (activeObject.type === 'image') {
      const response = await deleteImage(initializedData?.project_id as string, activeObject?.name as string);

      if (activeObject.data?.type === CanvasDataTypes.PhotoZoneImage) {
        addAction('remove-photo-from-user-zone', {
          photo: activeObject.data,
        });
      } else {
        addAction('remove-photo-from-card-face', {
          photo: activeObject.data,
        });
      }

      if (activeObject.data?.type === CanvasDataTypes.PhotoZoneImage && canvas.current) {
        addPhotozoneBackground(activeObject, canvas?.current, cardState);
      }
      if (response?.meta?.code >= 400) {
        setIsToasterOpen(appDispatch, {
          title: imageErrorTitle,
          children: resetErrorMessage,
          variant: ToastVariants.Error,
        });
        return closeDeleteConfirmationDialog();
      }
    }

    const iconToDelete = getIconForLowResImage(canvas.current, activeObject);
    if (iconToDelete) {
      canvas.current.remove(iconToDelete);
    }

    const image = deleteSelectedObject();

    const isPhotoImage = image?.data?.type === CanvasDataTypes.PhotoZoneImage;
    if (isPhotoImage && canvas?.current) {
      const currentZoneId = image?.data?.photoZoneId;
      if (!currentZoneId) {
        return;
      }
      //restore the photo zones
      const photoZones = getObjectsByType(canvas.current, CanvasDataTypes.PhotoZone);
      const currentPhotoZone = photoZones.find((zone) => zone.data?.id === currentZoneId);
      if (currentPhotoZone) {
        currentPhotoZone.set({ visible: true });
        currentPhotoZone.data.hasContent = false;
      }
      // restore the photo zone button
      const photoButton = getObjectsByType(canvas.current, CanvasDataTypes.PhotoZoneButton).find(
        (button) => button.data?.photoZoneId === currentZoneId,
      );
      photoButton?.set({ visible: true });
      canvas.current.renderAll();
    }
    // Restore editable area buttons if all objects are removed from editable area
    if (canvas?.current) {
      const objects = canvas.current.getObjects();
      if (isUserZoneEmpty(objects)) {
        // User zone is empty, so populate user zone buttons
        const cardFace = cardState.cardFacesList[`${cardState.activeCardIndex}`];
        const { editableAreas } = cardFace;
        if (editableAreas) {
          addEditableAreas(cardFace, cardState, true, isOneToMany);
        }
      }
    }
    setAllDrawersClosed(appDispatch);
    closeDeleteConfirmationDialog();
  };

  const handleSizeChange = (textObject: fabric.Textbox | null, valueInRange: string) => {
    if (textObject) {
      const scaledFontSize = convertPointToPixel(parseInt(valueInRange));
      textObject.setOptions({ fontSize: scaledFontSize });
      updateEditFormats({ fontSize: valueInRange });
      canvas?.current?.renderAll();
      textObject.fire('changed');
    }
  };

  return {
    handleRotateOpenDrawer,
    onChangeOrder,
    openDeleteConfirmationDialog,
    closeDeleteConfirmationDialog,
    closeClearConfirmationDialog,
    onSubmitSize,
    onSubmitRotate,
    onSubmitOrder,
    onFinishCropping,
    onCloseImageEditDrawer,
    onSizeDrawerClose,
    closeResetConfirmationDialog,
    handleClearConfirmation,
    deleteSelectedObject,
    handleResetConfirmation,
    handleDeleteConfirmation,
    handleSizeChange,
  };
};
