import React, { ReactElement, useEffect, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconNames } from '@hallmark/web.core.display.icon';
import { Drawer, DrawerPositions } from '@hallmark/web.core.feedback.drawer';
import { Tabs } from '@hallmark/web.core.surfaces.tabs';
import { TypographyVariants } from '@hallmark/web.core.typography.typography';
import { BrandColors } from '@hallmark/web.styles.colors';
import { BottomPadding } from '@hallmark/web.util.shared-types';
import { useAppContext } from '../../../context/app-context';
import { FabricTextBox } from '../../../global-types';
import { useActiveCanvas, useFeatureFlags, useIsSignAndSend } from '../../../hooks';
import { getGroupedTextObject } from '../../../utils';
import { getOrderIndices } from '../../../utils/canvas-utils/get-order-indices';
import { handleZoomIn } from '../../card-face/utils';
import styles from './font-drawer.module.scss';
import { ColorTab, PositionTab, TextTab, FontTab, FontDrawerHeader, FontDrawerFooter } from './fragments';
import { useCloseFontDrawer } from './hooks/useCloseFontDrawer';
import { useFontDrawerFlags } from './hooks/useFontDrawerFlags';
import { handleTextTabClick } from './utils/handle-text-tab-click';
import { onRevertStyles } from './utils/on-revert-styles';

export const FontDrawer = (): ReactElement => {
  const canvas = useActiveCanvas();
  const textTabRef = useRef<HTMLButtonElement>(null);
  const fontTabRef = useRef<HTMLButtonElement>(null);
  const colorTabRef = useRef<HTMLButtonElement>(null);
  const positionTabRef = useRef<HTMLButtonElement>(null);
  const currentText = canvas?.current?.getActiveObject() as FabricTextBox;
  const { t } = useTranslation();
  const { appState } = useAppContext();
  const { DISPLAY_ZOOM_DEV } = useFeatureFlags();
  const { shouldShowColorEditor, shouldShowPositionTabEditor } = useFontDrawerFlags();
  const selectedTextInitialState = Object.assign({}, getGroupedTextObject(canvas?.current));
  const { isTextDrawerOpen, isMobileApp, isTabletApp } = appState;
  const shouldShowFontToolbox = currentText?.data.displayFontToolbox ?? true;
  const isSignAndSend = useIsSignAndSend();
  const { onFontDrawerSubmit, onFontDrawerClose } = useCloseFontDrawer();

  // save order of every object in the canvas to revert on cancel
  const originalObjectsOrder = useMemo(() => {
    const { objects: originalObjectsOrder } = getOrderIndices(canvas?.current);
    return originalObjectsOrder;
  }, [canvas?.current]);

  const tabsDataBase = [
    {
      tabLabel: t('fontDrawer.textTabLabel'),
      tabContent: <TextTab />,
      tabIcon: IconNames.Keyboard,
      buttonRef: textTabRef,
    },

    { tabLabel: t('fontDrawer.FontTabLabel'), tabContent: <FontTab />, tabIcon: IconNames.Font, buttonRef: fontTabRef },
  ];

  if (shouldShowColorEditor) {
    tabsDataBase.push({
      tabLabel: t('fontDrawer.ColorTabLabel'),
      tabContent: <ColorTab />,
      tabIcon: IconNames.Paint,
      buttonRef: colorTabRef,
    });
  }

  if (shouldShowPositionTabEditor) {
    tabsDataBase.push({
      tabLabel: t('fontDrawer.PositionTabLabel'),
      tabContent: <PositionTab />,
      tabIcon: IconNames.Object,
      buttonRef: positionTabRef,
    });
  }

  useEffect(() => {
    const button = textTabRef.current;
    if (button && canvas?.current && isMobileApp) {
      button.addEventListener('click', () => handleTextTabClick(canvas?.current, isMobileApp));
    }

    return () => {
      if (button && canvas?.current && isMobileApp) {
        button.removeEventListener('click', () => handleTextTabClick(canvas?.current, isMobileApp));
      }
    };
  }, []);

  useEffect(() => {
    if (isMobileApp) {
      const objActiveCanvas = canvas?.current?.getActiveObject();

      if (canvas?.current && objActiveCanvas) {
        handleZoomIn(canvas.current, objActiveCanvas, DISPLAY_ZOOM_DEV, isSignAndSend);
      }
    }
  }, []);

  return (
    <Drawer
      addClass={styles['font-drawer']}
      drawerPosition={isMobileApp ? DrawerPositions.Bottom : DrawerPositions.Left}
      isOpen={isTextDrawerOpen && shouldShowFontToolbox}
      onClose={() => onFontDrawerClose(canvas)}
      headerContent={<FontDrawerHeader />}
      footerContent={
        <FontDrawerFooter
          onSubmit={() => onFontDrawerSubmit(canvas)}
          onCancel={() => onRevertStyles(selectedTextInitialState, canvas, originalObjectsOrder, onFontDrawerClose)}
        />
      }
      id="font_drawer"
      showVeil={false}
      closeButtonText="Close Drawer"
      drawerTestId="font-drawer"
    >
      <Tabs
        addClass={styles['font-drawer-tabs']}
        breakTabs={isMobileApp || isTabletApp ? false : true}
        tabImageColor={BrandColors.Blue}
        tabsItems={tabsDataBase}
        backgroundColor={BrandColors.White}
        bottomPadding={BottomPadding.Small}
        headlineColor={BrandColors.Purple}
        headlineStyle2={TypographyVariants.Headline2}
      />
    </Drawer>
  );
};
