import React from 'react';
import { HtmlTagOverrides, Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { useMultiAddresses } from '../../../hooks/use-multi-addresses';
import { AddressSelectionIndicator } from '../../address-selection-indicator/address-selection-indicator';
import { EnvelopeFormHeaderProps } from '../address-form-types';
import styles from '../address-form.module.scss';

export const Header = ({ formTitle }: EnvelopeFormHeaderProps) => {
  const { shouldUseMultiAddresses } = useMultiAddresses();

  return (
    <>
      <header className={styles.header}>
        <Typography
          variant={TypographyVariants.Subtitle}
          htmlTagOverride={HtmlTagOverrides.H2}
          addClass={styles.title}
          data-testid={'address-form-title'}
        >
          {formTitle}
        </Typography>
        {shouldUseMultiAddresses && <AddressSelectionIndicator />}
      </header>
    </>
  );
};
