import { AddressForm as AddressFormData, AddressTypes } from '../../../global-types';
import { AddressTypeCode } from '../../../global-types/addresses';

/**
 * Uses assets from project data to return default address data
 *
 * @param search - url search params
 * @param currentAddressType - address data from the store
 * @param isUK - boolean for country code
 * @param isAddressValidationEnabled - boolean for address validation
 * @returns object
 */
export const getDefaultAddressOptions = (search, currentAddressType, isUK, isAddressValidationEnabled) => {
  const searchParams = new URLSearchParams(search);
  const envelope = searchParams.get('envelope') as AddressFormData['envelope'];
  const defaultAddressOptions: Partial<AddressFormData> = {
    country_code: isUK ? 'UK' : 'USA',
    skip_usps_validation: !isAddressValidationEnabled,
    address_type_code:
      currentAddressType === AddressTypes.RECIPIENT ? AddressTypeCode.RECIPIENT : AddressTypeCode.SENDER,
    envelope: envelope || null,
  };
  return { ...defaultAddressOptions };
};
