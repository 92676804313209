import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ce from './translations/ce/translations.json';
import en from './translations/en/translations.json';
import uk from './translations/uk/translations.json';

// eslint-disable-next-line import/no-named-as-default-member
i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    initImmediate: false,
    debug: true,
    fallbackLng: 'en',
    defaultNS: 'common',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        common: en,
      },
      uk: {
        common: uk,
      },
      ce: {
        common: ce,
      },
    },
    lng: process.env.REACT_APP_THEME || 'en',
  });

export default i18next;
